import PayOrder from '@/views/wc/PayOrder.vue';
import ChangePaymentMethod from '@/views/wc/ChangePaymentMethod.vue';

export default {
  functional: true,
  render(h, ctx) {
    const { query } = ctx.parent.$route;
    if (query.change_payment_method) {
      return h(ChangePaymentMethod, ctx.data, ctx.children);
    }
    return h(PayOrder, ctx.data, ctx.children);
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{staticClass:"section",class:[
    _vm.layout.section_classes && _vm.layout.section_classes !== '' ? _vm.layout.section_classes.split(' ') : null,
    `layout--${_vm.layout.section_layout}`,
  ],style:({
    backgroundColor: _vm.layout.section_background !== '' ? _vm.layout.section_background : null,
    color: _vm.layout.section_text !== '' ? _vm.layout.section_text : null,
  }),attrs:{"id":_vm.layout.section_id ? _vm.layout.section_id : null,"tag":"section","template":_vm.sectionSpacer}},[_c('Wrapper',[_c('Grid',{attrs:{"col":_vm.layout.section_grid ? { default: 12, m: _vm.layout.section_grid } : '12',"row-gap":"0"}},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
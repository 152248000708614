<template>
  <div
    :class="['wysiwyg', mergeProps.size ? `typo--${mergeProps.size}` : null]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Wysiwyg',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        size: 's',
      };

      Object.keys(this.data).forEach(
        (key) => this.data[key] === undefined && delete this.data[key],
      );
      return { ...defaultProps, ...this.data };
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if (window.twttr && this.mergeProps.value.includes('twitter-tweet')) {
      // eslint-disable-next-line no-undef
      window.twttr.widgets.load();
    }

    // // eslint-disable-next-line no-undef
    // FB.init({
    //   xfbml: true,
    //   version: 'v10.0',
    // });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/src/04_typography";

.wysiwyg {
  display: grid;
  // max-width: var(--wrapper-m);
  // row-gap: var(--spacer-xl);
  // margin: var(--spacer-xxl);

  p a {
    transition: color 0.2s $ease-custom;

    @include mq($and: $hover) {
      &:hover {
        color: var(--grey-xl);
      }
    }
  }

  p + * {
    margin-top: var(--spacer-l);
  }

  ul,
  ol {
    display: block;
    padding-left: $unit * 2;
  }

  ul {
    list-style-type: disc;
  }

  h1 {
    @extend %typo--l;
    margin-top: var(--spacer-m);
    margin-bottom: var(--spacer-s);
  }

  h2 {
    @extend %typo--m;
    margin-top: var(--spacer-m);
    margin-bottom: var(--spacer-s);
  }

  h3,
  h4 {
    @extend %typo--s;
    margin-top: var(--spacer-m);
    margin-bottom: var(--spacer-s);
  }

  h5 {
    @extend %typo--xs;
  }

  .iframe {
    &-youtube {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; /*16:9*/

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .wp-block {
    &-image,
    &-video,
    &-embed,
    &-gallery {
      img,
      video {
        max-width: 100%;
        width: auto !important;
      }

      figcaption {
        @extend %typo--xs;
        text-align: left;
      }
    }

    &-image {
      margin-left: auto;
      margin-right: auto;

      &.is-style-small {
        max-width: 50%;

        @include mq(m) {
          max-width: 25%;
        }

        img {
          width: 100%;
        }
      }
      &.is-style-medium {
        @include mq(m) {
          max-width: 50%;
        }

        img {
          width: 100%;
        }
      }
      &.is-style-large {
        img {
          width: 100%;
        }
      }

      figcaption {
        padding: 0 var(--spacer-s);
      }
    }

    &-video {
      // max-width: var(--wrapper-s);
      margin-left: auto;
      margin-right: auto;

      video {
        width: 100%;
      }
    }

    &-quote {
      @extend %typo--l;
    }

    &-pullquote {
      border-top: var(--black) solid;
      border-bottom: var(--black) solid;
      padding: var(--spacer-m);

      p {
        @extend %typo--l;
      }
    }

    &-code {
      font-family: "Courier New", Courier, monospace;
      border: var(--black) solid;
      padding: var(--spacer-m);
    }

    &-table {
      td {
        border: var(--black) solid;
        padding: var(--spacer-s);
      }
    }

    &-gallery {
      padding: 0 var(--spacer-s);

      .blocks-gallery-grid {
        display: grid;
        gap: var(--spacer-s);
        list-style: none;
        margin: 0;
        padding: 0;
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      .blocks-gallery-item {
        display: flex;
        align-items: flex-start;
      }

      @for $i from 1 to 9 {
        &.columns-#{$i} .blocks-gallery-grid {
          @include mq(m) {
            grid-template-columns: repeat($i, minmax(0, 1fr));
          }
        }
      }
    }

    &-buttons,
    &-file {
      display: flex;
      gap: var(--spacer-s);
      align-items: center;
    }

    &-button,
    &-file__button {
      background: var(--grey-l);
      width: min-content;
      cursor: pointer;
      padding: var(--spacer-s);
    }

    &-media-text {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: column;
      gap: var(--spacer-s);
    }

    &-columns {
      display: grid;
      grid-auto-flow: column;
      gap: var(--spacer-s);
    }

    &-spacer {
      display: none;
    }
  }

  .is {
    &-content-justification {
      &-center {
        justify-content: center;
        &.is-vertical {
          align-items: center;
        }
      }
      &-right {
        justify-content: flex-end;
      }
    }

    &-vertical {
      flex-direction: column;
    }

    &-vertically-aligned {
      &-center {
        align-items: center;
      }
      &-bottom {
        align-items: end;
      }
    }
  }

  .has {
    &-drop-cap::first-letter {
      font-size: 100px;
    }

    &-medium-font-size {
      @extend %typo--m;
    }

    &-large-font-size {
      @extend %typo--l;
    }

    &-text-align {
      &-center {
        text-align: center;
      }
      &-right {
        text-align: right;
      }
    }

    &-media-on-the-right div {
      grid-column: 1;
    }
  }

  .wp-block-preformatted {
    white-space: normal;
  }
}
</style>

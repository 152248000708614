<template>
  <component
    :is="component"
    :category="data"
    :slug="slug"
    :type="type"
  />
</template>

<script>
import Page from '@/views/Page';
import Category from '@/views/Category';
import Search from '@/views/Search';

export default {
  name: 'SwitchComponent',
  components: {
    Page,
    Category,
    Search,
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      component: (this.$store.state.from && this.$store.state.from.name.includes('Category')) || this.$route.meta.component === 'category' ? Category : (this.$store.state.from && this.$store.state.from.name === 'Search') || this.$route.meta.component === 'search' ? Search : Page,
      data: this.$store.state.from && this.$store.state.from.name.includes('Category') ? {
        category: this.$store.state.from.meta.category,
        postType: this.$store.state.from.meta.postType,
        parent: this.$store.state.from.meta.parent,
        slug: this.$store.state.from.params.slug,
      } : null,
    };
  },
};
</script>

<style>

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.page || _vm.single || _vm.parent)?_c('Spacer',{class:[
    'breadcrumbs',
    _vm.$route.name === 'Index' || _vm.$route.name === 'Search' || _vm.$route.name === 'Checkout'
      ? 'breadcrumbs--hide'
      : false,
  ],attrs:{"x":"s","tag":"aside","top":"1"}},[_c('Flex',{staticClass:"overflow-scroll",attrs:{"align":"center","nowrap":""}},[(_vm.parent)?_c('Cta',{attrs:{"data":{
        title: _vm.parent.title,
        url: _vm.parent.url,
        size: 'label-s',
      }}}):_vm._e(),(_vm.page && _vm.parent)?_c('Spacer',{staticClass:"typo--label-s",attrs:{"x":"xs"}},[_vm._v(" > ")]):_vm._e(),(_vm.page && _vm.overlay)?_c('Cta',{attrs:{"data":{
        title: _vm.page.title,
        url: _vm.page.url,
        size: 'label-s',
      }}}):_vm._e(),(_vm.page && !_vm.overlay)?_c('Label',{attrs:{"data":{
        value: _vm.page.title,
        size: 'label-s',
      }}}):_vm._e(),(_vm.overlay && _vm.single)?_c('Spacer',{staticClass:"typo--label-s",attrs:{"x":"xs"}},[_vm._v(" > ")]):_vm._e(),(_vm.overlay && _vm.single)?_c('Label',{attrs:{"data":{
        value: _vm.single.title,
        size: 'label-s',
      }}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AccordionWrapper',{staticClass:"block-theme--default",attrs:{"id":`order-${_vm.order.id}`}},[_c('AccordionItem',{attrs:{"data-order":_vm.order.id}},[_c('template',{slot:"accordion-trigger"},[_c('Grid',{staticClass:"order-grid"},[_c('Column',{class:{ 'typo--m': _vm.$mq.isMobileXS },attrs:{"size":{
            default: 6,
            xs: 3,
          }}},[_vm._v(" #"+_vm._s(_vm.order.id)+" ")]),(_vm.$mq.isMobileXS)?_c('Column',{staticClass:"typo--m",attrs:{"size":{
            default: 9,
            s: 3,
          }}},[_c('div',{staticClass:"t-ellipsis"},[_vm._v(" "+_vm._s(_vm.orderStatus)+" ")])]):_vm._e(),(!_vm.$mq.isMobileXS)?_c('Column',{attrs:{"size":"3"}},[_c('div',{staticClass:"t-ellipsis"},[_vm._v(" "+_vm._s(_vm.parseDate(_vm.order.created_date))+" ")])]):_vm._e(),(!_vm.$mq.isMobileXS)?_c('Column',{attrs:{"size":"3"}},[_c('Flex',{staticClass:"flex-no-wrap",attrs:{"align":"center"}},[_c('div',{staticClass:"t-ellipsis"},[_vm._v(" "+_vm._s(_vm.orderStatus)+" ")]),(_vm.orderActions[0])?_c('Spacer',{attrs:{"left":"xs"}},[_c('Icon',{attrs:{"name":"alert","size":"xs"}})],1):_vm._e()],1)],1):_vm._e(),(!_vm.$mq.isMobileXS)?_c('Column',{attrs:{"size":"3"}},[_c('div',{staticClass:"t-ellipsis",domProps:{"innerHTML":_vm._s(_vm.getOrderTotal(_vm.order))}})]):_vm._e()],1)],1),_c('template',{slot:"accordion-content"},[_c('Spacer',{attrs:{"x":"s"}},[_c('Grid',{attrs:{"col":"12","row-gap":"l"}},[(!_vm.$mq.isMobileXS)?_c('div',{staticClass:"typo--m"},[(_vm.theme === 'default')?_c('span',[_vm._v(" "+_vm._s(_vm.$labels.order_details)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$labels.details))])]):_c('div',[_c('OrderHeader',{attrs:{"order":_vm.order,"theme":_vm.theme}}),_c('div',{staticClass:"separator--row"})],1),_c('OrderDetails',{attrs:{"order":_vm.order,"theme":_vm.theme}})],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ul class="accordion-wrapper">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'AccordionWrapper',
  provide() {
    return { Accordion: this.Accordion };
  },
  props: {
    activeCounter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      Accordion: {
        count: 0,
        active: this.activeCounter,
      },
    };
  },
  computed: {

  },
};
</script>

<style lang="scss">
.accordion-wrapper {
  width: 100%;
}
.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s cubic-bezier(0.65, 0, 0.35, 1), opacity 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
}
</style>

<template>
  <div>
    <transition name="fade">
      <div
        v-if="showCartCalc"
        class="overlay"
        @click="$store.commit('CLOSE_CART')"
      />
    </transition>
    <div class="cart">
      <transition name="slide">
        <div v-if="showCartCalc">
          <Spacer class="cart__wrapper">
            <div class="cart__content">
              <Spacer
                v-if="isCartEmpty"
                all="s"
                class="cart__empty-state"
              >
                <p>
                  {{ $labels.your_cart_empty }}
                </p>
              </Spacer>
              <CartList theme="side" />
            </div>
            <div
              v-if="!isCartEmpty"
              class="cart__footer"
            >
              <Spacer
                x="s"
                class="cart__subtotal"
              >
                <Flex justify="space-between">
                  <div>{{ $labels.subtotal }}</div>
                  <div class="t-right">
                    <div v-html="subtotal" />
                    <!-- <small v-if="taxDisplayCart === 'excl'">{{ $labels.excluded_vat_extended }}</small> -->
                    <!-- <small v-if="taxDisplayCart === 'excl'">{{ $labels.excluded_vat }}</small> -->
                  </div>
                </Flex>
              </Spacer>
              <Cta
                theme="button"
                :data="{
                  title: $labels.checkout,
                  url: '/checkout',
                  size: 's',
                }"
              />
            </div>
          </Spacer>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash.isempty';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { parseCurrency, unparseCurrency } from '@/assets/js/utils-wc';

import Cta from '@/components/typo/cta';
import CartList from '@/components/wc/cart-list';

export default {
  name: 'Cart',
  components: {
    Cta,
    CartList,
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(['cart', 'cartLoading', 'showCart', 'wcSettings']),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.taxDisplayCart === 'excl') {
        return this.parseCurrency(this.unparseCurrency(this.cart.totals.subtotal));
      }
      return this.parseCurrency(
        this.unparseCurrency(this.cart.totals.subtotal)
          + this.unparseCurrency(this.cart.totals.subtotal_tax),
      );
    },
    showCartCalc() {
      if (this.$route.name === 'Cart') {
        return false;
      }
      if (this.showCart) {
        disableBodyScroll(document.querySelector('html'));
        return true;
      }
      clearAllBodyScrollLocks();
      return false;
    },
  },
  watch: {
    $route() {
      // to
      // to.name === 'Checkout' &&
      if (this.showCart) {
        this.$store.commit('CLOSE_CART');
      }
    },
  },
  async mounted() {
    this.$store.dispatch('getCart');
  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.25s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.cart {
  position: fixed;
  z-index: 19;
  top: 0;
  right: 0;
}

.cart__wrapper {
  background: #f6f6f6;
  width: 100vw;
  height: calc((var(--vh) * 100) - var(--bar-height));
  position: relative;
  /* display: flex;
  flex-direction: column; */
  top: var(--bar-height);

  position: relative;
  display: grid;
  grid-template-rows: 1fr min-content;
  row-gap: var(--row-gap-xs);

  @include mq(m) {
    max-width: 33.333333333333vw;
  }
}

.cart__header {
  width: 100%;
  min-height: $unit * 4;
  &:after {
    content: " ";
    display: block;
    width: auto;
    height: 1px;
    background: var(--black);
    margin: var(--spacer-m);
  }
}

.cart__content {
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
}

.cart__list {
  flex: 1 0 0;
  overflow: scroll;
  line-height: $unit * 2;
}

.cart__footer {
  position: sticky;
  bottom: 0;
  z-index: 2;

  .app--cookied & {
    bottom: var(--bar-height);
  }

  &:before {
    content: " ";
    display: block;
    width: auto;
    height: 1px;
    background: var(--grey-xl);
    margin: 0;
  }
}

.cart__subtotal {
  height: var(--bar-height);
  line-height: var(--bar-height);
}

.cart__item-right {
  height: 100%;
}

.cart__remove-item {
  cursor: pointer;
  transition: all 0.25s $ease-custom;
  display: block;
  line-height: $unit * 1.5;
}

.no-touchevents {
  .cart__remove-item {
    opacity: 0;
    pointer-events: none;
  }
}

.cart__item {
  &:hover {
    .cart__remove-item {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>

<template>
  <li class="accordion__item">
    <Flex
      class="accordion__trigger"
      :class="{'accordion__trigger--active': visible}"
      justify="space-between"
      align="center"
      @click.native="open"
    >
      <slot name="accordion-trigger" />

      <Icon
        name="chevron-bottom"
        size="m"
        :fn="open"
      />
    </Flex>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="visible"
        class="accordion__content"
      >
        <Spacer
          top="s"
        >
          <slot name="accordion-content" />
        </Spacer>
      </div>
    </transition>
  </li>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  components: {
    Icon,
  },
  inject: ['Accordion'],
  props: {
    preOpenedFn: {
      type: Function,
      required: false,
      default: () => {},
    },
    openedFn: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.index === this.Accordion.active;
    },
  },
  created() {
    this.index = this.Accordion.count + 1;
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      this.preOpenedFn();
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      el.style.height = '';
      this.openedFn();
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion__trigger {
  padding: 0 var(--spacer-xxs) 0 var(--spacer-s);

  &.accordion__trigger--active {
    .icon {
      transform: scaleY(-1);
    }
  }
}
</style>

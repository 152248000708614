import Vue from 'vue';

const labels = {
  shop: 'shop',
  add: 'Add',
  view: 'View',
  products: 'Products',
  add_to_cart: 'Add to cart',
  added: 'Added',
  add_new_card: 'Add new card',
  make_default: 'Make default',
  out_of_stock: 'Out of stock',
  choose_option: 'Choose an option',
  select_option: 'Select option',
  available: 'available',
  id: 'ID',
  date: 'Date',
  status: 'Status',
  totals: 'Totals',
  actions: 'Actions',
  product: 'Product',
  payment: 'Payment',
  additional_information: 'Additional information',
  billing_address: 'Billing Address',
  shipping_address: 'Shipping Address',
  edit: 'Edit',
  save: 'Save',
  saved_changes: 'Changes have been saved',
  first_name: 'First name',
  last_name: 'Last name',
  company: 'Company',
  state: 'State / Country',
  postcode: 'Postcode / ZIP',
  order_notes: 'Order notes',
  order_notes_placeholder: 'Need a specific delivery day? Sending a gift? ',
  city: 'City',
  country: 'Country',
  email: 'Email',
  phone: 'Phone',
  display_name: 'Display name',
  old_password: 'Old password',
  password: 'Password',
  password_placeholder: 'Minimum 6 characters',
  repeat_password: 'Repeat password',
  welcome_back: 'Welcome back',
  do_you_have_account: 'Do you have an account?',
  login: 'Login',
  logged_in_with_mail: 'You\'re logged with the e-mail',
  my_account: 'My account',
  account: 'Account',
  account_created: 'The account has been created successfully.<br>Now you can login.',
  cart: 'Cart',
  your_cart: 'Your cart',
  your_cart_empty: 'Your cart is currently empty',
  username_email: 'E-mail',
  lost_password: 'Forgot your password?',
  send: 'Send',
  register: 'Create an account',
  create_accunt: 'Create account',
  profile: 'Profile',
  delete: 'Delete',
  close: 'Close',
  empty_cart: 'Empty Cart',
  checkout: 'Checkout',
  total: 'Total',
  subtotal: 'Subtotal',
  order: 'Order',
  orders: 'Orders',
  addresses: 'Addresses',
  address_1: 'Address',
  address_2: 'Address details',
  address_2_placeholder: 'Apartment, suit, unit, etc.',
  shipping: 'Shipping',
  shipping_exstimated: 'Extimated shipping',
  shipping_method: 'Shipping method',
  shipping_methods: 'Shipping methods',
  shipping_methods_empty_states:
    'Please enter your address to know the available shipping methods.',
  payment_method: 'Payment method',
  payment_methods: 'Payment methods',
  pay_for_order: 'Pay for order',
  discount: 'Discount',
  account_details: 'Account details',
  details: 'Details',
  order_updates: 'Order updates',
  logout: 'Logout',
  place_order: 'Place Order',
  coupon: 'Coupon',
  add_coupon: 'Apply',
  title_coupon: 'Do you have a discount code?',
  insert_coupon: 'Insert your coupon',
  create_account: 'Create account',
  ship_different_address: 'Ship to a different address?',
  terms: 'I agree with your terms and conditions.',
  no_order: 'No order has been placed yet.',
  go_to: 'Go to',
  go_to_shop: 'Go back to shop',
  go_to_checkout: 'Proceed to checkout',
  go_to_payment: 'Proceed to payment',
  cancel: 'Cancel',
  cancel_order: 'Cancel order',
  cancel_order_dialog: 'Are you sure you want to cancel this order?',
  thank_you: 'Thank you for your order!',
  thank_you_error: 'There is a problem with your order...',
  order_received: 'Your order has been received',
  order_failed:
    'Unfortunately your order cannot be processed as the originating bank/merchant has declined your transaction. Please attempt your purchase again',
  order_infos: 'Order information',
  order_details: 'Order details',
  show_order_summary: 'Show order summary',
  hide_order_summary: 'Hide order summary',
  is_business: 'Request invoice',
  add_business: 'Request invoice',
  vat_number: 'VAT number',
  fiscal_code: 'Fiscal code',
  pec_email: 'PEC email',
  sdi_code: 'SDI code',
  vat: 'VAT',
  vat_extimated: 'Extimated VAT',
  excluded_vat: 'ex. VAT',
  excluded_vat_extended: 'excluded VAT',
  future_payments: 'Future payments',
  remove: 'Remove',
  may_be_interested_in: 'You may  be interested in...',
  no_saved_card: 'You have no saved cards',
  save_card: 'Save card for future payments',
  cancel_card: 'Delete card',
  cancel_card_dialog: 'Are you sure you want to delete this card?',
  back_to_cart: 'Back to cart',
  bank: 'Bank',
  account_number: 'Account number',
  iban: 'IBAN',
  bic_swift: 'BIC/SWIFT',
  ending_in: 'ending in',
  expires: 'expires',
  use_new_card: 'Use a new credit card',
  credit_card_incomplete: 'Credit card form incomplete',
  saved_cards: 'Saved cards',
  order_cancelled: 'Your order was cancelled',
  order_updated: 'Your order was updated',
  cannot_pay_for_order: 'You cannot pay for this order',
  go_to_orders: 'Go to your orders',
  refunded: 'Refunded',
  accounte_creation_needed:
    'You have to create an account to place this order. Plese, login if you\'ve already one.',
  subscriptions: ' Subscriptions',
  subscription: ' Subscription',
  subscription_details: ' Subscription details',
  subscription_totals: ' Subscription totals',
  no_subscription: 'You have no active subscriptions.',
  shipping_subscription_address: 'Shipping subscription address',
  start_date: 'Start date',
  last_order_date: 'Last order date',
  end_date: 'End date',
  next_payment_date: 'Next payment date',
  change_payment: 'Change payment',
  change_payment_method: 'Change payment method',
  changed_payment_method: 'Your payment method was updated',
  renew_now: 'Renew now',
  related_orders: 'Related orders',
  cancel_subscription: 'Cancel subscription',
  cancel_subscription_dialog: 'Are you sure you want to cancel this subscription?',
  error_required: 'The field is required',
  error_email: 'The email is not valid',
  error_min_pre: 'The field must have at least',
  error_min_after: 'characters',
  error_confirmed_pre: 'The field must be the same of',
  error_confirmed_after: '',
  error_user_update:
    'A problem occurred while saving the data. <br>Please check them before retrying.',
  error_user_addresses_update: 'A problem occurred while saving the data.',
  wrong_login_data: 'Incorrect data. <br>Please try again or reset your password.',
  wrong_mail: 'This email is not registered in this website.',
  error_generic: 'An error occurred',
  error_generic_login: 'Something went wrong during the login. You should login again.',
  error_generic_customer: 'Cannot get customer data.',
  success_newsletter: 'Thank you for subscribing!',
  external_url: 'Buy here',
  subscribe: 'Subscribe',
  notification_cta: 'Notify me',
  notification_submit_cta: 'Sign up',
  notification_success: 'We will notify when the product is available.',
};

labels.install = () => {
  Vue.prototype.$labels = labels;
};

Vue.use(labels);

export default labels;

<template>
  <Block
    :data="{
      cta: {
        title: 'Go back to the shop',
        url: '/shop'
      },
      icon: 'arrow-left',
      theme: 'go-back',
      background: '#2B2C2F'
    }"
  />
</template>

<script>
import Block from '@/components/blocks/block';

export default {
  name: 'GoBack',
  components: {
    Block,
  },
};
</script>

<style>

</style>

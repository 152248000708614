<template>
  <Flex :class="[minify ? 'tags--minified' : false]">
    <Cta
      v-for="tag in computedData"
      :key="tag.term_id"
      class="tag"
      :data="{
        title: tag.name,
        url: isNaN(tag.term_id) ? tag.slug : `/${postType === 'post' ? 'magazine' : postType}/tag/${tag.slug}`,
        size: 'label'
      }"
    />
    <div
      v-if="minify && ((computedData.length > 4 && !$mq.isMobile) || (computedData.length > 2 && $mq.isMobile))"
      class="tag tag-more"
    >
      ...
    </div>
  </Flex>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Tags',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
    minify: {
      type: Boolean,
      default: false,
    },
    postType: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['postTypesLabel']),
    computedData() {
      if (this.$route.name === 'Index' && this.postType) {
        const postType = {
          term_id: this.postType,
          slug: this.postType === 'post' ? '/magazine' : '/publishing',
          name: this.postTypesLabel(this.postType),
        };
        if (this.data) {
          return [
            postType,
            ...this.data,
          ];
        }
        return [postType];
      }
      return this.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  font-size: 12px;
  line-height: 22px;
  height: 22px;
  padding: 0 var(--spacer-xs);
  border: 1px solid currentColor;
  white-space: nowrap;
  margin: 0 4px 4px 0;
}

.tags--minified {
  .tag {
    @include mq($until: m) {
      &:nth-child(n+3) {
        display:none;
      }

      &.tag-more {
        display: block;
      }
    }
    @include mq(m) {
      &:nth-child(n+5) {
        display:none;
      }

      &.tag-more {
        display: block;
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{class:['thumb', `thumb--${_vm.parsedThumb}`],attrs:{"to":_vm.$relativeUrl(_vm.data.link)}},[(_vm.parsedThumb !== 'only-text')?_c('div',{staticClass:"media"},[(_vm.data.gds_featured_image)?_c('Figure',{attrs:{"data":Object.assign(_vm.data.gds_featured_image, { lazyload: true })}}):_vm._e(),(
        (_vm.data.gds_taxonomies && _vm.data.gds_taxonomies.tags && _vm.data.acf.template !== 'past-issue') ||
          _vm.$route.name === 'Index'
      )?_c('Tags',{staticClass:"tags",attrs:{"data":_vm.data.gds_taxonomies ? _vm.data.gds_taxonomies.tags : [],"post-type":_vm.data.type,"minify":""}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"abstract"},[_c('Spacer',{staticClass:"abstract-wrapper",attrs:{"top":"xs","bottom":"xxs","x":"s"}},[_c('Flex',{staticClass:"wrapper",attrs:{"dir":"column"}},[_c('Spacer',{staticClass:"title title-wrapper",attrs:{"bottom":"s"}},[(
              _vm.parsedThumb === 'only-text' &&
                ((_vm.data.gds_taxonomies && _vm.data.gds_taxonomies.tags) || _vm.$route.name === 'Index')
            )?_c('Spacer',{attrs:{"y":"s"}},[(
                (_vm.data.gds_taxonomies &&
                  _vm.data.gds_taxonomies.tags &&
                  _vm.data.acf.template !== 'past-issue') ||
                  _vm.$route.name === 'Index'
              )?_c('Tags',{staticClass:"tags",attrs:{"data":_vm.data.gds_taxonomies ? _vm.data.gds_taxonomies.tags : [],"post-type":_vm.data.type,"minify":""}}):_vm._e()],1):_vm._e(),_c('Title',{staticClass:"title",attrs:{"data":{
              value: _vm.data.title.rendered,
              size:
                _vm.parsedThumb !== 'horizontal-xl' && _vm.parsedThumb !== 'only-text' && !_vm.$mq.isMobile
                  ? 'm'
                  : 'l',
            }}}),(_vm.data.gds_taxonomies && _vm.data.gds_taxonomies.tax_author)?_c('Title',{staticClass:"title",attrs:{"data":{ value: `by ${_vm.data.gds_taxonomies.tax_author[0].name}`, size: 'm' }}}):_vm._e()],1),_c('Flex',{class:['meta'],attrs:{"justify":"space-between"}},[_c('Column',{attrs:{"size":_vm.parsedThumb !== 'only-text' ? 'auto' : '12'}},[_c('time',{staticClass:"typo--label"},[_vm._v(_vm._s(_vm.date))])]),(_vm.data.acf.template !== 'past-issue' && _vm.data.reading_time)?_c('Column',{attrs:{"size":_vm.parsedThumb !== 'only-text' ? 'auto' : '12'}},[_c('div',{staticClass:"typo--label t-center reading-time"},[_c('span',{staticClass:"reading-time-label"},[_vm._v("Reading Time ")]),_vm._v(_vm._s(_vm.data.reading_time)+"′ ")])]):_vm._e(),(_vm.category)?_c('Column',{attrs:{"size":_vm.parsedThumb !== 'only-text' ? 'auto' : '12'}},[_c('div',{class:['typo--label', _vm.parsedThumb !== 'only-text' ? 't-right' : 't-center']},[_vm._v(" "+_vm._s(_vm.category.name)+" ")])]):_vm._e()],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.footer)?_c('footer',{staticClass:"page-footer typo--s-alt"},[_c('Grid',{attrs:{"col":{ default: 12, m: 3 }}},_vm._l((_vm.footer.information),function(info,index){return _c('Flex',{key:index},[(_vm.$mq.isMobile)?[_c('AccordionWrapper',[_c('AccordionItem',[_c('template',{slot:"accordion-trigger"},[_c('Flex',{staticClass:"col-header",attrs:{"align":"center"}},[_c('div',{staticClass:"typo--label-s"},[_vm._v(" "+_vm._s(info.title)+" ")])])],1),_c('template',{slot:"accordion-content"},[_c('div',{staticClass:"col-body"},[(info.type === 'text')?[_c('div',{domProps:{"innerHTML":_vm._s(info.text)}})]:(info.type === 'menu')?[_c('ul',_vm._l((_vm.menu(info.menu).items),function(item){return _c('li',{key:item.id,class:[...item.css]},[_c('Cta',{attrs:{"data":{
                          url: item.url,
                          title: item.content,
                          target: item.target,
                        }}})],1)}),0)]:_vm._e()],2)])],2)],1)]:[_c('Flex',{staticClass:"col-header",attrs:{"align":"center"}},[_c('div',{staticClass:"typo--label-s"},[_vm._v(" "+_vm._s(info.title)+" ")])]),_c('div',{staticClass:"col-body"},[(info.type === 'text')?[_c('div',{domProps:{"innerHTML":_vm._s(info.text)}})]:(info.type === 'menu')?[_c('ul',_vm._l((_vm.menu(info.menu).items),function(item){return _c('li',{key:item.id,class:[...item.css]},[_c('Cta',{attrs:{"data":{
                    url: item.url,
                    title: item.content,
                    target: item.target,
                  }}})],1)}),0)]:_vm._e()],2)]],2)}),1),_c('Grid',{staticClass:"impressum",attrs:{"align":"center"}},[_c('Column',{attrs:{"size":{
        default: 12,
        m: _vm.footer.credits && _vm.footer.credits !== '' ? 9 : 12,
      }}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.footer.description)}})]),(_vm.footer.credits && _vm.footer.credits !== '')?_c('Column',{staticClass:"credits",attrs:{"size":{ default: 12, m: 3 }}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.footer.credits)}})]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
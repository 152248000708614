<template>
  <div v-html="product.type !== 'external' ? product.price_html : product.short_description" />
  <!--
    Per personalizzare prezzo
    <span>
    <del
      v-if="product.sale_price"
      v-html="parseCurrency(product.regular_price)"
    />
    <component
      :is="product.sale_price ? 'ins' : 'span'"
      v-html="parseCurrency(product.price)"
    />
  </span> -->
</template>

<script>
import { mapGetters } from 'vuex';

import { parseCurrency } from '@/assets/js/utils-wc';

export default {
  name: 'Price',
  components: {},
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['wcSettings']),
  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style lang="scss" scoped>
div /deep/ del + ins {
  padding-left: var(--spacer-xs);
}
</style>

<template>
  <transition name="snackbar">
    <div
      v-if="active"
      :class="['snackbar', 'typo--xs', `snackbar-theme--${theme}`]"
    >
      <Spacer all="s">
        <Flex align="center">
          <Icon
            v-if="theme === 'error'"
            name="alert"
            size="s"
          />
          <Spacer
            left="s"
            right="s"
            v-html="content"
          />
        </Flex>
        <Icon
          name="close"
          size="xs"
          :fn="close"
          class="icon--close"
        />
      </Spacer>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/icon';

export default {
  name: 'Snackbar',
  components: {
    Icon,
  },
  props: {
    timeout: {
      type: Number,
      default: 3500,
    },
  },
  computed: {
    ...mapGetters(['snackbar']),
    active() {
      return this.snackbar.active;
    },
    theme() {
      if (this.snackbar.theme) {
        return this.snackbar.theme;
      }
      return 'error';
    },
    content() {
      if (this.theme === 'error' && !this.snackbar.content) {
        return this.$labels.error_generic;
      }
      return this.snackbar.content;
    },
    autoClose() {
      return this.snackbar.autoClose ?? true;
    },
  },
  watch: {
    active() {
      if (this.active) {
        if (this.autoClose) {
          setTimeout(() => {
            this.$store.dispatch('closeSnackbar');
            this.$store.commit('SET_SNACKBAR', { active: false, content: null });
          }, this.timeout);
        }
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('SET_SNACKBAR', { active: false, content: null });
    },
  },
};
</script>

<style lang="scss">
.snackbar-enter-active,
.snackbar-leave-active {
  transition: all 0.3s ease;
}
.snackbar-enter,
.snackbar-leave-to {
  transform: translateY(200%);
}
.snackbar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: $unit * 2;
  margin: 0 auto;
  width: $unit * 30;
  max-width: calc(100vw - #{$unit}* 3);
  border-radius: var(--border-radius);

  z-index: 30;
  .flex {
    flex-wrap: nowrap;
    min-height: 30px;
  }
  &-theme {
    // &--default {
    // }
    &--error {
      background: var(--warning-color);
      color: var(--white);
      path {
        fill: var(--white);
      }
    }
    &--success {
      background: var(--grey-xd);
      color: var(--white);
      path {
        fill: var(--white);
      }
    }
  }
}
</style>

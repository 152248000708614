<template>
  <Flex
    tag="nav"
    dir="column"
  >
    <div
      v-for="item in navigation"
      :key="item.id"
      :class="[...item.css]"
      class="item-wrapper"
    >
      <div class="item">
        <Spacer
          x="s"
          top="1"
        >
          <a
            v-if="item.target"
            :href="item.url"
            :target="item.target"
            v-html="item.content"
          />
          <router-link
            v-else
            :to="$relativeUrl(item.url)"
            v-html="item.content"
          />
        </Spacer>
      </div>

      <Flex
        class="categories"
        dir="column"
      >
        <Spacer
          v-for="subitem in item.children"
          :key="subitem.id"
          class="subitem"
          x="s"
        >
          <router-link
            :to="$relativeUrl(subitem.url)"
            v-html="subitem.content"
          />
        </Spacer>
      </Flex>
    </div>

    <div class="footer">
      <Flex
        class="social"
        column-gap="m"
        tag="ul"
      >
        <li><a href="#!">FB</a></li>
        <li><a href="#!">PT</a></li>
        <li><a href="#!">TW</a></li>
        <li><a href="#!">IG</a></li>
      </Flex>
    </div>
  </Flex>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  props: {
    fn: {
      type: Function,
      default: () => {},
    },
    current: {
      type: Number,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
  beforeDestroy() {
    document.body.classList.remove('overlay-menu');
  },
};
</script>

<style lang="scss" scoped>
nav {
  position: absolute;
  left: 0;
  // top: var(--bar-height);
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - var(--bar-height));
  z-index: 10;
  background: var(--white);

  .app--home-banner & {
    top: calc(var(--bar-height) + 16.666667vw);
  }

  .router-link-active {
    color: var(--grey);
  }

  .item {
    background: var(--grey-xl);
    height: var(--bar-height);
  }

  .subitem {
    height: 30px;
  }

  .item-wrapper {
    margin-bottom: var(--spacer-s);
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--white);

    .social {
      padding: 0 var(--spacer-s);
    }
  }
}
</style>

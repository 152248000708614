var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"marquee-container",style:({
    '--bg': _vm.data.background_color,
    '--color': _vm.data.color,
  })},[_c('div',{class:['marquee-overflow', !_vm.loaded ? 'marquee-loading' : false],style:({
      '--marquee-width': `${_vm.totalWidth}px`,
      '--marquee-duration': `${_vm.duration}s`,
      '--marquee-direction': _vm.direction > 0 ? 'reverse' : undefined,
    })},[_c('div',{class:['marquee-wrapper', _vm.switchDuplicate ? 'marquee-wrapper-start' : false]},[_c('div',{ref:"marquee",staticClass:"marquee",attrs:{"data-direction":_vm.data.direction,"align":"center"}},_vm._l((_vm.wrappers),function(wrapper){return _c('div',{key:wrapper,staticClass:"item-wrapper"},_vm._l((_vm.data.items),function(item,index){return _c('Flex',{key:index,class:['item', `item--${item.type}`],attrs:{"align":"center"}},[_c('Spacer',{attrs:{"x":"s","top":"1"}},[(item.type === 'text' && item.url)?_c('Cta',{attrs:{"data":{
                  title: item.text,
                  url: item.url,
                  size: 'label-s'
                }}}):(item.type === 'text' && !item.url)?_c('Title',{attrs:{"data":{
                  value: item.text,
                  size: 'label-s'
                }}}):_c('Figure',{ref:"img",refInFor:true,attrs:{"skip-load":!_vm.switchDuplicate && !_vm.isResizing,"data":{...item.image, lazyload: false, ratio: false, object_fit: 'contain', relative: true }}})],1)],1)}),1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
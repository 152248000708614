<template>
  <div
    :class="[
      'cta',
      `cta-theme--${theme}`,
      data.size ? `typo--${data.size}` : null,
      data.toggle ? 'cta-toggle' : null,
      disabled ? 'cta-disabled' : null,
    ]"
  >
    <template v-if="data.fn">
      <button @click="!disabled ? data.fn() : null">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="modal">
      <button @click="openModal">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="hash">
      <button @click="goTo">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="external">
      <a
        :href="data.url"
        :target="data.target"
      >
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link :to="url">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Cta',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      if (
        this.data.url
        && typeof this.data.url === 'string'
        && process.env.VUE_APP_BASE_URL
        && process.env.VUE_APP_BASE_URL !== '/'
      ) {
        return this.$relativeUrl(this.data.url.replace(process.env.VUE_APP_BASE_URL, ''));
      }
      return this.data.url;
    },
    external() {
      return this.url && typeof this.url === 'string' && this.url.startsWith('http');
    },
    hash() {
      return this.url && typeof this.url === 'string' && this.url.startsWith('#');
    },
    modal() {
      return this.url && typeof this.url === 'string' && this.url.startsWith('#modal-');
    },
  },
  methods: {
    openModal() {
      if (!this.disabled) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      }
    },
    goTo() {
      if (!this.disabled) {
        const hash = this.data.url;
        gsap.killTweensOf(window);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(hash),
            offsetY: 0, // header height
            autoKill: true,
          },
          duration: 1,
          ease: 'expo.out',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: inline-block;

  &.router-link-active {
    // style
  }
}

.cta-disabled {
  // cursor: progress;
  opacity: 0.5;

  * {
    // cursor: progress !important;
    pointer-events: none !important;
  }
}

button {
  font-size: inherit;
  line-height: inherit;
}

.cta {
  a,
  button {
    display: inline-block;
    line-height: normal;
  }
  &-theme {
    &--default {
    }
    &--button,
    &--button-light,
    &--button-white,
    &--button-outline {
      a,
      button {
        display: block;
        width: 100%;
        background: var(--grey-xxd);
        color: var(--white);
        padding: var(--spacer-1) var(--spacer-s) 0;
        text-align: center;
        height: 30px;
        @extend %typo--label;

        div {
          line-height: 30px;
        }
      }
    }

    &--button-light {
      a,
      button {
        background: var(--grey-xl);
        color: var(--grey-xxd);
      }
    }

    &--button-white {
      a,
      button {
        background: var(--white);
        color: var(--grey-xxd);
        border: 1px solid var(--grey-xl);
      }
    }

    &--button-outline {
      a,
      button {
        background: none;
        color: var(--grey-xxd);
        border: 1px solid var(--grey-xxd);
      }
    }

    @include mq($and: $hover) {
      &--button {
        a,
        button {
          &:hover {
            background: var(--black);
          }
        }
      }

      &--button-light {
        a,
        button {
          &:hover {
            background: var(--grey-l);
          }
        }
      }

      &--button-white {
        a,
        button {
          &:hover {
            background: var(--grey-xxxl);
          }
        }
      }
    }
  }

  &-toggle {
    a,
    button {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>

<template>
  <div>
    <Spacer
      class="checkout__header"
      y="s"
    >
      <Flex
        class="flex-auto"
        justify="space-between"
        align="end"
      >
        <router-link
          class="checkout__back typo--label-m"
          to="/cart"
        >
          <Spacer>
            <Flex align="center">
              <Spacer
                v-if="!$mq.isMobileXS"
                left="s"
              >
                {{ $labels.back_to_cart }}
              </Spacer>
            </Flex>
          </Spacer>
        </router-link>
        <Flex
          align="center"
          justify="center"
        >
          <Spacer class="typo--l checkout__title ">
            {{ $labels.checkout }}
          </Spacer>
        </Flex>
        <div />
      </Flex>
    </Spacer>

    <!--  -->
    <Wrapper
      v-if="!firstLoading"
      class="checkout__content"
    >
      <Grid
        align="start"
      >
        <Column :size="{ default: 12, s: 6, l: 8 }">
          <Grid
            col="12"
            class="checkout__left"
            row-gap="s"
          >
            <AccordionWrapper>
              <!-- Account -->
              <AccordionItem class="block-theme--default">
                <template slot="accordion-trigger">
                  <div class="form-wrapper__header">
                    <div
                      v-if="user"
                      class="typo--m"
                    >
                      {{ $labels.welcome_back }} {{ userNiceName }}.
                    </div>
                    <div
                      v-else
                      class="typo--m"
                    >
                      {{ $labels.do_you_have_account }}
                    </div>
                  </div>
                </template>
                <template slot="accordion-content">
                  <Spacer x="s">
                    <div v-if="user">
                      <Spacer>
                        <!-- bottom="l" -->
                        {{ $labels.logged_in_with_mail }}
                        <a :href="'mailto:' + user.user_email">{{
                          user.user_email
                        }}</a>.
                      </Spacer>
                      <!-- <Cta
                    :data="{
                      title: $labels.logout,
                      url: {name: 'Logout'},
                      fn: logout
                    }"
                  /> -->
                    </div>
                    <Grid
                      v-else
                      grid="1"
                      row-gap="m"
                    >
                      <Wrapper
                        size="xs"
                        :margin="false"
                      >
                        <Form
                          id="login"
                          theme="inline"
                        />
                        <LoginSocial />
                        <Spacer
                          top="s"
                        >
                          <Flex justify="space-between">
                            <Cta
                              :data="{
                                title: $labels.lost_password,
                                fn: toggleRecoverPassword,
                              }"
                            />
                          </Flex>
                        </Spacer>
                        <Spacer
                          top="s"
                        >
                          <Form
                            v-if="showRecoverPassword"
                            id="recovery-password"
                            theme="inline"
                          />
                        </Spacer>
                      </Wrapper>
                    </Grid>
                  </Spacer>
                </template>
              </AccordionItem>
            </AccordionWrapper>
            <Form
              id="checkout"
              ref="form"
              class="form--multi"
            />
          </Grid>
        </Column>
        <Column
          :size="{ default: 12, s: 6, l: 4 }"
          class="checkout__right"
        >
          <CheckoutOrder />
        </Column>
      </Grid>
    </Wrapper>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import isEmpty from 'lodash.isempty';
import { mapGetters } from 'vuex';

import { getCartPaymentGateways } from '@/api';
import { stripeMountCard } from '@/assets/js/utils-wc';

import Form from '@/components/form';
import Cta from '@/components/typo/cta';
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import CheckoutOrder from '@/components/wc/checkout-order';
import LoginSocial from '@/components/wc/login-social';

export default {
  name: 'Checkout',
  components: {
    Form,
    Cta,
    AccordionWrapper,
    AccordionItem,
    CheckoutOrder,
    LoginSocial,
  },
  data() {
    return {
      firstLoading: true,
      paymentMethod: null,
      stripe: null,
      stripeCard: null,
      stripeClientSecret: null,
      cartPaymentGateways: null,
      showRecoverPassword: false,
      // hasPaymentIntent: false,
      // cartLoaded: this.cartLoading,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'cart',
      'customer',
      'paymentGateways',
      'paymentGatewaysActive',
      'paymentMetas',
      'cartLoading',
    ]),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
    userNiceName() {
      if (this.customer && this.customer.first_name) {
        return this.customer.first_name;
      }
      return this.user.display_name;
    },
  },
  watch: {
    cartLoading() {
      // if (!val) this.cartLoaded = true;
      if (this.cartLoading === false) {
        this.firstLoading = false;
        if (this.isCartEmpty) {
          this.$router.push('/shop');
        }
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('getCart');

    // Marketing
    this.$bus.$emit('beginCheckout', this.cart);

    const { data } = await getCartPaymentGateways();
    this.$store.commit('SET_PAYMENT_GATEWAYS_ACTIVE', Object.keys(data));
    this.cartPaymentGateways = Object.keys(data);
    if (
      this.paymentGateways
      && this.paymentGateways.stripe
      && this.paymentGateways.stripe.publishable_key
    ) {
      const stripe = await loadStripe(
        this.paymentGateways.stripe.publishable_key,
        {
          locale: 'en', // TODO: get from WPML
        },
      );
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      // await createAndStorePaymentIntent(this.$store);
      stripeMountCard(this.$store, this.paymentMetas);
    }
    // this.hasPaymentIntent = true;
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    async setPaymentGateway() {
      const body = {
        gateway_id: this.paymentMethod,
      };
      this.$store.dispatch('setPaymentGateway', body);
    },
    toggleRecoverPassword() {
      this.showRecoverPassword = !this.showRecoverPassword;
    },
  },
};
</script>

<style lang="scss">
.checkout__header {
  background: #FBFBFB;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  & > * {
    height: 100%;
    position: relative;
  }
}

.checkout__content {
  padding-top: 60px;
}
.checkout__left {
  padding-top: var(--spacer-s);

  .block-theme--default {
    padding-bottom: var(--spacer-xs);
    margin-bottom: var(--spacer-xs);
    border-bottom: 1px solid var(--grey-xl);
  }

  .accordion__trigger .form-wrapper__header {
    padding-bottom: 4px;
  }
}

.checkout__right {
  background: #F6F6F6;
  padding: var(--spacer-s);

  @include mq(s) {
    position: sticky;
    top: 60px;
    min-height: calc((var(--vh, 1vh) * 100) - 60px);
  }

  // Test to hide scrollbar

  // &::-webkit-scrollbar {
  //   width: 10px;
  // }
  // &::-webkit-scrollbar-track {
  //   background: transparent;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: #888;
  //   border-right: 4px solid transparent;
  // }
  // &::-webkit-scrollbar-thumb:hover {
  //   background: #555;
  // }
}
</style>

<template>
  <Grid
    col="12"
    row-gap="0"
    :class="['cart-list', `cart-list--${theme}`]"
  >
    <div
      v-for="(cartItem, index) in cart.items"
      :key="index"
      :data-key="cartItem.key"
      :data-quantity="cartItem.quantity"
      class="cart__item"
    >
      <Wrapper
        size="m"
        :margin="false"
      >
        <Flex justify="space-between">
          <Flex
            nowrap
          >
            <!-- <Figure :data="{ default: { sizes: { l: cartItem.product_image } }, ratio: false }" /> -->
            <div
              class="wc-product-image wc-product-image--l"
              :style="{
                backgroundImage: `url(${cartItem.product_image})`
              }"
            />
            <Flex
              dir="column"
              justify="space-between"
            >
              <Spacer y="xs">
                <router-link
                  :to="{name: 'SingleProduct', params: {
                    slug: cartItem.slug
                  }}"
                  class="title"
                >
                  {{ cartItem.product_name }}
                </router-link>

                <Spacer
                  top="s"
                >
                  <div v-html="getLineTotal(cartItem)" />
                </Spacer>
              </Spacer>
              <Spacer
                y="xs"
                class="cart__item__footer"
              >
                <Flex
                  v-if="theme !== 'checkout'"
                  align="center"
                >
                  <Number
                    :key="cartLoading"
                    :data="{
                      id: `number`,
                      fn: numberChanged,
                      itemKey: cartItem.key,
                      readonly: cartLoading
                    }"
                    :value="cartItem.quantity"
                  />
                  <Spacer left="s">
                    <a
                      class="button cart__remove-item typo--s"
                      @click="removeCartItem(cartItem)"
                    >
                      {{ $labels.remove }}
                    </a>
                  </Spacer>
                </Flex>
              </Spacer>
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
      <!-- <hr v-if="theme === 'complete' && index < (cart.items.length - 1)"> -->
    </div>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';

import { parseCurrency, unparseCurrency } from '@/assets/js/utils-wc';

import Number from '@/components/form/fields/number';
// import Figure from '@/components/media/figure';

export default {
  name: 'CartList',
  components: {
    Number,
    // Figure,
  },
  props: {
    theme: {
      type: String,
      default: 'complete',
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(['cart', 'cartLoading', 'showCart', 'wcSettings']),
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      return this.parseCurrency(
        this.unparseCurrency(this.cart.totals.subtotal)
          + this.unparseCurrency(this.cart.totals.subtotal_tax),
      );
    },
    generalSpacerX() {
      if (this.theme === 'checkout') {
        return false;
      }
      return this.theme === 'complete' ? 'l' : 'm';
    },
    generalSpacerY() {
      if (this.theme === 'checkout') {
        return 's';
      }
      return false;
    },
  },
  watch: {},
  async mounted() {
    // const request = {
    //   type: 'product',
    //   params: {
    //     // include: [this.cart.items.map((x) => x.product_id)],
    //     per_page: 100,
    //     offset: 0,
    //     lang: this.$store.state.lang,
    //   },
    // };
    // this.products = await this.$store.dispatch('getItems', request);
  },
  methods: {
    updateCartItem(key, quantity) {
      const item = this.cart.items.find((cartItem) => cartItem.key === key);
      const prevQuantity = this.$el.querySelector(`[data-key="${key}"]`) ? this.$el.querySelector(`[data-key="${key}"]`).dataset.quantity : 0;
      this.$store.dispatch('updateCartItem', Object.assign(item, { quantity, quantityDelta: quantity - prevQuantity }));
    },
    numberChanged(val) {
      if (val[0]) {
        this.updateCartItem(val[0], val[1]);
      } else {
        // $event
        const { target } = val;
        this.updateCartItem(target.dataset.key, target.value);
      }
    },
    removeCartItem(item) {
      const { key } = item;
      const prevQuantity = this.$el.querySelector(`[data-key="${key}"]`) ? this.$el.querySelector(`[data-key="${key}"]`).dataset.quantity : 0;
      this.$store.dispatch('removeCartItem', Object.assign(item, { quantityDelta: prevQuantity }));
    },
    getLineTotal(cartItem) {
      if (this.taxDisplayCart === 'excl') {
        return this.parseCurrency(cartItem.line_subtotal);
      }
      return this.parseCurrency(
        cartItem.line_subtotal + cartItem.line_subtotal_tax,
      );
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    // getProductProp(id, element) {
    //   const product = this.products.find((p) => p.id === id);
    //   if (product) {
    //     if (element === 'image' && product.gds_featured_image) {
    //       return product.gds_featured_image.sizes.woocommerce_thumbnail;
    //     }
    //     return product[element];
    //   }
    //   return null;
    // },
    getCartItemSpacerBottom(index) {
      if (this.theme === 'complete') {
        return 'l';
      }
      return index === this.cart.items.length - 1 ? false : 'm';
    },
    getCartItemSpacerTop(index) {
      if (this.theme === 'side' && index === 0) {
        return 'm';
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-list {
  &:after {
    content: " ";
    display: block;
    width: auto;
    height: 1px;
    background: var(--grey-xl);
    margin: 0;
  }
}

.cart__item + .cart__item {
  margin-top: 1px;

  &:before {
    content: " ";
    display: block;
    width: calc(100% - 120px);
    height: 1px;
    background: var(--grey-xl);
    margin: 0 0 0 auto;
  }
}

.cart-list--checkout:after {
  margin-top: var(--spacer-s);
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  margin-right: var(--spacer-l);
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Flex',{staticClass:"menu-user",attrs:{"tag":"nav"}},[_c('Flex',{staticClass:"categories",attrs:{"align":"center"}},[_c('Spacer',{attrs:{"x":"s","top":"1"}},[_c('Cta',{attrs:{"data":{
          title: _vm.$labels.account_details,
          url: {name: 'EditAccount'},
        }}})],1),_c('Spacer',{attrs:{"x":"s","top":"1"}},[_c('Cta',{attrs:{"data":{
          title: _vm.$labels.orders,
          url: {name: 'Orders'},
        }}})],1),_c('Spacer',{attrs:{"x":"s","top":"1"}},[(_vm.areSubscriptionsActive)?_c('Cta',{attrs:{"data":{
          title: _vm.$labels.subscriptions,
          url: {name: 'Subscriptions'},
        }}}):_vm._e()],1),_c('Spacer',{attrs:{"x":"s","top":"1"}},[_c('Cta',{attrs:{"data":{
          title: _vm.$labels.logout,
          url: {name: 'Account'},
          fn: _vm.logout
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
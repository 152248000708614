<template>
  <Spacer
    :class="[
      'archive',
      data.archive_type === 'archive'
        ? `archive--${data.archive}`
        : `archive--${data.posts[0].post_type}`,
      data.template ? `archive--${data.template}` : false,
      items.length > 1 && data.template !== 'shop' ? `archive-overflow` : false,
    ]"
  >
    <Title
      v-if="data.title"
      :data="{ value: data.title, size: 'label-s' }"
      class="title"
    />
    <component
      :is="data.carousel ? 'Carousel' : 'Grid'"
      v-if="items.length > 0"
      :extra-settings="{
        cellAlign: 'left',
        prevNextButtons: items.length > 2 || $mq.isMobile,
        template: 'posts',
        autoPlay: data.autoplay ? 2000 : false,
        pauseAutoPlayOnHover: false,
      }"
      :col="data.template === 'category' || data.template === 'shop' ? undefined : gridCol"
      :template="gridTemplate"
      :class="['archive-grid', `archive-grid--${data.thumb}`]"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <transition
          name="fade"
          appear
        >
          <component
            :is="thumb(item.type)"
            :data="item"
            :thumb="
              (index + 1 - 3) % 5 === 0 && data.template === 'category'
                ? 'horizontal-xl'
                : data.thumb
            "
          />
        </transition>
        <Adv
          v-if="(index + 1 - 3) % 10 === 0 && data.template === 'category' && advs['page-section']"
        />
      </div>
    </component>

    <div v-if="items.length === 0">
      <Skeleton
        :items="skeletonItems"
        :cols="gridCol"
        :thumb-component="data.archive_type === 'archive' ? data.archive : data.posts[0].post_type"
        :thumb-theme="data.thumb"
        :class="['archive-grid', `archive-grid--${data.thumb}`]"
      />
    </div>

    <div
      v-show="items && data.infinite_scroll"
      ref="infinite"
      class="infinite"
    />

    <Cta
      v-show="items && data.infinite_scroll && counter >= 3 && showLoadMore"
      class="load-more"
      :data="{
        title: 'Load more',
        fn: requestOffset,
      }"
      theme="button"
    />

    <Cta
      v-if="data.cta"
      :data="data.cta"
    />
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import archive from '@/mixins/archive';

import Thumb from '@/components/thumb';
import ThumbProduct from '@/components/thumb/product';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Skeleton from '@/components/ui/skeleton';
import Adv from '@/components/blocks/custom/adv';
import Carousel from '@/components/layout/carousel';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('product', ThumbProduct);
thumbs.set('simple', ThumbProduct);
thumbs.set('variable', ThumbProduct);
thumbs.set('subscription', ThumbProduct);
thumbs.set('variable-subscription', ThumbProduct);
thumbs.set('grouped', ThumbProduct);
thumbs.set('external', ThumbProduct);

export default {
  name: 'Archive',
  components: {
    Title,
    Cta,
    Skeleton,
    Adv,
    Carousel,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size, layout_type } = layout;
          cols = {
            ...cols,
            [media_query]:
              layout_type === 'carousel'
                ? 12
                : layout_size === 'auto'
                  ? (12 / this.items.length).toString()
                  : layout_size,
          };
        });
      } else {
        cols = {
          default: 12,
          m:
            this.items && (12 / this.items.length) % 3 === 0
              ? 12 / this.items.length
              : this.data.archive_type === 'posts' && (12 / this.data.posts.length) % 3 === 0
                ? 12 / this.data.posts.length
                : 4,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'grid';
      }
      return template;
    },
    ...mapGetters(['advs']),
  },
  methods: {
    thumb(key) {
      return key && thumbs.get(key) ? thumbs.get(key) : thumbs.get('default');
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite {
  position: absolute;
  height: 50vh;
  visibility: hidden;
  pointer-events: none;
  bottom: 0;
  left: 0;
  width: 100%;
}

.title {
  height: var(--bar-height);
  padding: var(--spacer-1) var(--spacer-s) 0;
  background: var(--grey-d);
  color: var(--white);
  line-height: var(--bar-height);
}

.archive {
  position: relative;
  // background: var(--grey-xl);

  &-grid {
    @include mq($until: m) {
      row-gap: var(--row-gap-xs);
    }
  }

  &-overflow {
    overflow: hidden;
  }

  &--product {
    background: var(--white);
  }
}

.item {
  background: var(--white);
}

.archive--category {
  .archive-grid {
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));

    ::v-deep {
      .item,
      .skeleton {
        grid-column: 1 / -1;

        @include mq(m) {
          grid-column: span 6;

          &:nth-child(5n + 3) {
            grid-column: 1 / -1;
          }
        }
      }
    }
  }
}

.archive--shop {
  .archive-grid {
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    row-gap: var(--row-gap-xs);

    ::v-deep {
      .item,
      .skeleton {
        grid-column: 1 / -1;

        @include mq(m) {
          grid-column: span 3;
          /* &:nth-child(10n + 1) { grid-column: span 3; }
          &:nth-child(10n + 2) { grid-column: span 3; }
          &:nth-child(10n + 3) { grid-column: span 3; }
          &:nth-child(10n + 4) { grid-column: span 3; } */
        }
      }
    }
  }
}

.archive-grid--only-text {
  @include mq($until: m) {
    row-gap: var(--row-gap-xs);
    background: var(--grey-xl);
  }
}

.archive {
  ::v-deep {
    .grid-m-3 {
      @include mq($from: s, $until: xxl) {
        .reading-time-label {
          display: none;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s $ease-custom;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

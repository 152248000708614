<template>
  <Spacer
    y="l"
    :x="{ default: 's', m: 0 }"
    :use-margin="true"
  >
    <Wrapper
      v-if="order"
      size="s"
    >
      <div class="t-center typo--m">
        <Spacer bottom="l">
          {{ order.status !== 'failed' ? $labels.thank_you : $labels.thank_you_error }}
        </Spacer>
      </div>
      <div class="block-theme--default">
        {{ $labels.order_infos }}
        <div>
          <Spacer bottom="m">
            {{ order.status !== 'failed' ? $labels.order_received : $labels.order_failed }}.
          </Spacer>
          <OrderHeader :order="order" />
        </div>
        <Spacer
          top="m"
          bottom="xs"
        >
          {{ $labels.order_details }}
        </Spacer>
        <div>
          <OrderDetails :order="order" />
        </div>
      </div>
    </Wrapper>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

import OrderHeader from '@/components/wc/order-header';
import OrderDetails from '@/components/wc/order-details';

export default {
  name: 'ThankYou',
  components: {
    OrderDetails,
    OrderHeader,
  },
  data() {
    return {
      order: null,
    };
  },
  computed: {
    ...mapGetters(['wcSettings', 'paymentGateways']),
    total() {
      return this.parseCurrency(Number(this.order.total));
    },
    paymentGateway() {
      if (this.order && this.order.payment_method) {
        if (this.paymentGateways[this.order.payment_method]) {
          return this.paymentGateways[this.order.payment_method].title;
        }
        return this.order.payment_method.charAt(0).toUpperCase() + this.order.payment_method.slice(1);
      }
      return null;
    },
  },
  async mounted() {
    this.$store.dispatch('clearCart');
    const { data } = await this.$store.dispatch('getOrder', {
      key: this.$route.query.key,
      order_id: this.$route.params.orderId,
    });
    this.order = data;
    this.$store.dispatch('loadCustomerData');
  },
  methods: {

  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('main',[_c('Grid',{staticClass:"product-section",attrs:{"tag":"section","column-gap":"0"}},[_c('Column',{attrs:{"size":{ default: 12, m: 8 }}},[(_vm.productGallery && _vm.productGallery.length > 0)?_c('Media',{ref:"product-gallery",staticClass:"product-gallery",attrs:{"data":{
          type: 'gallery',
          gallery: _vm.productGallery,
          object_fit: 'cover',
          ratio: _vm.$mq.isMobile,
          lazyload: false,
        }}}):_c('Media',{staticClass:"product-gallery",attrs:{"data":{
          type: 'image',
          image: _vm.post.gds_featured_image,
          object_fit: 'cover',
          ratio: _vm.$mq.isMobile,
          lazyload: false,
        }}})],1),_c('Column',{attrs:{"size":{ default: 12, m: 4 }}},[_c('Flex',{staticClass:"product-content",attrs:{"dir":{ m: 'column' }}},[_c('div',{staticClass:"product-body"},[_c('div',{staticClass:"text"},[_c('Spacer',{attrs:{"all":"s"}},[_c('Title',{attrs:{"data":{
                  value: _vm.isSubscription(_vm.post.slug) ? _vm.magazineSubscription.title : _vm.post.name,
                  size: _vm.$mq.isMobile ? 'l' : 'm',
                }}}),_c('Spacer',{attrs:{"top":{ default: 'xl', m: 'm' }}},[_c('Richtext',{attrs:{"data":{
                    value: _vm.isSubscription(_vm.post.slug)
                      ? _vm.magazineSubscription.text
                      : _vm.product.description
                        ? _vm.product.description
                        : _vm.post.description,
                    size: _vm.$mq.isMobile ? 'm' : 's',
                  }}})],1)],1)],1),(_vm.author || _vm.info)?_c('div',{staticClass:"meta"},[_c('Spacer',{attrs:{"all":"s"}},[(_vm.author && _vm.author !== '')?_c('Richtext',{attrs:{"data":{
                  value: _vm.author,
                }}}):_vm._e(),(_vm.info && _vm.info !== '')?_c('Richtext',{attrs:{"data":{
                  value: _vm.info,
                }}}):_vm._e()],1)],1):_vm._e(),(_vm.isSubscription(_vm.post.slug))?_c('div',{staticClass:"subscriptions"},[_c('Spacer',{attrs:{"all":"s"}},[_c('Grid',{attrs:{"grid":"1","row-gap":"s"}},_vm._l((_vm.magazineSubscription.products),function(s){return _c('Cta',{key:s.ID,attrs:{"data":{
                    title: s.post_title,
                    url: {
                      name: 'SingleProduct',
                      params: {
                        slug: s.post_name,
                      },
                      query: {
                        switch: true,
                      },
                    },
                  },"theme":s.ID === _vm.post.id ? 'button' : 'button-outline',"disabled":s.ID === _vm.post.id}})}),1)],1)],1):_vm._e(),(_vm.isSubscription(_vm.post.slug))?_c('div',{staticClass:"text"},[_c('Spacer',{attrs:{"all":"s"}},[_c('Richtext',{attrs:{"data":{
                  value: _vm.product.description ? _vm.product.description : _vm.post.description,
                  size: _vm.$mq.isMobile ? 'm' : 's',
                }}})],1)],1):_vm._e(),_c('div',{staticClass:"actions typo--label"},[_c('Spacer',{attrs:{"all":"s"}},[_c('Share',{attrs:{"data":{
                  permalink: _vm.post.link,
                  title: _vm.post.name,
                  media: _vm.post.gds_featured_image ? _vm.post.gds_featured_image.url : null,
                }}})],1)],1),(_vm.hasVariations)?_c('div',[_c('Spacer',{attrs:{"all":"s"}},_vm._l((_vm.attributes),function(attribute,index){return _c('div',{key:index},[_c('Flex',[_c('Select',{staticClass:"product__variation-selector",attrs:{"data":_vm.getAttribute(attribute),"model":attribute.model},on:{"update:model":function($event){return _vm.$set(attribute, "model", $event)}},model:{value:(attribute.model),callback:function ($$v) {_vm.$set(attribute, "model", $$v)},expression:"attribute.model"}},[_c('label',{attrs:{"slot":"label","for":"terms"},slot:"label"},[_vm._v(_vm._s(attribute.name))])]),(attribute.model)?_c('Icon',{attrs:{"name":"close","theme":"small","fn":() => _vm.resetAttribute(attribute)}}):_vm._e()],1)],1)}),0)],1):_vm._e()]),_c('Flex',{staticClass:"product-footer flex-auto"},[(_vm.isOos && _vm.post.acf.notification && _vm.post.acf.notification.active)?_c('div',{staticClass:"notification-box"},[_c('Transition',{attrs:{"name":"slide"}},[_c('Notification',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNotification),expression:"showNotification"}],attrs:{"text":_vm.post.acf.notification.text,"cta":_vm.$labels.notification_submit_cta,"product-id":_vm.product.id},on:{"close":_vm.closeNotification}})],1),_c('Cta',{staticClass:"wc-cta",attrs:{"data":{
                title: _vm.showNotification ? _vm.$labels.close : _vm.$labels.notification_cta,
                url: false,
                fn: () => {},
              },"theme":"button"},nativeOn:{"click":function($event){_vm.showNotification = !_vm.showNotification}}})],1):_vm._e(),_c('div',{staticClass:"price t-center"},[(_vm.product)?_c('Price',{attrs:{"product":_vm.product}}):_vm._e()],1),(_vm.product.type !== 'external')?[_c('Cta',{staticClass:"wc-cta",attrs:{"data":{
                title: _vm.labelCta,
                url: false,
                fn: _vm.addToCart,
              },"theme":"button","disabled":!_vm.addableToCart}})]:[_c('Cta',{staticClass:"wc-cta",attrs:{"data":{
                title: _vm.product.button_text || _vm.$labels.external_url,
                url: _vm.product.external_url,
                target: '_blank',
              },"theme":"button"}})]],2)],1)],1)],1),_c('Archive',{attrs:{"data":{
      title: _vm.post.upsell_ids && _vm.post.upsell_ids.length > 0 ? 'Related items' : 'From the shop',
      archive_type: 'archive',
      archive: 'product',
      product: _vm.post.upsell_ids && _vm.post.upsell_ids.length > 0 ? _vm.post.upsell_ids : undefined,
      max_posts: 4,
      exclude: _vm.isSubscription(_vm.post.slug)
        ? [_vm.post.id, ..._vm.magazineSubscription.products.map((p) => p.ID)]
        : [_vm.post.id],
      layout: [
        {
          media_query: 'default',
          layout_size: '6',
          layout_type: 'grid',
        },
        {
          media_query: 'm',
          layout_size: '3',
          layout_type: 'grid',
        },
      ],
    }}}),_c('OverlayFooter',{attrs:{"data":_vm.footer.blocks}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
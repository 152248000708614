<template>
  <div
    class="video-player"
  >
    <div
      v-if="data.embed && data.url"
      ref="player"
      class="plyr"
      :data-plyr-provider="embed.provider"
      :data-plyr-embed-id="embed.id"
    />

    <video
      v-if="!data.embed && data.src"
      ref="player"
      class="plyr"
      :src="data.src"
      :data-poster="data.poster ? data.poster.sizes.l : null"
      playsinline
    />
    <div
      v-if="data.caption"
      v-html="data.caption"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import { parseVideoURL } from '@/assets/js/utils';

export default {
  name: 'VideoPlayer',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plyr: null,
      playing: false,
      loading: false,
    };
  },
  computed: {
    embed() {
      return this.data.embed && this.data.url ? parseVideoURL(this.data.url) : null;
    },
  },
  mounted() {
    if (this.data.url || this.data.src) {
      this.plyr = new Plyr(this.$refs.player, {
        autoplay: false,
        controls: ['play', 'progress', 'mute'],
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  width: 100%;
}
</style>

<style lang="scss">
.plyr {
  --plyr-color-main: $color-white;
  --plyr-range-track-height: 2px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-thumb-shadow: none;

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type="range"] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
}
</style>

<template>
  <div
    v-show="show"
    :class="['adv-wrapper', `adv-${templateComputed}`]"
  >
    <Spacer
      v-if="templateComputed.includes('post')"
      bottom="xs"
    >
      <Label :data="{ value: 'Advertising', size: 'label' }" />
    </Spacer>
    <div :class="['adv', { 'is-mobile': advMobile }]">
      <div
        v-if="ready"
        class="content"
        v-html="content[0]"
      />
      <div
        v-if="ready && (templateComputed === 'post-small' || templateComputed === 'page-section-medium')
        "
        class="content"
        v-html="content[1]"
      />
    </div>
  </div>
</template>

<script>
import Label from '@/components/typo/label';

export default {
  name: 'Adv',
  components: {
    Label,
  },
  props: {
    template: {
      type: String,
      default: 'page-section',
    },
    data: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      ready: false,
      show: false,
      content: [],
      advMobile: false,
    };
  },
  computed: {
    templateComputed() {
      return this.data && this.data.adv ? this.data.adv : this.template;
    },
  },
  watch: {
    show(val) {
      if (val && this.templateComputed === 'preheader') {
        document.body.classList.add('is-adv-preheader');
      } else if (!val && this.templateComputed === 'preheader') {
        document.body.classList.remove('is-adv-preheader');
      }
    },
    $route(to) {
      if (to.name !== 'Index' && this.templateComputed === 'preheader') {
        document.body.classList.remove('is-adv-preheader');
      }
      if (to.name === 'Index' && this.templateComputed === 'preheader') {
        document.body.classList.add('is-adv-preheader');
      }
    },
  },
  mounted() {
    const request = {
      fn: 'adv',
      params: {
        template: this.templateComputed,
      },
    };
    this.$store
      .dispatch('sendRequest', request)
      .then((response) => {
        if (response.data.html && response.data.html.length > 0) {
          this.content = response.data.html;
          this.advMobile = Array.isArray(response.data.mobile);
          this.ready = true;
          this.show = !response.data.html[0].startsWith('<!--');

          if (!this.show && this.templateComputed === 'popup') {
            this.$bus.$emit('removeContentModal', this.templateComputed);
          }

          if (this.show) {
            this.$nextTick(() => {
              this.$el.querySelector('a.gofollow').addEventListener('click', this.setTrack);
            });
          }
        } else {
          this.show = false;

          if (!this.show && this.templateComputed === 'popup') {
            this.$bus.$emit('removeContentModal', this.templateComputed);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  beforeDestroy() {
    if (this.templateComputed === 'preheader') document.body.classList.remove('is-adv-preheader');
    if (this.$el.querySelector('a.gofollow')) {
      this.$el.querySelector('a.gofollow').removeEventListener('click', this.setTrack);
    }
  },
  methods: {
    setTrack(e) {
      const request = {
        action: 'adrotate_click',
        track: e.target.dataset.track,
      };
      this.$store.dispatch('sendRequest', request);
    },
  },
};
</script>

<style lang="scss" scoped>
.adv {
  position: relative;

  &-wrapper {
    position: relative;

    &[class*="adv-post"] {
      margin-top: var(--spacer-xl);
      margin-bottom: var(--spacer-xl);
      border-top: 1px solid var(--grey-l);
      border-bottom: 1px solid var(--grey-l);
      padding: var(--spacer-xs) 0 var(--spacer-s);
    }
  }

  .content,
  .content ::v-deep * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;

    &:not(a) {
      pointer-events: none;
    }
  }

  .content,
  .content ::v-deep a {
    pointer-events: auto;
  }

  &-preheader {
    // position: absolute;
    // z-index: 180;
    width: 100%;

    // transform: translate3d(0, calc(var(--bar-height) * -1), 0);
    // margin-top: calc((16.66666666667vw + var(--bar-height)) * -1);
    .adv {
      @include aspect-ratio(6, 1);

      &.is-mobile {
        @include mq($until: m) {
          @include aspect-ratio(3, 1);
        }
      }
    }
  }

  &-page-section {
    .adv {
      @include aspect-ratio(4, 1);

      &.is-mobile {
        @include mq($until: m) {
          @include aspect-ratio(4, 5);
        }
      }
    }
  }

  &-page-section-medium {
    .adv {
      @include aspect-ratio(8, 1);

      &.is-mobile {
        @include mq($until: m) {
          @include aspect-ratio(8, 5);
        }
      }

      .content {
        width: 50%;
      }

      .content:last-child {
        left: 50%;
      }
    }
  }

  &-popup {
    width: 100%;

    .adv {
      @include aspect-ratio(3, 2);
    }

    @include mq($until: m) {
      width: 100%;

      .adv {
        @include aspect-ratio(4, 5);
      }
    }
  }

  &-post-large {
    .adv {
      @include aspect-ratio(4, 1);

      &.is-mobile {
        @include mq($until: m) {
          @include aspect-ratio(8, 5);
        }
      }
    }

    margin-left: var(--spacer-s);
    margin-right: var(--spacer-s);

    @include mq(m) {
      margin-left: 0;
      margin-right: 0;

      .label {
        margin-left: var(--spacer-s);
      }
    }
  }

  &-post-medium {
    .adv {
      @include aspect-ratio(4, 1);

      &.is-mobile {
        @include mq($until: m) {
          @include aspect-ratio(4, 5);
        }
      }
    }

    margin-left: var(--spacer-s);
    margin-right: var(--spacer-s);

    @include mq(m) {
      width: 50%;
      margin-right: var(--spacer-s);
    }
  }

  &-post-small {
    margin-left: var(--spacer-s);
    margin-right: var(--spacer-s);

    @include mq(m) {
      width: 50%;
      margin-right: 0;
    }

    .adv {
      @include aspect-ratio(4, 1);

      &.is-mobile {
        @include mq($until: m) {
          @include aspect-ratio(4, 5);
        }
      }

      .content {
        width: 50%;
      }

      .content:last-child {
        left: 50%;
      }
    }
  }
}
</style>

<style lang="scss">
/* #app {
  position: relative;
  transition: top 0.4s $ease-custom;
}
.is-adv-preheader {
  #app {
    top: 16.66666666667vw;
  }
} */
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['thumb', 'thumb--product', `thumb--${_vm.parsedThumb}`]},[_c('router-link',{staticClass:"link",attrs:{"to":_vm.data.link ? _vm.$relativeUrl(_vm.data.link) : _vm.$relativeUrl(_vm.data.permalink)}}),_c('div',{staticClass:"media"},[((_vm.isSubscription(_vm.data.slug) && _vm.magazineSubscription.thumb) || _vm.data.gds_featured_image)?_c('Media',{attrs:{"data":{
        type: 'image',
        image: _vm.isSubscription(_vm.data.slug) ? _vm.magazineSubscription.thumb : _vm.data.gds_featured_image,
        ratio: false,
        lazyload: true,
        double_size: true,
        object_fit: 'cover',
      }}}):_vm._e()],1),_c('div',{staticClass:"abstract"},[_c('Spacer',{staticClass:"abstract-wrapper",attrs:{"top":"xs","bottom":"xxs","x":"s"}},[_c('Flex',{staticClass:"abstract-flex",attrs:{"justify":"space-between"}},[_c('Title',{staticClass:"title",attrs:{"data":{
            value: _vm.isSubscription(_vm.data.slug)
              ? _vm.magazineSubscription.title
              : _vm.data.taxonomy || _vm.data.name
                ? _vm.data.name
                : _vm.data.title.rendered,
            size: _vm.$mq.isMobile ? 'm' : 's',
          }}}),(!_vm.isSubscription(_vm.data.slug))?_c('Price',{class:[_vm.$mq.isMobile ? 'typo--m' : 'typo--s'],attrs:{"product":_vm.data}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"cta-wrapper"},[(_vm.isSubscription(_vm.data.slug))?_c('Cta',{attrs:{"theme":"button-light","data":{
        title: _vm.$labels.subscribe,
        url: _vm.data.link || _vm.data.permalink,
      }}}):(!_vm.hasVariations)?_c('Cta',{attrs:{"theme":"button-light","data":{
        title: _vm.labelCta,
        url: false,
        fn: _vm.addToCart,
      },"disabled":!_vm.addableToCart}}):_c('Cta',{attrs:{"theme":"button-light","data":{
        title: _vm.$labels.select_option,
        url: _vm.data.link || _vm.data.permalink,
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Flex
    align="center"
    tag="nav"
    @mouseleave.native="fn ? fn(null) : false"
  >
    <Spacer
      v-for="item in navigation"
      :key="item.id"
      :class="[...item.css]"
      x="s"
    >
      <div
        v-if="item.css.includes('no-link')"
        :class="['link', item.id === current ? 'link--hover' : false]"
        @mouseenter="fn ? fn(item.id) : false"
        v-html="item.content"
      />
      <a
        v-else-if="item.target"
        :href="item.url"
        :target="item.target"
        :class="['link', item.id === current ? 'link--hover' : false]"
        @mouseenter="fn ? fn(item.id) : false"
        v-html="item.content"
      />
      <router-link
        v-else
        :to="$relativeUrl(item.url)"
        :class="['link', item.id === current ? 'link--hover' : false]"
        @mouseenter.native="fn ? fn(item.id) : false"
        v-html="item.content"
      />
    </Spacer>

    <Flex
      v-if="submenu && !$mq.isMobile"
      class="subheader"
    >
      <Flex
        :key="current"
        class="categories"
        align="center"
      >
        <Spacer
          v-for="item in submenu"
          :key="item.id"
          x="s"
          top="1"
        >
          <router-link
            :to="$relativeUrl(item.url)"
            v-html="item.content"
          />
        </Spacer>
      </Flex>
    </Flex>
  </Flex>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  props: {
    fn: {
      type: Function,
      default: () => {},
    },
    current: {
      type: Number,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
    submenu() {
      return this.current ? this.navigation.find((item) => item.id === this.current).children : null;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  height: var(--bar-height);

  .subheader {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: var(--bar-height);
    background: var(--grey-xl);

    .categories {
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }

  a {
    display: block;
  }

  .router-link-active {
    color: var(--grey);
  }
}
</style>

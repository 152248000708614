var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Grid',{attrs:{"col":_vm.cols}},_vm._l((_vm.items),function(item){return _c(_vm.thumb(),{key:item,tag:"component",class:['skeleton', `skeleton--${_vm.thumbComponent}`],attrs:{"data":{
      link: '#!',
      title: {
        rendered: ''
      },
      acf: {
        template: 'default'
      }
    },"thumb":_vm.thumbTheme}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }
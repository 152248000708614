<template>
  <transition name="fade">
    <div
      v-if="loaders"
      class="app-loader"
    >
      <inline-svg
        :src="require(`@/assets/svg/loading.svg`)"
      />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  computed: {
    ...mapGetters(['authLoading', 'cartLoading', 'ajaxLoading']),
    loaders() {
      return this.authLoading || this.cartLoading || this.ajaxLoading;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss">
.app-loader {
  position: fixed;
  bottom: var(--spacer-xxs);
  right: var(--spacer-xxs);
  z-index: 100;

  svg {
    width: 26px;
    height: 26px;
    animation: rotating 0.4s linear infinite;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>

<template>
  <router-link
    :to="$relativeUrl(data.link)"
    :class="['thumb', `thumb--${parsedThumb}`]"
  >
    <div
      v-if="parsedThumb !== 'only-text'"
      class="media"
    >
      <Figure
        v-if="data.gds_featured_image"
        :data="Object.assign(data.gds_featured_image, { lazyload: true })"
      />
      <Tags
        v-if="
          (data.gds_taxonomies && data.gds_taxonomies.tags && data.acf.template !== 'past-issue') ||
            $route.name === 'Index'
        "
        class="tags"
        :data="data.gds_taxonomies ? data.gds_taxonomies.tags : []"
        :post-type="data.type"
        minify
      />
    </div>
    <div class="abstract">
      <Spacer
        top="xs"
        bottom="xxs"
        x="s"
        class="abstract-wrapper"
      >
        <Flex
          dir="column"
          class="wrapper"
        >
          <Spacer
            class="title title-wrapper"
            bottom="s"
          >
            <Spacer
              v-if="
                parsedThumb === 'only-text' &&
                  ((data.gds_taxonomies && data.gds_taxonomies.tags) || $route.name === 'Index')
              "
              y="s"
            >
              <Tags
                v-if="
                  (data.gds_taxonomies &&
                    data.gds_taxonomies.tags &&
                    data.acf.template !== 'past-issue') ||
                    $route.name === 'Index'
                "
                class="tags"
                :data="data.gds_taxonomies ? data.gds_taxonomies.tags : []"
                :post-type="data.type"
                minify
              />
            </Spacer>
            <Title
              :data="{
                value: data.title.rendered,
                size:
                  parsedThumb !== 'horizontal-xl' && parsedThumb !== 'only-text' && !$mq.isMobile
                    ? 'm'
                    : 'l',
              }"
              class="title"
            />
            <Title
              v-if="data.gds_taxonomies && data.gds_taxonomies.tax_author"
              :data="{ value: `by ${data.gds_taxonomies.tax_author[0].name}`, size: 'm' }"
              class="title"
            />
          </Spacer>
          <Flex
            :class="['meta']"
            justify="space-between"
          >
            <Column :size="parsedThumb !== 'only-text' ? 'auto' : '12'">
              <time class="typo--label">{{ date }}</time>
            </Column>
            <Column
              v-if="data.acf.template !== 'past-issue' && data.reading_time"
              :size="parsedThumb !== 'only-text' ? 'auto' : '12'"
            >
              <div class="typo--label t-center reading-time">
                <span class="reading-time-label">Reading Time </span>{{ data.reading_time }}′
              </div>
            </Column>
            <Column
              v-if="category"
              :size="parsedThumb !== 'only-text' ? 'auto' : '12'"
            >
              <div :class="['typo--label', parsedThumb !== 'only-text' ? 't-right' : 't-center']">
                {{ category.name }}
              </div>
            </Column>
          </Flex>
        </Flex>
      </Spacer>
    </div>
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Tags from '@/components/typo/tags';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Title,
    Tags,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    thumb: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    parsedThumb() {
      return this.thumb ? this.thumb : this.data.thumb;
    },
    date() {
      return this.data.acf.template !== 'past-issue'
        ? this.data.date_format
        : this.data.acf.release;
    },
    category() {
      return this.data.gds_taxonomies
        ? this.data.gds_taxonomies.categories && this.data.type === 'post'
          ? this.data.gds_taxonomies.categories[0]
          : this.data.gds_taxonomies['category-publishing'] && this.data.type === 'publishing'
            ? this.data.gds_taxonomies['category-publishing'][0]
            : false
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  position: relative;
  display: block;

  @include mq($and: $hover) {
    &:hover {
      .media {
        & > * {
          //  mix-blend-mode: difference;
        }
      }

      .abstract {
        background: var(--grey-xxd);
        color: var(--white);
      }
    }
  }

  /* .abstract {
    background: var(--white);
    position: sticky;
    bottom: 0;
    z-index: 5;
  } */

  .media {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tags {
      position: absolute;
      top: auto;
      width: auto;
      height: auto;
      bottom: calc(var(--spacer-s) - 4px);
      left: var(--spacer-s);
      z-index: 2;
      color: var(--white);
      mix-blend-mode: difference;
    }
  }

  &--horizontal,
  &--horizontal-xl {
    .media {
      @include aspect-ratio(4, 3);
    }
  }

  &--vertical {
    .media {
      @include aspect-ratio(3, 4);
    }
  }

  &--horizontal-xl {
    .title-wrapper {
      @include mq(m) {
        width: 50%;
      }
    }
  }

  .abstract-wrapper {
    position: relative;
    min-height: var(--block-height);
    display: flex;

    .title {
      flex: 1 0 0;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .meta {
      height: 22px;
      padding-top: 1px;

      ::v-deep .col-auto {
        flex: 1 0 0;
        white-space: nowrap;
      }
    }

    .archive-overflow .archive-grid & {
      &::after {
        content: "";
        position: absolute;
        right: calc(var(--row-gap-xs) * -1);
        top: 0;
        width: var(--row-gap-xs);
        height: 100%;
        background: var(--grey-xl);
      }
    }
  }

  &--only-text {
    text-align: center;

    .abstract {
      @include aspect-ratio(3.25, 4);
    }

    .abstract-wrapper {
      height: 100%;

      .meta {
        height: 66px;
        justify-content: center;
      }

      .tags {
        justify-content: center;
        color: var(--grey-xxd);

        @include mq(m) {
          color: var(--grey-xl);
        }
      }

      .title {
        letter-spacing: 0.05em;
        text-transform: uppercase;
      }
    }

    @include mq($and: $hover) {
      &:hover {
        .abstract-wrapper {
          .tags {
            color: var(--white);
          }
        }
      }
    }
  }
}
</style>

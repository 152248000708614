import orderBy from 'lodash.orderby';

export default {
  menu: (state, getters) => (name) => state.menus[getters.lang][name],
  request:
    (state) => ({ type, params }) => {
      let res = null;
      const slugs = [];
      if (params.slug && params.slug.length > 0) {
        params.slug.forEach((slug) => {
          if (state[type][params.lang][slug]) slugs.push(slug);
        });
        return slugs.length > 0 ? slugs : null;
      }
      const request = state[type].requests.find((req) => {
        if (Object.keys(req.params).length === Object.keys(params).length) {
          res = Object.keys(req.params).every(
            (key) => JSON.stringify(req.params[key]) === JSON.stringify(params[key]),
          );
        }
        return res;
      });

      if (request) return request.data;
      return res;
    },
  singleBySlug:
    (state) => ({
      type, slug, lang, category,
    }) => {
      if (category) {
        return state.categories[category][lang][slug];
      }
      return state[type][lang][slug];
    },
  options: (state) => state.options,
  categories: (state) => (taxonomy) => state.categories[taxonomy].default,
  tags: (state) => orderBy(
    Object.keys(state.tags.default).map((k) => state.tags.default[k]),
    'count',
    'desc',
  ),
  ajaxLoading: (state) => state.ajaxLoading,
  advs: (state) => state.adv,
  postTypesLabel: (state) => (value) => state.postTypes[value].labels.singular_name || state.postTypes[value].label,
  magazineSubscription: (state) => state.options?.magazine_subscription,
  subscriptionIds: (state, getters) => getters.magazineSubscription?.products?.map((p) => p.ID),
  isSubscription: (state, getters) => (value) => !!getters.magazineSubscription?.products?.find((p) => p.post_name === value),
};

<template>
  <Spacer>
    <Grid row-gap="m">
      <Column
        size="6"
        class="wc-order-line"
      >
        <div class="typo--xxs t-uppercase">
          {{ $labels.order }}
        </div>
        <div>
          #{{ order.id }}
        </div>
      </Column>
      <Column
        size="6"
        class="wc-order-line"
      >
        <div class="typo--xxs t-uppercase">
          {{ $labels.date }}
        </div>
        <div>
          {{ parseDate(order.created_date) }}
        </div>
      </Column>
      <Column
        size="6"
        class="wc-order-line"
      >
        <div class="typo--xxs t-uppercase">
          {{ $labels.total }}
        </div>
        <div>
          <span v-html="total" />
        </div>
      </Column>
      <Column
        v-if="paymentGateway"
        size="6"
        class="wc-order-line"
      >
        <div class="typo--xxs t-uppercase">
          {{ $labels.payment_method }}
        </div>
        <div>
          {{ paymentGateway }}
        </div>
      </Column>
      <!-- <Column
      v-if="order.customer_note"
      size="12"
      class="wc-order-line"
    >
      <div class="typo--xxs t-uppercase">
        {{ $labels.additional_information }}
      </div>
      {{ order.customer_note }}
    </Column> -->
      <div />
    </Grid>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseDate, parseCurrency, getOrderTotal } from '@/assets/js/utils-wc';

export default {
  name: 'OrderDetails',
  props: {
    order: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['wcSettings', 'paymentGateways']),
    total() {
      return getOrderTotal(this.order, this.wcSettings);
    },
    paymentGateway() {
      if (this.order && this.order.payment_method) {
        if (this.paymentGateways[this.order.payment_method]) {
          return this.paymentGateways[this.order.payment_method].title;
        }
        return this.order.payment_method.charAt(0).toUpperCase() + this.order.payment_method.slice(1);
      }
      return null;
    },
  },
  mounted() {

  },
  methods: {
    parseDate(date) {
      if (date) {
        return parseDate(date, this.wcSettings);
      }
      return null;
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings, this.order.currency);
    },
  },
};
</script>

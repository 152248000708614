import axios from 'axios';

// eslint-disable-next-line no-undef
let { url } = __VUE_WORDPRESS__.routing;
url = url.replace(':8888', ':3000');

const urlWp = `${url}/wp-json/`;

const ajax = axios.create(
  {
    baseURL: urlWp,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
);
export default ajax;

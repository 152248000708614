var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{attrs:{"data-template":_vm.post.acf.page_template}},[(_vm.post.children.length)?[_c('Grid',{staticClass:"children",attrs:{"col":"3"}},[_c('Column',{staticClass:"children-menu",attrs:{"size":{ default: 4, s: 1 }}},[_c('Flex',{attrs:{"dir":"column"}},_vm._l((_vm.post.children),function(c){return _c('Spacer',{key:c.id,class:[
              'child',
              'typo--label-s',
              { active: _vm.$route.hash === `#${c.slug}` },
            ],attrs:{"x":"s"}},[_c('router-link',{attrs:{"to":{ hash: c.slug }},domProps:{"innerHTML":_vm._s(c.title.rendered)}})],1)}),1)],1),_c('Column',{attrs:{"size":{ default: 4, s: 3 }}},[_c('Spacer',{attrs:{"all":"s"}},_vm._l((_vm.currentChild.acf.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:`section--${parentIndex}`,attrs:{"layout":_vm.layout(section)}},_vm._l((section.blocks),function(block,index){return _c('Spacer',{key:index,attrs:{"x":_vm.currentChild.acf.page_template === 'static'
                  ? 's'
                  : block.block_spacer}},[_c(_vm.components.get(block.acf_fc_layout),{tag:"component",class:[
                  'block',
                  `block--${block.acf_fc_layout}`,
                  block.block_classes && block.block_classes !== ''
                    ? block.block_classes.split(' ')
                    : null,
                ],attrs:{"data":block}})],1)}),1)}),1)],1)],1)]:[(
        (_vm.slug === 'index' || _vm.$route.meta.slug === 'index') && _vm.advs.preheader
      )?_c('Adv',{attrs:{"template":"preheader"}}):_vm._e(),_vm._l((_vm.post.acf.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:`section--${parentIndex}`,attrs:{"layout":_vm.layout(section)}},_vm._l((section.blocks),function(block,index){return _c('Spacer',{key:index,attrs:{"x":_vm.post.acf.page_template === 'static' ? 's' : block.block_spacer}},[_c(_vm.components.get(block.acf_fc_layout),{tag:"component",class:[
            'block',
            `block--${block.acf_fc_layout}`,
            block.block_classes && block.block_classes !== ''
              ? block.block_classes.split(' ')
              : null,
          ],attrs:{"data":block}})],1)}),1)})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
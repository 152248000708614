<template>
  <Spacer
    class="notification"
    x="s"
    y="xs"
  >
    <Grid
      col="12"
      row-gap="xl"
      :class="['form', avoid ? 'form-avoid' : false]"
    >
      <Flex justify="space-between">
        <Content
          :data="{
            rich_text: text,
          }"
        />
      </Flex>

      <form
        v-show="!success"
        @submit.prevent="submit"
      >
        <Flex align="center">
          <div class="field">
            <input
              v-model="email"
              type="email"
              name="email"
              placeholder="Your e-mail"
              required
              :disabled="customer && customer.email"
            >
          </div>
          <Flex
            class="cta"
            tag="button"
            align="center"
          >
            <Title
              v-if="!$mq.isMobile"
              :data="{
                value: cta,
                size: 's',
              }"
            />
            <Icon
              name="arrow-right"
              size="xs"
            />
          </Flex>
        </Flex>
      </form>

      <Content
        v-if="success"
        :data="{ rich_text: $labels.notification_success }"
      />
    </Grid>
  </Spacer>
</template>

<script>
import Content from '@/components/blocks/content';
import Title from '@/components/typo/title';
import Icon from '@/components/ui/icon';
import { mapGetters } from 'vuex';

export default {
  name: 'Newsletter',
  components: {
    Content,
    Title,
    Icon,
  },
  computed: {
    ...mapGetters(['options', 'customer']),
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    cta: {
      type: String,
      default: 'Submit',
    },
  },
  data() {
    return {
      email: '',
      success: false,
      avoid: false,
    };
  },
  created() {
    this.email = this.customer ? this.customer.email : '';
  },
  methods: {
    submit() {
      if (this.avoid) return;

      this.avoid = true;
      const request = {
        fn: 'notify-me',
        wc_bis_product_live_form: true,
        wc_bis_product_id: this.productId,
        wc_bis_email: this.email,
        params: {},
      };

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(this.options.extra.recaptcha_public_key.value, { action: 'submit' })
          .then((token) => {
            request.params.token = token;
            this.$store
              .dispatch('sendRequest', request)
              .then((response) => {
                if (response.data && response.data.error && response.data.error.length) {
                  this.success = false;
                  this.$store.commit('SET_SNACKBAR', {
                    active: true,
                    content: response.data.error[response.data.error.length - 1].notice,
                  });
                } else {
                  this.success = true;
                }
                this.avoid = false;
              })
              .catch(() => {
                this.success = false;
                this.avoid = false;
                this.$store.commit('SET_SNACKBAR', {
                  active: true,
                  content: this.$labels.error_generic,
                });
              });
          })
          .catch(() => {
            this.avoid = false;
            this.success = false;
            this.$store.commit('SET_SNACKBAR', {
              active: true,
              content: this.$labels.error_generic,
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  background: var(--green);
  display: grid;
}
.form {
  height: 100%;

  &-avoid {
    cursor: progress;

    form {
      pointer-events: none;
    }
  }

  form {
    border-bottom: 1px solid currentColor;

    .field {
      flex: 1 0 0;

      input {
        padding-top: 0;
      }
    }
  }
}
</style>

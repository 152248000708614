var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.data.cta.fn ? 'div' : 'router-link',{tag:"component",class:['block', _vm.data.theme ? `block--${_vm.data.theme}` : false],style:({
    '--bg': _vm.data.background,
    '--color': _vm.data.color,
  }),attrs:{"to":_vm.data.cta.url ? _vm.$relativeUrl(_vm.data.cta.url) : false},on:{"click":function($event){_vm.data.cta.fn ? _vm.data.cta.fn() : false}}},[_c('Spacer',{attrs:{"x":"s","y":"xs"}},[_c('Grid',{attrs:{"col":{ default: 'auto', m: 6 }}},[(_vm.data.title)?_c('Title',{attrs:{"data":{
          value: _vm.data.title,
        }}}):_vm._e(),_c('Flex',{staticClass:"cta",attrs:{"align":"center"}},[(!_vm.$mq.isMobile || _vm.data.theme === 'go-back' || _vm.data.theme === 'go-top')?_c('Title',{attrs:{"data":{
            value: _vm.data.cta.title,
          }}}):_vm._e(),_c('Icon',{attrs:{"name":_vm.data.icon ? _vm.data.icon : 'arrow-right',"size":"l"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
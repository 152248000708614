var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Flex',{class:['search-bar'],attrs:{"align":"center"}},[_c('Spacer',{class:['search-input'],attrs:{"x":"s"}},[_c('Flex',{class:['search-input-wrapper'],attrs:{"justify":"space-between","align":"center"}},[_c('div',{staticClass:"bar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.s),expression:"s"}],ref:"input",attrs:{"id":"s","name":"s","placeholder":"Search","spellcheck":"false"},domProps:{"value":(_vm.s)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.s=$event.target.value}}})])])],1),_c('div',[_c('Flex',{staticClass:"type",attrs:{"column-gap":"0"}},[_c('Cta',{attrs:{"data":{
            title: 'Text',
            fn: () => _vm.setRequestType('post'),
            size: 'label-xs',
          },"theme":_vm.type === 'post' ? 'button' : 'button-white'}}),_c('Cta',{attrs:{"data":{
            title: 'Images',
            fn: () => _vm.setRequestType('media'),
            size: 'label-xs',
          },"theme":_vm.type === 'media' ? 'button' : 'button-white'}})],1)],1)],1),_c('div',{staticClass:"search-placeholder"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
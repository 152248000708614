var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('Archive',{attrs:{"data":{
      archive_type: 'archive',
      archive: _vm.request.postType,
      posts_per_page: _vm.request.parent === 'shop' ? 20 : 25,
      infinite_scroll: true,
      orderby: 'date',
      archive_category: {
        taxonomy: _vm.request.category,
        term_id: _vm.request.categoryId,
      },
      layout: [
        {
          media_query: 'default',
          layout_size: '6',
          layout_type: 'grid'
        },
        {
          media_query: 'm',
          layout_size: '3',
          layout_type: 'grid'
        }
      ],
      thumb: 'horizontal',
      template: _vm.request.postType === 'product' ? 'shop' : _vm.categorySlug === 'past-issues' ? 'past-issues' : 'category'
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
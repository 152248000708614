var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Grid',{attrs:{"row-gap":"m"}},[_c('Column',{attrs:{"size":{
      default: 12,
      s: 6}}},[_c('Wrapper',{attrs:{"size":"s","margin":false}},[_c('Spacer',{staticClass:"typo--m",attrs:{"bottom":{ default: 'xs', m: 's' }}},[_vm._v(" "+_vm._s(_vm.$labels.billing_address)+" ")]),_c('div',{staticClass:"typo--s"},[_vm._v(" "+_vm._s(_vm.data.billing.first_name)+" "+_vm._s(_vm.data.billing.last_name)+" "),_c('br'),(_vm.data.billing.company)?_c('span',[_vm._v(" "+_vm._s(_vm.data.billing.company)+" "),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.billing.address_1)+" "),_c('br'),(_vm.data.billing.address_2)?_c('span',[_vm._v(" "+_vm._s(_vm.data.billing.address_2)+" "),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.billing.postcode)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.data.billing.city)+", "+_vm._s(_vm.geoData.states ? _vm.geoData.states[_vm.data.billing.country][_vm.data.billing.state] : _vm.data.billing.state)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.geoData.countries ? _vm.geoData.countries[_vm.data.billing.country] : _vm.data.billing.country)+" "),_c('br'),_c('a',{attrs:{"href":'mailto:'+_vm.data.billing.email}},[_vm._v(_vm._s(_vm.data.billing.email))]),_c('br'),_c('a',{attrs:{"href":'tel:'+_vm.data.billing.phone}},[_vm._v(_vm._s(_vm.data.billing.phone))]),_c('br')])],1)],1),_c('Column',{attrs:{"size":{
      default: 12,
      s: 6
    }}},[_c('Wrapper',{attrs:{"size":"s","margin":false}},[_c('Spacer',{staticClass:"typo--m",attrs:{"bottom":{ default: 'xs', m: 's' }}},[_vm._v(" "+_vm._s(_vm.$labels.shipping_address)+" ")]),_c('div',{staticClass:"typo--s"},[_vm._v(" "+_vm._s(_vm.data.shipping.first_name)+" "+_vm._s(_vm.data.shipping.last_name)+" "),_c('br'),(_vm.data.shipping.company)?_c('span',[_vm._v(" "+_vm._s(_vm.data.shipping.company)+" "),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.shipping.address_1)+" "),_c('br'),(_vm.data.shipping.address_2)?_c('span',[_vm._v(" "+_vm._s(_vm.data.shipping.address_2)+" "),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.shipping.postcode)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.data.shipping.city)+", "+_vm._s(_vm.geoData.states ? _vm.geoData.states[_vm.data.shipping.country][_vm.data.shipping.state] : _vm.data.shipping.state)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.geoData.countries ? _vm.geoData.countries[_vm.data.shipping.country] : _vm.data.shipping.country)+" "),_c('br')]),_c('Flex',[(_vm.data.subscription_data && _vm.data.status === 'active')?_c('Spacer',{attrs:{"top":"m"}},[_c('Cta',{attrs:{"theme":"button","data":{
              title: _vm.$labels.edit,
              url: { name: 'EditAddress', params: {
                addressType: 'shipping',
              }, query: {
                subscription: _vm.data.id,
                key: _vm.data.key
              }},
            }}})],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AccordionWrapper',{staticClass:"block-theme--default"},[_c('AccordionItem',{attrs:{"data-subscription":_vm.subscription.id}},[_c('template',{slot:"accordion-trigger"},[_c('Grid',{staticClass:"order-grid"},[_c('Column',{class:{ 'typo--m': _vm.$mq.isMobileXS },attrs:{"size":{
            default: 6,
            xs: 3,
          }}},[_vm._v(" #"+_vm._s(_vm.subscription.id)+" ")]),_c('Column',{attrs:{"size":{
            default: 9,
            s: 3,
          }}},[_c('div',{staticClass:"t-ellipsis"},[_vm._v(" "+_vm._s(_vm.subscriptionStatus)+" ")])]),(!_vm.$mq.isMobileS)?_c('Column',{attrs:{"size":"3"}},[_c('div',{staticClass:"t-ellipsis",domProps:{"innerHTML":_vm._s(_vm.nextPayment)}})]):_vm._e(),(!_vm.$mq.isMobileXS)?_c('Column',{attrs:{"size":"3"}},[_c('div',{staticClass:"t-ellipsis",domProps:{"innerHTML":_vm._s(_vm.total)}})]):_vm._e()],1)],1),_c('template',{slot:"accordion-content"},[_c('SubscriptionDetails',{attrs:{"subscription":_vm.subscription}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Spacer
    class="block"
    x="s"
    y="xs"
  >
    <Flex
      dir="column"
      :class="['form', avoid ? 'form-avoid' : false]"
      justify="space-between"
    >
      <Content :data="{ title: !success ? data.title : $labels.success_newsletter, rich_text: data.rich_text }" />

      <form
        v-show="!success"
        @submit.prevent="submit"
      >
        <Flex align="center">
          <div class="field">
            <input
              v-model="email"
              type="email"
              name="email"
              placeholder="Your e-mail"
              required
            >
          </div>
          <Flex
            class="cta"
            tag="button"
            align="center"
          >
            <Title
              v-if="!$mq.isMobile"
              :data="{
                value: 'Sign up',
              }"
            />
            <Icon
              name="arrow-right"
              size="l"
            />
          </Flex>
        </Flex>
      </form>
    </Flex>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import Content from '@/components/blocks/content';
import Title from '@/components/typo/title';
import Icon from '@/components/ui/icon';

export default {
  name: 'Newsletter',
  components: {
    Content,
    Title,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      success: false,
      avoid: false,
    };
  },
  computed: {
    ...mapGetters(['options']),
  },
  methods: {
    submit() {
      if (this.avoid) return;

      this.avoid = true;
      const request = {
        fn: 'mailchimp',
        params: {
          list_id: this.data.list_id || this.options.extra.mailchimp_list_id.value,
          email: this.email,
        },
      };

      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(this.options.extra.recaptcha_public_key.value, { action: 'submit' })
          .then((token) => {
            request.params.token = token;
            this.$store.dispatch('sendRequest', request)
              .then((response) => {
                if (response.data.status && response.data.status === 'subscribed') {
                  this.success = true;
                  this.avoid = false;
                } else {
                  this.success = false;
                  this.avoid = false;
                  this.$store.commit('SET_SNACKBAR', { active: true, content: response.data.detail });
                }
              })
              .catch(() => {
                this.success = false;
                this.avoid = false;
                this.$store.commit('SET_SNACKBAR', { active: true, content: this.$labels.error_generic });
              });
          })
          .catch(() => {
            this.$store.commit('SET_SNACKBAR', { active: true, content: this.$labels.error_generic });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  height: var(--block-height);
  background: var(--green);
}
.form {
  height: 100%;

  &-avoid {
    cursor: progress;

    form {
      pointer-events: none;
    }
  }

  form {
    border-bottom: 1px solid currentColor;

    .field {
      flex: 1 0 0;

      input {
        padding-top: 0;
        @extend %typo--l;
      }
    }
  }
}
</style>

<template>
  <component
    :is="currentTemplate === 'carousel' ? 'Carousel' : tag"
    :class="[
      currentTemplate,
      ...gridCol,
      ...gridJustify,
      ...gridAlign,
      ...extraClass,
      rowGap && rowGap !== '' ? `row-gap-${rowGap}` : false,
      columnGap && columnGap !== '' ? `column-gap-${columnGap}` : false,
    ]"
    :extra-settings="extraSettings"
    :style="grid ? { '--columns' : grid } : undefined"
  >
    <slot />
  </component>
</template>

<script>
import { is } from '@/assets/js/utils';

export default {
  name: 'Grid',
  props: {
    grid: {
      type: String,
      default: () => {},
    },
    tag: {
      type: String,
      default: 'div',
    },
    col: {
      type: [Object, String],
      default: '',
    },
    rowGap: {
      type: String,
      default: '',
    },
    columnGap: {
      type: String,
      default: '',
    },
    template: {
      type: [Object, String, Array],
      default: 'grid',
    },
    extraClass: {
      type: [Array],
      default: () => [],
    },
    justify: {
      type: [Object, String],
      default: '',
    },
    align: {
      type: [Object, String],
      default: '',
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentTemplate: 'grid',
      stopSignal: false,
    };
  },
  computed: {
    gridCol() {
      const { col } = this;
      const cols = [];
      if (typeof col === 'string' && col !== '') {
        cols.push(`grid-${col}`);
      } else if (typeof col === 'object') {
        Object.keys(col).forEach((key) => {
          if (key !== 'default') {
            cols.push(`grid-${key}-${col[key]}`);
          } else {
            cols.push(`grid-${col[key]}`);
          }
        });
      }

      return cols;
    },

    gridJustify() {
      const { justify } = this;
      const justifys = [];
      if (typeof justify === 'string' && justify !== '') {
        justifys.push(`justify-${justify}`);
      } else if (typeof justify === 'object') {
        Object.keys(justify).forEach((key) => {
          if (key !== 'default') {
            justifys.push(`justify-${key}-${justify[key]}`);
          } else {
            justifys.push(`justify-${justify[key]}`);
          }
        });
      }

      return justifys;
    },

    gridAlign() {
      const { align } = this;
      const aligns = [];
      if (typeof align === 'string' && align !== '') {
        aligns.push(`align-${align}`);
      } else if (typeof align === 'object') {
        Object.keys(align).forEach((key) => {
          if (key !== 'default') {
            aligns.push(`align-${key}-${align[key]}`);
          } else {
            aligns.push(`align-${align[key]}`);
          }
        });
      }

      return aligns;
    },
  },
  watch: {
    template(val) {
      if (typeof val === 'string') {
        this.currentTemplate = val;
      }
    },
  },
  created() {
    if (typeof this.template === 'string') {
      this.currentTemplate = this.template;
    }
  },
  mounted() {
    if (typeof this.template === 'object') {
      this.watchLayout();
      this.$bus.$on('windowResized', this.watchLayout);
    }
  },
  beforeUnmount() {
    this.$bus.$off('windowResized', this.watchLayout);
  },
  methods: {
    watchLayout() {
      this.stopSignal = false;
      const keys = Object.keys(this.template);
      keys.forEach((key, index) => {
        if (key === 'default' && !this.stopSignal && (keys.length === 1 || is(keys[1]))) {
          this.stopSignal = true;
          this.currentTemplate = this.template.default;
        } else if (is(key) && !this.stopSignal) {
          this.stopSignal = true;
          this.currentTemplate = this.template[key];
        } else if (!this.stopSignal && index === (keys.length - 1)) {
          this.currentTemplate = this.template[key];
        }
      });
    },
  },
};
</script>

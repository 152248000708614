import Login from './login';
import RecoveryPassword from './recovery-password';
import Register from './register';
import Checkout from './checkout';
import EditAccount from './edit-account';
import EditBillingAddress from './edit-billing-address';
import EditShippingAddress from './edit-shipping-address';
import Coupon from './coupon';
import Pay from './pay';
import ChangePaymentMethod from './change-payment-method';
import EditSubscriptionAddress from './edit-subscription-address';

const mixins = new Map();

mixins.set('login', Login);
mixins.set('recovery-password', RecoveryPassword);
mixins.set('register', Register);
mixins.set('checkout', Checkout);
mixins.set('edit-account', EditAccount);
mixins.set('edit-billing-address', EditBillingAddress);
mixins.set('edit-shipping-address', EditShippingAddress);
mixins.set('coupon', Coupon);
mixins.set('pay', Pay);
mixins.set('changePaymentMethod', ChangePaymentMethod);
mixins.set('edit-subscription-address', EditSubscriptionAddress);

export default mixins;

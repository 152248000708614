var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.firstLoading)?_c('Grid',{staticClass:"grid-cart",attrs:{"col":"12"}},[(_vm.isCartEmpty && !_vm.cartLoading)?_c('Wrapper',{attrs:{"size":"s"}},[_c('div',{staticClass:"block-theme--default"},[_c('div',{staticClass:"t-center"},[_c('Spacer',{attrs:{"bottom":"l"}},[_vm._v(" "+_vm._s(_vm.$labels.your_cart_empty)+" ")])],1),_c('Flex',{attrs:{"justify":"center"}},[_c('Cta',{attrs:{"theme":"button","data":{
            title: _vm.$labels.go_to_shop,
            url: '/shop',
          }}})],1)],1)]):_c('div',[_c('Grid',{staticClass:"grid-main"},[_c('Column',{attrs:{"size":{ default: 12, s: 6, l: 8 }}},[_c('Grid',{staticClass:"block-theme--default",attrs:{"col":"12"}},[_c('CartList',{attrs:{"theme":"complete"}})],1)],1),_c('Column',{staticClass:"cart-left",attrs:{"size":{ default: 12, s: 6, l: 4 }}},[_c('Grid',{staticClass:"block-theme--default",attrs:{"col":"12","row-gap":"m"}},[_c('CartTotals',{attrs:{"theme":"cart"}}),_c('Cta',{attrs:{"theme":"button","data":{
              title: _vm.$labels.go_to_checkout,
              url: '/checkout',
            }}})],1)],1)],1)],1),_c('div',{staticClass:"block-theme--default"},[_c('Title',{staticClass:"title",attrs:{"data":{ value: _vm.$labels.may_be_interested_in, size: 'label-s' }}}),_c('div',[_c('Grid',_vm._l((_vm.products),function(item,index){return _c('Column',{key:index,attrs:{"size":{
            default: 6,
            s: 4,
            m: 2,
          }}},[_c('ThumbProduct',{attrs:{"data":item}})],1)}),1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
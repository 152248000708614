var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Grid',{attrs:{"grid":"1"}},[_c('Form',{staticClass:"form--single",attrs:{"id":"login"}}),_c('Social',{staticClass:"block-theme--default"}),_c('Spacer',{attrs:{"top":"s","x":"s"}},[_c('div',[_c('Cta',{attrs:{"data":{
          title: _vm.$labels.lost_password,
          fn: _vm.toggleRecoverPassword,
        }}}),_c('Spacer',{attrs:{"top":"s"}},[_c('Cta',{attrs:{"data":{
            title: _vm.$labels.register,
            url: '/register',
          }}})],1)],1),_c('Spacer',{attrs:{"top":"s"}},[(_vm.showRecoverPassword)?_c('Form',{staticClass:"form--single",attrs:{"id":"recovery-password","theme":"inline"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$mq.isMobileS)?_c('div',[_c('Spacer',{staticClass:"t-center checkout-order__mobile-static",attrs:{"bottom":"xl","use-margin":true}},[_c('Grid',{attrs:{"col":"12","row-gap":"m"}},[_c('Cta',{attrs:{"data":{
            title: _vm.$labels.place_order,
            fn: _vm.placeOrder,
          },"theme":"button"}}),_c('Checkbox',{attrs:{"data":_vm.terms,"model":_vm.terms.model},on:{"update:model":function($event){return _vm.$set(_vm.terms, "model", $event)}},model:{value:(_vm.terms.model),callback:function ($$v) {_vm.$set(_vm.terms, "model", $$v)},expression:"terms.model"}},[_c('label',{attrs:{"slot":"label","for":"terms"},slot:"label"},[_vm._v(_vm._s(_vm.$labels[_vm.terms.label])+" "),_c('sup',{domProps:{"innerHTML":_vm._s('*')}})])])],1)],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showMobileModal)?_c('div',{staticClass:"overlay",on:{"click":function($event){_vm.showMobileModal = !_vm.showMobileModal}}}):_vm._e()]),_c('Grid',{directives:[{name:"touch",rawName:"v-touch:swipe",value:(_vm.swipeHandlerMobileModal),expression:"swipeHandlerMobileModal",arg:"swipe"}],staticClass:"checkout-order__mobile-modal block-theme--default spacer spacer--padding--a--s",class:{ 'checkout-order__mobile-modal--visible': _vm.showMobileModal },attrs:{"col":"12"}},[_c('div',{on:{"click":function($event){_vm.showMobileModal = !_vm.showMobileModal}}},[_c('Flex',{attrs:{"justify":"space-between","align":"center"}},[_c('Spacer',{staticClass:"typo--label",attrs:{"bottom":"xs"}},[_vm._v(" "+_vm._s(_vm.$labels.show_order_summary)+" ")]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.showMobileModal)?_c('Spacer',{staticClass:"typo--label",attrs:{"bottom":"xs"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.parseCurrency(_vm.unparseCurrency(_vm.cart.totals.total)))}})]):_vm._e()],1)],1)],1),_c('CartList',{attrs:{"theme":"checkout"}}),_c('div',{staticClass:"separator--row"}),_c('CartTotals')],1)],1):_c('Grid',{staticClass:"block-theme--default",attrs:{"col":"12","row-gap":"m"}},[_c('div',{staticClass:"typo--s"},[_vm._v(" "+_vm._s(_vm.$labels.order_details)+" ")]),_c('CartList',{attrs:{"theme":"checkout"}}),_c('CartTotals'),_c('div',[_c('Cta',{attrs:{"data":{
          title: _vm.$labels.place_order,
          fn: _vm.placeOrder,
        },"theme":"button"}})],1),_c('Checkbox',{attrs:{"data":_vm.terms,"model":_vm.terms.model},on:{"update:model":function($event){return _vm.$set(_vm.terms, "model", $event)}},model:{value:(_vm.terms.model),callback:function ($$v) {_vm.$set(_vm.terms, "model", $$v)},expression:"terms.model"}},[_c('label',{attrs:{"slot":"label","for":"terms"},slot:"label"},[_vm._v(_vm._s(_vm.$labels[_vm.terms.label])+" "),_c('sup',{domProps:{"innerHTML":_vm._s('*')}})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
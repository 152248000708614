<template>
  <div />
</template>

<script>

import Cookies from 'js-cookie';

export default {
  name: 'SocialLoggedIn',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {

  },
  async mounted() {
    const loggedIn = await this.$store.dispatch('login');
    if (loggedIn) {
      await this.$store.dispatch('loadCustomerData');
      if (Cookies.get('gds-after-social-login-url')) {
        this.$router.push(Cookies.get('gds-after-social-login-url'));
      } else {
        this.$router.push({ name: 'Account' });
      }
    }
  },
  methods: {

  },
};
</script>

<template>
  <Grid
    v-if="!firstLoading"
    col="12"
    class="grid-cart"
  >
    <!-- Cart -->
    <Wrapper
      v-if="isCartEmpty && !cartLoading"
      size="s"
    >
      <div class="block-theme--default">
        <div class="t-center">
          <Spacer
            bottom="l"
          >
            {{ $labels.your_cart_empty }}
          </Spacer>
        </div>
        <Flex justify="center">
          <Cta
            theme="button"
            :data="{
              title: $labels.go_to_shop,
              url: '/shop',
            }"
          />
        </Flex>
      </div>
    </Wrapper>
    <div v-else>
      <Grid class="grid-main">
        <Column :size="{ default: 12, s: 6, l: 8 }">
          <Grid
            col="12"
            class="block-theme--default"
          >
            <CartList theme="complete" />
          </Grid>
        </Column>
        <Column
          :size="{ default: 12, s: 6, l: 4 }"
          class="cart-left"
        >
          <Grid
            col="12"
            class="block-theme--default"
            row-gap="m"
          >
            <CartTotals theme="cart" />
            <Cta
              theme="button"
              :data="{
                title: $labels.go_to_checkout,
                url: '/checkout',
              }"
            />
          </Grid>
        </Column>
      </Grid>
    </div>
    <div class="block-theme--default">
      <Title
        :data="{ value: $labels.may_be_interested_in, size: 'label-s' }"
        class="title"
      />
      <div>
        <Grid>
          <Column
            v-for="(item, index) in products"
            :key="index"
            :size="{
              default: 6,
              s: 4,
              m: 2,
            }"
          >
            <ThumbProduct :data="item" />
          </Column>
        </Grid>
      </div>
    </div>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash.isempty';

// import {
//   cancelOrder,
// } from '@/api';

import ThumbProduct from '@/components/thumb/product';
import Cta from '@/components/typo/cta';
import Title from '@/components/typo/title';
import CartList from '@/components/wc/cart-list';
import CartTotals from '@/components/wc/cart-totals';

export default {
  name: 'Cart',
  components: {
    ThumbProduct,
    Cta,
    Title,
    CartList,
    CartTotals,
  },
  data() {
    return {
      firstLoading: true,
      products: [],
    };
  },
  computed: {
    ...mapGetters(['cart', 'cartLoading', 'showCart', 'wcSettings']),
    isCartEmpty() {
      return isEmpty(this.cart.items);
    },
  },
  watch: {
    cartLoading() {
      if (this.cartLoading === false) {
        this.firstLoading = false;
      }
    },
  },
  async mounted() {
    // Ordine da cancellare, o rientro da cambio metodo pagamento subscription (es: paypal) con un parametro redirect
    if (
      this.$route.query.cancel_order === 'true'
      && this.$route.query.order
      && this.$route.query.order_id
    ) {
      if (this.$route.query.redirect !== undefined && this.$route.query.redirect == null && this.$route.query.token) {
        // qua è quando rientro da cambio metodo pagamento subscription (es: paypal)
        // /cart/?cancel_order=true&order=wc_order_xxxxx&order_id=nnn&redirect&_wpnonce=xxx&token=EC-yyyyy
        this.$router.replace({
          name: 'Orders',
          replace: true,
        });
        return;
      }
      await this.$store.dispatch('cancelOrder', {
        order_id: this.$route.query.order_id,
        key: this.$route.query.order,
      });
    }

    // Altrimenti mostra carrello
    await this.$store.dispatch('getCart');
    const request = {
      type: 'product',
      params: {
        // include: [this.cart.items.map((x) => x.product_id)],
        per_page: 6,
        offset: 0,
        lang: this.$store.state.lang,
      },
    };
    this.products = await this.$store.dispatch('getItems', request);
    // Marketing
    this.$bus.$emit('viewCart', this.cart);
  },
};
</script>

<style lang="scss" scoped>
.grid-cart {
  min-height: calc((var(--vh, 1vh) * 100) - (2 * var(--bar-height)));
  grid-template-rows: 1fr min-content;
}
.grid-main {
  height: 100%;
}
.title {
  height: var(--bar-height);
  padding: var(--spacer-1) var(--spacer-s) 0;
  background: var(--grey-d);
  color: var(--white);
  line-height: var(--bar-height);
}
.cart-left {
  background: #F6F6F6;
  padding: var(--spacer-s);
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Block',{attrs:{"data":{
    cta: {
      title: 'Back to top',
      fn: _vm.fn,
    },
    icon: 'arrow-top',
    theme: 'go-top',
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <main :data-template="post.acf.template">
    <article
      ref="article"
      :class="background"
    >
      <section class="intro">
        <div class="abstract">
          <Spacer
            top="xs"
            x="s"
            class="abstract-wrapper"
          >
            <Flex
              dir="column"
              class="wrapper"
            >
              <Spacer bottom="xxl">
                <Title
                  :data="{ value: post.title.rendered }"
                  class="title"
                />
                <Title
                  v-if="
                    post.gds_taxonomies.tax_author ||
                      (post.acf.subtitle && post.acf.subtitle !== '')
                  "
                  :data="{
                    value:
                      post.acf.subtitle && post.acf.subtitle !== ''
                        ? post.acf.subtitle
                        : `by ${post.gds_taxonomies.tax_author[0].name}`,
                  }"
                  class="title"
                />
              </Spacer>
            </Flex>
          </Spacer>
        </div>
      </section>
      <section class="meta typo--label">
        <Spacer x="s">
          <Spacer
            top="1"
            class="meta-wrapper"
          >
            <Flex
              gap
              class="flex-auto"
            >
              <time>{{ date }}</time>
              <div
                v-if="post.acf.template !== 'past-issue'"
                class="t-center reading"
              >
                Reading Time {{ remaining }}′
              </div>
              <Share
                :data="{
                  permalink: post.link,
                  title: post.title.rendered,
                  media: post.gds_featured_image
                    ? post.gds_featured_image.url
                    : null,
                  theme: 'post',
                }"
                class="t-right single--share"
              />
            </Flex>
          </Spacer>
        </Spacer>
      </section>
      <Spacer bottom="xxxs" />
      <section class="gallery">
        <Media
          v-if="post.acf.page_gallery && post.acf.page_gallery.length > 0"
          :data="{
            type: 'gallery',
            gallery: post.acf.page_gallery,
            showCaption: true,
            object_fit: 'contain',
          }"
          class="media"
          :style="{
            '--ratio': `${setRatio(post.acf.page_gallery, 'width', true)}%`,
          }"
        />
        <Media
          v-else-if="post.gds_featured_image"
          :data="{
            type: 'image',
            image: post.gds_featured_image,
            ratio: true,
            showCaption: true,
            object_fit: 'contain',
          }"
        />
        <!-- <Tags
          v-if="post.gds_taxonomies.tags"
          class="tags"
          :data="post.gds_taxonomies.tags"
        /> -->
      </section>
      <section
        ref="main"
        class="main-content"
      >
        <div
          v-if="excerpt"
          class="excerpt"
        >
          <Spacer all="s">
            <RichText :data="{ value: excerpt, size: 'l' }" />
          </Spacer>
        </div>
        <div
          ref="content"
          class="text"
        >
          <Spacer bottom="s">
            <div
              v-for="(text, index) in content"
              :key="index"
            >
              <Adv
                v-if="
                  post.acf.template !== 'past-issue' &&
                    index + 1 < content.length
                "
                :key="index"
                :template="randomAdv[index % 3]"
              />
              <RichText
                :data="{ value: text }"
                :class="[
                  'article-wysiwyg',
                  excerpt && index === 0 ? 'with-excerpt' : false,
                ]"
              />
            </div>
          </Spacer>
        </div>
      </section>
      <!-- <Clippy /> -->
    </article>
    <section
      v-if="post.acf.template !== 'past-issue' && category"
      class="related"
    >
      <Archive
        :data="{
          title: 'Related articles',
          archive_type: 'archive',
          archive: post.type,
          max_posts: 4,
          thumb: 'horizontal',
          exclude: [post.id],
          archive_category: {
            taxonomy: category.taxonomy,
            term_id: category.term_id,
          },
          layout: [
            {
              media_query: 'default',
              layout_size: 'auto',
              layout_type: 'carousel',
            },
            {
              media_query: 'm',
              layout_size: '3',
              layout_type: 'grid',
            },
          ],
        }"
      />

      <Archive
        :data="{
          title: 'From the shop',
          archive_type: 'archive',
          archive: 'product',
          max_posts: 4,
          product: post.acf.related_products,
          layout: [
            {
              media_query: 'default',
              layout_size: 'auto',
              layout_type: 'carousel',
            },
            {
              media_query: 'm',
              layout_size: '3',
              layout_type: 'grid',
            },
          ],
        }"
      />
    </section>
    <section class="footer">
      <OverlayFooter
        v-if="post.acf.template !== 'past-issue'"
        :data="footer.blocks"
      />
      <Block
        v-if="post.acf.template === 'past-issue' && post.acf.product"
        class="issue"
        :data="{
          cta: {
            url: post.acf.product.url,
            title: 'Buy issue',
          },
          title: post.acf.product.title,
        }"
      />
    </section>

    <section
      v-if="extraGallery && extraImages"
      class="extra-gallery"
    >
      <Flex
        class="ui"
        justify="space-between"
        align="center"
      >
        <Cta
          :data="{
            title:
              templateExtraGallery === 'carousel'
                ? 'Grid View'
                : 'Gallery View',
            fn: setTemplateExtraGallery,
            size: 'label',
          }"
        />
        <Cta
          :data="{
            title: 'Close',
            fn: closeExtraGallery,
            size: 'label',
          }"
        />
      </Flex>
      <Grid
        :template="templateExtraGallery"
        :col="{ default: 6, m: 3 }"
        :extra-settings="{
          initialIndex: currentIndex,
          template: 'single-gallery',
          currentId,
        }"
        row-gap="xs"
      >
        <div
          v-for="(img, index) in extraImages"
          :key="`gallery-${index}`"
          class="item"
          :data-id="img.id"
          @click="
            () => {
              templateExtraGallery === 'grid' ? setExtraGallery(img.id) : null;
            }
          "
        >
          <Figure
            ref="figure"
            :data="{
              ...img,
              ratio: templateExtraGallery === 'grid',
              object_fit: 'contain',
              showCaption: templateExtraGallery === 'carousel',
            }"
          />

          <div
            v-if="templateExtraGallery === 'carousel'"
            class="count"
          >
            {{ pad(index + 1, 2) }} / {{ pad(extraImages.length, 2) }}
          </div>
        </div>
      </Grid>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import uniqBy from 'lodash.uniqby';
import shuffle from 'lodash.shuffle';
import chunk from 'lodash.chunk';

import Archive from '@/components/blocks/archive';
// import Newsletter from '@/components/blocks/newsletter';
import Block from '@/components/blocks/block';
import Media from '@/components/blocks/media';
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';
import Figure from '@/components/media/figure';
// import Tags from '@/components/typo/tags';
import Share from '@/components/ui/share';
import OverlayFooter from '@/components/ui/overlay-footer';
// import Clippy from '@/components/ui/clippy';
import Adv from '@/components/blocks/custom/adv';

import { pad } from '@/assets/js/utils';

import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Media,
    Title,
    RichText,
    Archive,
    // Tags,
    Figure,
    Cta,
    Share,
    Block,
    Adv,
    OverlayFooter,
    // Clippy,
  },
  mixins: [data],
  data() {
    return {
      extraGallery: false,
      currentIndex: 0,
      currentId: null,
      templateExtraGallery: 'carousel',
      pad,
      remaining: 0,
      currentFootnote: null,
    };
  },
  computed: {
    ...mapGetters(['options', 'advs']),
    background() {
      const background = this.post.acf.background
        ? this.post.acf.background
        : 'default';
      return `background--${background}`;
    },
    footer() {
      const settings = this.options.overlay_footer.find(
        (el) => el.type === 'posts',
      );
      return settings;
    },
    excerpt() {
      const isMore = this.post.content.rendered.includes('<!--more-->');
      const value = this.post.content.rendered.split('<!--more-->');
      return this.post.acf.template === 'past-issue'
        ? false
        : isMore
          ? value[0]
          : false;
    },
    content() {
      const array = this.post.content.rendered.split('<!--more-->');

      const splitContent = array[array.length - 1];

      const { length } = splitContent;
      const index = length <= 10000 ? 2 : length <= 20000 ? 3 : 4;

      const byP = splitContent.split('</p>');
      const content = chunk(byP, Math.round(byP.length / index));
      const final = [];
      content.forEach((text) => final.push(text.join('')));
      return final;
    },
    extraImages() {
      const array = [];

      if (this.post.acf.page_gallery && this.post.acf.page_gallery.length > 0) {
        array.push(...this.post.acf.page_gallery);
      } else if (this.post.gds_featured_image) {
        array.push(this.post.gds_featured_image);
      }
      if (
        this.post.images_from_content
        && this.post.images_from_content.length > 0
      ) {
        array.push(...this.post.images_from_content);
      }

      return uniqBy(array, 'id');
    },
    date() {
      return this.post.acf.template !== 'past-issue'
        ? this.post.date_format
        : this.post.acf.release;
    },
    randomAdv() {
      const advActive = [];

      if (this.advs['post-large']) advActive.push('post-large');
      if (this.advs['post-medium']) advActive.push('post-medium');
      if (this.advs['post-small-1'] || this.advs['post-small-2']) advActive.push('post-small');
      return shuffle(advActive);
    },
    category() {
      return this.post.gds_taxonomies
        ? this.post.gds_taxonomies.categories && this.post.type === 'post'
          ? this.post.gds_taxonomies.categories[0]
          : this.post.gds_taxonomies['category-publishing']
            && this.post.type === 'publishing'
            ? this.post.gds_taxonomies['category-publishing'][0]
            : false
        : false;
    },
  },
  mounted() {
    this.remaining = this.post.reading_time;
    this.setCursor();

    // const imgs = Array.from(this.$refs.main.querySelectorAll('img[data-cursor]'));
    const imgs = [
      ...Array.from(this.$el.querySelectorAll('section.gallery figure img')),
      ...Array.from(
        this.$el.querySelectorAll('.blocks-gallery-item figure img'),
      ),
      ...Array.from(this.$el.querySelectorAll('.wp-block-image img')),
    ];
    if (imgs && imgs.length > 0) {
      imgs.forEach((img) => {
        const id = img.dataset.id
          ? img.dataset.id
          : img.className.split('wp-image-')[1];
        img.addEventListener('click', () => this.setExtraGallery(id));
      });
    }

    if (this.post.acf.template !== 'past-issue') {
      this.$aion.add(this.setRemaining, 'remaining', true);
    }

    this.setFootnotes();
  },
  beforeDestroy() {
    this.$aion.remove('remaining');
    document.body.removeEventListener('keydown', this.closeExtraGallery);
    this.removeCursor();
    this.removeFootnotes();
  },
  methods: {
    setRatio(array, param, limit = false) {
      const getMax = (a, b) => (a[param] > b[param] ? a : b);
      const max = array.reduce(getMax, 0);
      return limit
        ? Math.min(
          (max.height / max.width) * 100,
          (window.innerHeight / window.innerWidth) * 100,
        )
        : (max.height / max.width) * 100;
    },
    setExtraGallery(id) {
      if (this.$mq.isMobile) return;
      this.templateExtraGallery = 'carousel';
      this.extraGallery = true;
      this.currentId = id;
      document.body.classList.add('overlay-gallery');
      document.body.addEventListener('keydown', this.closeExtraGallery);

      this.$nextTick(() => {
        if (this.$refs.figure) {
          this.$refs.figure.forEach((figure) => {
            figure.currentSrc();
          });
        }
      });
    },
    closeExtraGallery(e) {
      if (!e || (e && e.keyCode === 27)) {
        this.currentIndex = 0;
        this.currentId = null;
        this.extraGallery = false;
        this.templateExtraGallery = 'carousel';

        document.body.classList.remove('overlay-gallery');
      }
    },
    setTemplateExtraGallery() {
      this.templateExtraGallery = this.templateExtraGallery === 'carousel' ? 'grid' : 'carousel';

      this.$nextTick(() => {
        if (this.$refs.figure) {
          this.$refs.figure.forEach((figure) => {
            figure.currentSrc();
          });
        }
      });
    },
    setRemaining() {
      const { top, height } = this.$refs.main.getBoundingClientRect();
      if (top < 0 && Math.abs(top) < height) {
        const delta = Math.abs(top) / height;
        this.remaining = Math.max(
          Math.round(this.post.reading_time - delta * this.post.reading_time),
          0,
        );
      }
    },
    setCursor() {
      const imgs = [
        ...Array.from(
          this.$refs.article.querySelectorAll('section.gallery figure img'),
        ),
        ...Array.from(
          this.$refs.article.querySelectorAll('.blocks-gallery-item figure img'),
        ),
        ...Array.from(
          this.$refs.article.querySelectorAll('.wp-block-image img'),
        ),
      ];
      if (imgs && imgs.length > 0) {
        imgs.forEach((img) => {
          img.dataset.cursor = 'Gallery View';
        });
      }
    },
    removeCursor() {
      const imgs = Array.from(
        this.$refs.article.querySelectorAll('figure[data-cursor]'),
      );
      imgs.forEach((img) => {
        img.dataset.cursor = null;
      });
    },
    setFootnotes() {
      const footnotes = Array.from(
        this.$refs.article.querySelectorAll('.modern-footnotes-footnote a'),
      );
      if (footnotes) {
        document.addEventListener('click', this.toggleFootnote);

        // footnotes.forEach((footnote) => {
        //   footnote.addEventListener('mouseenter', this.toggleFootnote);
        //   footnote.addEventListener('mouseleave', this.toggleFootnote);
        // });
      }
    },
    removeFootnotes() {
      // const footnotes = Array.from(this.$el.querySelectorAll('.modern-footnotes-footnote a'));
      // footnotes.forEach((footnote) => {
      //   footnote.removeEventListener('mouseenter', this.toggleFootnote);
      //   footnote.removeEventListener('mouseleave', this.toggleFootnote);
      // });

      document.removeEventListener('click', this.toggleFootnote);
    },
    toggleFootnote(event) {
      const active = this.$refs.article.querySelector(
        '.modern-footnotes-footnote__note.active',
      );
      if (
        !active
        || (event.target !== active && !active.contains(event.target))
      ) {
        if (this.currentFootnote) {
          this.currentFootnote.classList.remove('active');
        }
        if (active) {
          active.classList.remove('active');
        }

        const { mfn } = event.target.parentNode.dataset;
        if (mfn) {
          const action = this.currentFootnote === event.target ? 'remove' : 'add';
          this.currentFootnote = event.target;

          const el = this.$refs.article.querySelector(
            `.modern-footnotes-footnote__note[data-mfn="${mfn}"]`,
          );
          el.classList[action]('active');
          event.target.classList[action]('active');

          if (action === 'remove') {
            this.currentFootnote = null;
          }
        } else {
          this.currentFootnote = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.abstract-wrapper {
  min-height: calc(var(--block-height) - var(--bar-height));
  display: flex;

  .title {
    flex: 1 0 0;
    @include mq(m) {
      width: 50%;
    }
  }
}

.meta {
  position: sticky;
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 10;
  background: var(--white);
  height: 30px;

  & > * {
    flex: 1 0 0;
  }

  .reading {
    flex: 1 0 auto;
  }

  top: calc(var(--bar-height) * 2);
  transition: top 0.4s $ease-custom;

  .header-scrolling & {
    top: calc(var(--bar-height) * 1);
  }
}

.gallery {
  position: relative;
  background: var(--white);

  .tags {
    position: absolute;
    top: auto;
    width: auto;
    height: auto;
    bottom: calc(var(--spacer-s) - 4px);
    left: var(--spacer-s);
    z-index: 2;
    color: var(--white);
    mix-blend-mode: difference;
  }

  ::v-deep .picture {
    background: var(--white);
  }
}

.media {
  ::v-deep .picture {
    padding-bottom: var(--ratio);
    background: var(--white);

    picture {
      position: absolute;
      width: 100%;
    }
  }
}

.excerpt {
  position: relative;

  ::v-deep {
    h1,
    h2,
    h3,
    p {
      @extend %typo--l;
    }
  }
}

article {
  --margin-top: 100px;

  @include mq(m) {
    --margin-top: 150px;
  }
}

.article-wysiwyg {
  margin-top: var(--spacer-s);
  &.with-excerpt {
    margin-top: var(--margin-top);
  }

  ::v-deep {
    h1,
    h2,
    h3 {
      @extend %typo--m;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      padding: var(--spacer-1) var(--spacer-s) 0;
      text-align: center;

      max-width: 250px;
      margin: 0 auto;

      @include mq(m) {
        margin-left: 0;
        width: 50%;
        max-width: 50%;
      }

      margin-bottom: var(--spacer-xl);

      &:not(:first-child) {
        margin-top: var(--margin-top);
      }
    }

    h4 {
      padding: var(--spacer-1) var(--spacer-s) 0;
      margin: 0 0 var(--spacer-xxs) 0;
    }

    p {
      padding: var(--spacer-1) var(--spacer-s) 0;
      @extend %typo--m;

      @include mq(m) {
        max-width: 50%;
      }
    }

    .wp-block-quote {
      margin: var(--spacer-xl) 0;

      p {
        @extend %typo--l;
      }
    }

    .wp-block-gallery,
    .wp-block-image {
      width: 100%;
      margin: var(--spacer-xl) 0;
    }

    .wp-block-preformatted {
      margin-top: var(--margin-top);
      padding: var(--spacer-1) var(--spacer-s) 0;

      @include mq(m) {
        max-width: 40%;
      }

      & + .wp-block-preformatted {
        margin-top: var(--spacer-xxl);
      }
    }

    img {
      width: 100% !important;
      cursor: pointer;
    }
  }
}
.extra-gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 30;
  background: var(--white);
  overflow-y: scroll;

  .ui {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--bar-height);
    z-index: 1;
    padding: var(--spacer-1) var(--spacer-s) 0;
  }

  .count {
    position: absolute;
    bottom: var(--spacer-xs);
    right: var(--spacer-s);
    @extend %typo--xs;
    z-index: 1;
  }

  ::v-deep {
    .carousel {
      .item {
        display: flex;
        height: calc(var(--vh, 1vh) * 100);

        .picture {
          background: var(--white);
        }

        picture {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translate3d(0, -50%, 0);
        }

        figcaption {
          @include mq(m) {
            max-width: 50%;
          }
        }
      }

      .navigation {
        & > * {
          flex: 0 0 50%;
          pointer-events: auto;
        }
      }
    }

    .grid {
      padding-top: var(--bar-height);
      .item {
        cursor: pointer;
        picture {
          padding-bottom: var(--ratio);
          height: 0;
        }

        img {
          position: relative;
          height: auto;
          width: 100%;
          // object-fit: none;
        }
      }
    }
  }
}

[data-template="past-issue"] {
  .issue {
    margin-top: var(--block-height);
  }
}
</style>

<style lang="scss">
article {
  .modern-footnotes-footnote__note {
    @extend %typo--m;
    @include mq($until: m) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--grey-xd);
      color: var(--white);
      opacity: 1;
      transform: translate3d(0, 100%, 0);
      visibility: hidden;
      transition: all 0.4s $ease-custom;
      padding: var(--spacer-s);
      z-index: 10;
    }

    @include mq(m) {
      position: absolute;
      display: inline-block;
      width: 40%;
      left: 60%;
      visibility: hidden;
      padding: 0 var(--spacer-xl) 0 var(--spacer-xl);
    }

    &::before {
      display: inline-block;
      content: attr(data-mfn);
      margin-right: var(--spacer-s);

      @include mq(m) {
        position: absolute;
        left: 0;
      }
    }
  }

  .excerpt {
    .modern-footnotes-footnote__note {
      @include mq(m) {
        top: 100%;
        transform: translate3d(0, var(--margin-top), 0);
      }
    }
  }

  .modern-footnotes-footnote__note.active {
    opacity: 1;
    visibility: visible;

    @include mq($until: m) {
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }
  }

  /* .modern-footnotes-footnote__note:hover {
    opacity: 1;
  } */

  .modern-footnotes-footnote {
    font-size: inherit;
    a {
      display: inline-flex;
      width: 18px;
      height: 18px;
      background: var(--grey-xxl);
      transition: none;
      font-size: 14px;
      padding-top: 4px;
      justify-content: center;
      align-items: center;
    }
  }

  .modern-footnotes-footnote {
    @include mq($and: $hover) {
      &:hover {
        a {
          background: var(--grey-xxd);
          color: var(--white);
        }
      }
    }

    a.active {
      background: var(--grey-xxd);
      color: var(--white);
    }
  }

  .wp-block-interview {
    & + .wp-block-interview {
      margin-top: var(--spacer-xl);
    }
  }

  span[style="font-size: 10pt;"] {
    display: inline-block;
    @extend %typo--s;
  }
}

/* Background colors */
.background {
  &--default {
    background: var(--white);
    .media {
      .picture {
        background: var(--white);
      }
    }
    .meta {
      background: var(--white);
    }
    .modern-footnotes-footnote {
      background: var(--grey-xxl);
      & a {
        background: var(--grey-xxl);
      }
    }
  }

  &--grey--100 {
    background: #c3c3c3;
    .media {
      .picture {
        background: #c3c3c3;
      }
    }
    .meta {
      background: #c3c3c3;
    }
    .modern-footnotes-footnote {
      a {
        background: #c3c3c3;
      }
      // @include mq($and: $hover) {
      //   &:hover {
      //     a {
      //       background: #c3c3c3;
      //       color: black;
      //     }
      //   }
      // }
      // a.active {
      //   background: #c3c3c3;
      //   color: black;
      // }
    }
    .single--share {
      background: #c3c3c3 !important;
    }
    .gallery {
      position: relative;
      background: #c3c3c3;
    }
  }

  &--grey--200 {
    background: #949494;
    .media {
      .picture {
        background: #949494;
      }
    }
    .meta {
      background: #949494;
    }
    .modern-footnotes-footnote {
      a {
        background: #949494;
      }
      // @include mq($and: $hover) {
      //   &:hover {
      //     a {
      //       background: #949494;
      //       color: black;
      //     }
      //   }
      // }
      // a.active {
      //   background: #949494;
      //   color: black;
      // }
    }
    .single--share {
      background: #949494 !important;
    }
    .gallery {
      position: relative;
      background: #949494;
    }
  }
}
</style>

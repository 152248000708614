<template>
  <Grid
    col="12"
    row-gap="m"
  >
    <Flex
      v-for="(orderItem, index) in order.items"
      :key="index"
      justify="space-between"
    >
      <Flex
        nowrap
      >
        <div
          class="wc-product-image wc-product-image--l"
          :style="{
            backgroundImage: `url(${orderItem.product.gds_featured_image ? orderItem.product.gds_featured_image.sizes.medium : ''})`
          }"
        />
        <Flex
          dir="column"
          justify="space-between"
        >
          <Spacer y="xs">
            <del v-if="orderItem.qty_refunded_for_item < 0">{{ orderItem.quantity - orderItem.qty_refunded_for_item }}</del>
            {{ orderItem.quantity }} x <router-link
              :to="$relativeUrl(orderItem.product.permalink)"
            >
              {{ orderItem.item_name }}
              <Spacer
                v-if="orderItem.item_metas"
                top="xs"
              >
                <div
                  v-for="(itemMeta, j) in orderItem.item_metas"
                  :key="j"
                  class="typo--xs"
                >
                  {{ itemMeta.value }}
                </div>
              </Spacer>
            </router-link>

            <Spacer
              top="s"
            >
              <div
                v-html="getTaxedValueLine(orderItem)"
              />
            </Spacer>
          </Spacer>
        </Flex>
      </Flex>
    </Flex>
    <div class="separator--row" />

    <Grid
      v-if="order.fees && order.fees[0]"
      col="12"
      row-gap="m"
    >
      <Flex

        v-for="(fee, index) in order.fees"
        :key="index"
        justify="space-between"
      >
        <Flex column-gap="m">
          <div v-html="fee.name" />
        </Flex>
        <div
          class="t-right"
          v-html="getTaxedValueLine(fee)"
        />
      </Flex>
      <div class="separator--row" />
    </Grid>

    <Flex
      justify="space-between"
    >
      <div>{{ $labels.subtotal }}</div>
      <div
        class="t-right"
        v-html="subtotal"
      />
    </Flex>
    <Flex
      justify="space-between"
    >
      <div>{{ $labels.shipping }}</div>
      <div
        class="t-right"
        v-html="shipping"
      />
    </Flex>
    <Flex
      v-if="order.discount_total > 0"
      justify="space-between"
    >
      <div>{{ $labels.discount }}</div>
      <div>-<span v-html="discount" /></div>
    </Flex>
    <Flex
      v-if="taxDisplayCart === 'excl'"
      justify="space-between"
    >
      <div>{{ $labels.vat }}</div>
      <div v-html="vat" />
    </Flex>
    <Flex
      v-if="order.total_refunded > 0"
      justify="space-between"
    >
      <div>{{ $labels.refunded }}</div>
      <div>-<span v-html="parseCurrency(order.total_refunded)" /></div>
    </Flex>
    <Flex
      v-if="paymentGateway"
      justify="space-between"
    >
      <div>
        <div>{{ $labels.payment_method }}</div>
        <BanksDetails v-if="order.payment_method === 'bacs'" />
      </div>
      <div>
        {{ paymentGateway }}
      </div>
    </Flex>
    <div class="separator--row" />
    <Flex
      justify="space-between"
    >
      <div>{{ $labels.total }}</div>
      <div v-html="total" />
    </Flex>
    <div
      class="separator--row"
    />

    <Address :data="order" />
    <Spacer
      v-if="order.customer_note || (order.notes && order.notes[0])"
      top="m"
    >
      <div class="separator--row" />
    </Spacer>
    <Spacer
      v-if="order.customer_note"
    >
      <div class="typo--m">
        {{ $labels.additional_information }}
      </div>
      <Spacer y="xs">
        {{ order.customer_note }}
      </Spacer>
    </Spacer>
    <Spacer
      v-if="order.notes && order.notes[0]"
      :top="order.customer_note ? 'm' : false"
    >
      <Spacer
        y="xs"
        class="typo--m"
      >
        {{ $labels.order_updates }}
      </Spacer>
      <div
        v-for="(note) in order.notes"
        :key="note.id"
      >
        <Spacer y="xs">
          <div>{{ parseDateTime(note.date_created.date) }}</div>
          <div>{{ note.content }}</div>
        </Spacer>
      </div>
    </Spacer>
    <Spacer
      v-if="orderActions && orderActions[0] && theme === 'list'"
      top="s"
      bottom="s"
    >
      <Wrapper
        size="s"
        :margin="false"
      >
        <Flex
          column-gap="m"
          row-gap="m"
        >
          <Column
            v-for="(orderAction, i) in orderActions"
            :key="i"
            :size="{ default: 12, m: orderAction === 'pay' ? 6 : 'auto' }"
          >
            <Cta
              v-if="orderAction === 'pay'"
              theme="button"
              :data="{
                title: $labels.go_to_payment,
                url: { name: 'PayOrder', params: {
                  orderId: order.id,
                }, query: {
                  pay_for_order: true,
                  key: order.key
                }},
              }"
            />
            <Cta
              v-if="orderAction === 'cancel'"
              theme="button-outline"
              :data="{
                title: $labels.cancel_order,
                fn: openModal
              }"
            />
          </Column>
        </Flex>
      </Wrapper>
    </Spacer>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  parseCurrency, unparseCurrency, parseDateTime, availableOrderActions, getOrderTotal,
} from '@/assets/js/utils-wc';

import Cta from '@/components/typo/cta';
import BanksDetails from '@/components/wc/banks-details.vue';
import Address from '@/components/wc/address.vue';

export default {
  name: 'OrderDetails',
  components: {
    Cta,
    BanksDetails,
    Address,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapGetters(['wcSettings', 'paymentGateways', 'geoData']),
    total() {
      return getOrderTotal(this.order, this.wcSettings);
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.order) {
        let linesTotals = 0;
        let linesTaxs = 0;
        this.order.items.forEach((element) => {
          linesTotals += Number(element.subtotal);
          linesTaxs += Number(element.subtotal_tax);
        });
        this.order.fees.forEach((element) => {
          linesTotals += Number(element.subtotal);
          linesTaxs += Number(element.subtotal_tax);
        });
        if (this.taxDisplayCart === 'excl') {
          return `${this.parseCurrency(Number(linesTotals))}`;
        }
        return this.parseCurrency(Number(linesTotals) + Number(linesTaxs));
      }
      return null;
    },
    discount() {
      if (this.order) {
        if (this.taxDisplayCart === 'excl') {
          return this.parseCurrency(Number(this.order.discount_total));
        }
        return this.parseCurrency(Number(this.order.discount_total) + Number(this.order.discount_tax));
      }
      return null;
    },
    shipping() {
      if (this.order) {
        if (this.taxDisplayCart === 'excl') {
          return this.parseCurrency(Number(this.order.shipping_total));
        }
        return this.parseCurrency(Number(this.order.shipping_total) + Number(this.order.shipping_tax));
      }
      return null;
    },
    paymentGateway() {
      if (this.order && this.order.payment_method) {
        if (this.paymentGateways[this.order.payment_method]) {
          return this.paymentGateways[this.order.payment_method].title;
        }
        return this.order.payment_method.charAt(0).toUpperCase() + this.order.payment_method.slice(1);
      }
      return null;
    },
    vat() {
      return this.parseCurrency(Number(this.order.total_tax));
    },
    orderStatus() {
      return this.$store.state.wp.wcSettings.woocommerce_order_statuses[`wc-${this.order.status}`];
    },
    orderActions() {
      return availableOrderActions[`wc-${this.order.status}`];
    },
  },
  mounted() {

  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings, this.order.currency);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    getTaxedValueLine(row) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(Number(row.total) + Number(row.total_tax));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(Number(row.total))}`;
      }
      return `${this.parseCurrency(Number(row.total))} + ${this.parseCurrency(Number(row.total_tax))} VAT`;
    },
    parseDateTime(date) {
      return parseDateTime(date, this.wcSettings);
    },
    async cancelOrder() {
      await this.$store.dispatch('cancelOrder', {
        order_id: this.order.id,
        key: this.order.key,
      });
      await this.$store.dispatch('getOrders');
    },
    openModal() {
      this.$bus.$emit('modal', {
        type: 'dialog',
        id: 'cancelOrder',
        theme: 'dialog',
        content: {
          richtext: this.$labels.cancel_order_dialog,
          cta: {
            title: this.$labels.cancel_order,
          },
        },
        fn: this.cancelOrder,
      });
    },
  },
};
</script>

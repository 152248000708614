<template>
  <Grid row-gap="m">
    <Column
      :size="{
        default: 12,
        s: 6}"
    >
      <Wrapper
        size="s"
        :margin="false"
      >
        <Spacer
          :bottom="{ default: 'xs', m: 's' }"
          class="typo--m"
        >
          {{ $labels.billing_address }}
        </Spacer>
        <div class="typo--s">
          {{ data.billing.first_name }} {{ data.billing.last_name }}
          <br>
          <span v-if="data.billing.company">
            {{ data.billing.company }}
            <br>
          </span>
          {{ data.billing.address_1 }}
          <br>
          <span v-if="data.billing.address_2">
            {{ data.billing.address_2 }}
            <br>
          </span>
          {{ data.billing.postcode }}
          <br>
          {{ data.billing.city }}, {{ geoData.states ? geoData.states[data.billing.country][data.billing.state] : data.billing.state }}
          <br>
          {{ geoData.countries ? geoData.countries[data.billing.country] : data.billing.country }}
          <br>
          <a :href="'mailto:'+data.billing.email">{{ data.billing.email }}</a>
          <br>
          <a :href="'tel:'+data.billing.phone">{{ data.billing.phone }}</a>
          <br>
        </div>
      </Wrapper>
    </Column>
    <Column
      :size="{
        default: 12,
        s: 6
      }"
    >
      <Wrapper
        size="s"
        :margin="false"
      >
        <Spacer
          :bottom="{ default: 'xs', m: 's' }"
          class="typo--m"
        >
          {{ $labels.shipping_address }}
        </Spacer>
        <div class="typo--s">
          {{ data.shipping.first_name }} {{ data.shipping.last_name }}
          <br>
          <span v-if="data.shipping.company">
            {{ data.shipping.company }}
            <br>
          </span>
          {{ data.shipping.address_1 }}
          <br>
          <span v-if="data.shipping.address_2">
            {{ data.shipping.address_2 }}
            <br>
          </span>
          {{ data.shipping.postcode }}
          <br>
          {{ data.shipping.city }}, {{ geoData.states ? geoData.states[data.shipping.country][data.shipping.state] : data.shipping.state }}
          <br>
          {{ geoData.countries ? geoData.countries[data.shipping.country] : data.shipping.country }}
          <br>
        </div>
        <Flex>
          <Spacer
            v-if="data.subscription_data && data.status === 'active'"
            top="m"
          >
            <Cta
              theme="button"
              :data="{
                title: $labels.edit,
                url: { name: 'EditAddress', params: {
                  addressType: 'shipping',
                }, query: {
                  subscription: data.id,
                  key: data.key
                }},
              }"
            />
          </Spacer>
        </Flex>
      </Wrapper>
    </Column>
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Address',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['wcSettings', 'geoData']),
  },
  mounted() {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
div /deep/ del + ins {
  padding-left: var(--spacer-xs);
}
</style>

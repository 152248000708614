<template>
  <Spacer
    y="s"
  >
    <Wrapper
      v-if="user"
      :margin="false"
    >
      <MenuUser />
      <router-view :key="$route.fullPath" />
    </Wrapper>
    <Wrapper
      v-else
      size="xs"
      :margin="false"
    >
      <Grid
        col="12"
        row-gap="m"
      >
        <Spacer
          class="typo--m"
          x="s"
        >
          {{ $labels.login }}
        </Spacer>
        <Login />
      </Grid>
    </Wrapper>
    <div />
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

import MenuUser from '@/components/wc/menu-user';
import Login from '@/components/wc/login';

export default {
  name: 'Account',
  components: {
    MenuUser,
    Login,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['user', 'authLoading']),
  },
};
</script>

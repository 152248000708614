<template>
  <Grid grid="1">
    <Form
      id="login"
      class="form--single"
    />
    <Social class="block-theme--default" />
    <Spacer
      top="s"
      x="s"
    >
      <div>
        <Cta
          :data="{
            title: $labels.lost_password,
            fn: toggleRecoverPassword,
          }"
        />
        <Spacer top="s">
          <Cta
            :data="{
              title: $labels.register,
              url: '/register',
            }"
          />
        </Spacer>
      </div>
      <Spacer top="s">
        <Form
          v-if="showRecoverPassword"
          id="recovery-password"
          class="form--single"
          theme="inline"
        />
      </Spacer>
    </Spacer>
  </Grid>
</template>

<script>
import Form from '@/components/form';
import Cta from '@/components/typo/cta';
import Cookies from 'js-cookie';
import Social from '@/components/wc/login-social';

export default {
  name: 'Login',
  components: {
    Form,
    Social,
    Cta,
  },
  data() {
    return {
      showRecoverPassword: false,
    };
  },
  computed: {
    loginApps() {
      if (this.$store.state.wp.session.social_apps) {
        return this.$store.state.wp.session.social_apps;
      }
      return [];
    },
  },
  methods: {
    toggleRecoverPassword() {
      this.showRecoverPassword = !this.showRecoverPassword;
    },
    loginWithApp(appName) {
      /* eslint-disable no-undef */
      Cookies.set('gds-after-social-login-url', this.$route.fullPath, { expires: 1 });
      moOpenIdLogin(appName, 'true');
    },
  },
};
</script>

<style lang="scss" scoped>
.form--single {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>

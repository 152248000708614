<template>
  <header v-show="!$route.meta.hideHeader">
    <Flex
      class="wrapper"
      align="center"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Flex align="center">
          <component
            :is="$route.name !== 'Index' ? 'router-link' : 'div'"
            to="/"
            class="logo"
          >
            <inline-svg :src="require('@/assets/svg/logo.svg')" />
          </component>

          <Menu
            v-if="!$mq.isMobile"
            :fn="setCurrent"
            :current="current"
            :class="[signal ? 'menu--hover' : false]"
          />
        </Flex>
        <WcMenu v-if="!$mq.isMobile" />
        <Flex v-if="$mq.isMobile">
          <Icon
            name="search"
            :to="{ name: 'Search', query: { type: 'post' } }"
          />
          <Icon
            name="user"
            :to="{ name: 'EditAccount' }"
          />
          <Icon
            name="cart"
            :to="$route.name === 'Checkout' ? '/cart' : undefined"
            :fn="$route.name !== 'Checkout' ? toggleCart : undefined"
          />
          <Spacer
            left="m"
            use-margin
          >
            <Icon
              :name="!menuStatus ? 'menu' : 'close'"
              :fn="toggleMenu"
            />
          </Spacer>
        </Flex>
      </Flex>
    </Flex>

    <MenuMobile v-if="$mq.isMobile && menuStatus" />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from '@/components/ui/menu';
import MenuMobile from '@/components/ui/menu-mobile';
import WcMenu from '@/components/wc/wc-menu';
import Icon from '@/components/ui/icon';

export default {
  name: 'Header',
  components: {
    Menu,
    WcMenu,
    MenuMobile,
    Icon,
  },
  data() {
    return {
      current: null,
      signal: false,
      signalTimeout: null,
    };
  },
  computed: {
    ...mapGetters(['menuStatus']),
  },
  methods: {
    setCurrent(current) {
      if (this.signalTimeout) {
        window.clearTimeout(this.signalTimeout);
      }

      if (current) {
        this.current = current;
        this.signal = true;

        this.signalTimeout = setTimeout(() => {
          this.signal = false;
        }, 1000);
      } else {
        this.current = null;
        this.signal = false;
      }
    },
    toggleCart() {
      this.$store.commit('TOGGLE_CART');
    },
    toggleMenu() {
      document.body.classList.toggle('overlay-menu');
      this.$store.commit('TOGGLE_MENU');
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20;
  background: var(--white);

  @extend %typo--label-s;

  transition: top 0.4s $ease-custom;

  .header-scrolling & {
    top: calc(var(--bar-height) * -1);
  }

  .wrapper {
    position: relative;
    height: var(--bar-height);
    padding: 0 0 0 var(--spacer-s);

    @include mq(m) {
      padding: var(--spacer-1) var(--spacer-s) 0;
    }

    & > * {
      flex: 0 0 100%;
    }
  }

  .logo {
    margin-right: var(--spacer-xl);
    display: flex;
    height: 14px;
    align-items: baseline;

    svg {
      width: 80px;
      height: 15px;

      @include mq(m) {
        width: 56px;
        height: 12px;
      }
    }
  }

  .menu--hover {
    ::v-deep .link:not(.link--hover) {
      pointer-events: none;
    }
  }
}
</style>

<template>
  <component
    :is="to ? 'router-link' : href ? 'a' : 'div'"
    :class="[
      'icon',
      `icon-theme--${theme}`,
      `icon--size-${size}`,
      `icon-${name}`,
    ]"
    :to="to ? typeof to === 'string' ? $relativeUrl(to) : to : undefined"
    @click.stop="fn"
  >
    <inline-svg :src="require(`@/assets/svg/icons/${name}.svg`)" />
  </component>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'm',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: [String, Object],
      default: () => {},
    },
    href: {
      type: String,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.icon {
  position: relative;
  cursor: pointer;

  width: 40px;
  height: 40px;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 24px;
    height: 24px;
  }

  &-theme {
    &--default {
    }

    &--warning {
      path {
        fill: var(--warning-color);
      }
    }
    &--invert {
      path {
        fill: var(--white);
      }
    }

    &--circle {
    background: var(--black);
    border-radius: 50%;
    path {
      fill: var(--white);
    }
  }
  }

  &--size-header {
    width: var(--bar-height);
    height: var(--bar-height);

    svg {
      width: var(--bar-height);
      height: var(--bar-height);
    }
  }

  &--size-l {
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
    }

    @include mq(m) {
      width: 40px;
      height: 40px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  &--size-xs {
  width: 18px;
  height: 18px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &--close {
    position: absolute;
    top: $unit * 0.5;
    right: $unit * 0.5;
    z-index: 10;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Block',{attrs:{"data":{
    cta: {
      title: 'Go back to the shop',
      url: '/shop'
    },
    icon: 'arrow-left',
    theme: 'go-back',
    background: '#2B2C2F'
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }
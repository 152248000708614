import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Repeater from '@/components/blocks/repeater';
import List from '@/components/blocks/list';
import Archive from '@/components/blocks/archive';
import Custom from '@/components/blocks/custom';
import Newsletter from '@/components/blocks/newsletter';
import Hero from '@/components/blocks/hero';
import Accordion from '@/components/blocks/accordion';
import Marquee from '@/components/blocks/marquee';
import Search from '@/components/blocks/search';
import Block from '@/components/blocks/block';
import News from '@/components/blocks/custom/news';
import Adv from '@/components/blocks/custom/adv';

const components = new Map();

components.set('content', Content);
components.set('media', Media);
components.set('repeater', Repeater);
components.set('list', List);
components.set('archive', Archive);
components.set('hero', Hero);
components.set('marquee', Marquee);
components.set('newsletter', Newsletter);
components.set('accordion', Accordion);
components.set('placeholder', Custom);
components.set('search', Search);
components.set('block', Block);
components.set('news', News);
components.set('adv', Adv);

export default components;

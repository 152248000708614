<template>
  <main>
    <Archive
      :data="{
        archive_type: 'archive',
        archive: request.postType,
        posts_per_page: request.parent === 'shop' ? 20 : 25,
        infinite_scroll: true,
        orderby: 'date',
        archive_category: {
          taxonomy: request.category,
          term_id: request.categoryId,
        },
        layout: [
          {
            media_query: 'default',
            layout_size: '6',
            layout_type: 'grid'
          },
          {
            media_query: 'm',
            layout_size: '3',
            layout_type: 'grid'
          }
        ],
        thumb: 'horizontal',
        template: request.postType === 'product' ? 'shop' : categorySlug === 'past-issues' ? 'past-issues' : 'category'
      }"
    />
  </main>
</template>

<script>
import Archive from '@/components/blocks/archive';

export default {
  name: 'Category',
  components: {
    Archive,
  },
  props: {
    category: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      archive: null,
      request: {},
      categorySlug: null,
    };
  },
  created() {
    const {
      category, postType, parent, key,
    } = this.$route.meta;
    const { slug } = this.$route.params;

    this.request = {
      category: this.category ? this.category.category : category,
      postType: this.category ? this.category.postType : postType,
      parent: this.category ? this.category.parent : parent,
      slug: this.category ? this.category.slug : slug,
      key: this.category ? this.category.key : key,
    };

    let cat = null;

    if (key === 'post_tag') {
      cat = this.$store.state.wp.tags.default[this.request.slug];
    } else {
      cat = this.$store.state.wp.categories[this.request.parent].default[this.request.slug];
    }

    this.categorySlug = cat.slug;
    this.request.categoryId = cat.id;

    this.$store.commit('SET_CURRENT_PAGE', cat);
  },
};
</script>

<style>

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.overlay ? 'app--is-overlay' : false,
    _vm.$route.name === 'Index' ? 'app--home' : false,
    _vm.$route.name === 'Search' ? 'app--search' : false,
  ],attrs:{"id":"app"}},[_c('AppHeader'),_c('Breadcrumbs'),(_vm.search)?_c('Search'):_vm._e(),_c('transition',{attrs:{"css":false,"appear":"","mode":"out-in"},on:{"enter":_vm.enter,"leave":_vm.leave}},[_c('div',{key:_vm.filteredPath,class:['main--default', `main--${_vm.filteredPath.substring(1)}`],style:(_vm.overlay ? { transform: `translate3d(0, ${_vm.offset * -1}px, 0)` } : false)},[_c('router-view'),_c('AppFooter',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideFooter),expression:"!hideFooter"}]})],1)]),_c('transition',{attrs:{"css":false,"mode":_vm.$route.meta.overlay && _vm.$store.state.route.from.meta.overlay && !_vm.$route.meta.avoidTransition
        ? 'in-out'
        : 'out-in',"appear":""},on:{"enter":_vm.enterOverlay,"leave":_vm.leaveOverlay}},[(_vm.overlay)?_c('div',{key:_vm.$route.path,ref:"overlay",staticClass:"main--overlay",style:({ '--overlay-opacity': Number(_vm.opacity).toFixed(2) }),on:{"click":_vm.clickGoBack}},[_c('router-view',{attrs:{"name":"overlay"}})],1):_vm._e()]),_c('Modal'),_c('Snackbar'),_c('Cart'),_c('Loader'),(!_vm.$mq.isMobile)?_c('Pointer'):_vm._e(),_c('div',{ref:"disable",staticClass:"disable"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Marquee
    :data="{ items: [{
      type: 'text',
      text: data.news.title,
      url: data.news.url,
    }], direction: 'ltr', background_color: data.background_color, color: data.color }"
  />
</template>

<script>
import Marquee from '@/components/blocks/marquee';

export default {
  name: 'News',
  components: {
    Marquee,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>

<template>
  <Grid
    col="12"
    row-gap="s"
  >
    <Form
      id="edit-account"
      :name="$labels.profile"
      class="form--single"
    />
    <Form
      id="edit-billing-address"
      :name="$labels.billing_address"
      class="form--single"
    />
    <Form
      id="edit-shipping-address"
      :name="$labels.shipping_address"
      class="form--single"
    />
    <div
      v-if="paymentGateways && paymentGateways.stripe && paymentGateways.stripe.publishable_key"
    >
      <SavedCards />
    </div>
  </Grid>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { mapGetters } from 'vuex';

import Form from '@/components/form';
import SavedCards from '@/components/wc/saved-cards';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'EditAccount',
  components: {
    Form,
    SavedCards,
  },
  computed: {
    ...mapGetters(['user', 'paymentGateways']),
  },
  mounted() {
    if (this.user) {
      // Ricarica dati se utente cambia al checkout
      this.$store.dispatch('loadCustomerData');
      if (this.$route.hash) {
        const accordionWrapper = document.querySelector(this.$route.hash).closest('.accordion-wrapper');
        if (accordionWrapper) {
          accordionWrapper.querySelector('.accordion__trigger').click();
        }
        setTimeout(() => {
          disableBodyScroll(this.$el);
          gsap.killTweensOf(window);
          gsap.to(window, {
            scrollTo: {
              y: accordionWrapper,
            },
            duration: 0.4,
            ease: 'expo.out',
            onComplete: () => {
              clearAllBodyScrollLocks();
            },
          });
        }, 1000);
      }
    }
  },
  methods: {

  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.id,class:[_vm.theme ? `form-theme--${_vm.theme}` : 'form-theme--default']},[_c(_vm.setWrapperComponent(),{tag:"component"},[(_vm.settings.title)?_c('template',{slot:"form-title"},[_c('span',{staticClass:"typo--m"},[_vm._v(_vm._s(_vm.settings.title))])]):_vm._e(),_c('template',{slot:"form-groups"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"form",attrs:{"id":_vm.id,"tag":"form","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.callback)}}},[_c('Grid',{attrs:{"row-gap":"0"}},[_vm._l((_vm.groups),function(group,groupKey){return _c('Column',{key:groupKey,staticClass:"form-group",attrs:{"size":!_vm.responsive
                ? { default: group.size }
                : { default: 12, s: group.size || 12 },"data-form-group":groupKey}},[_c(_vm.setGroupComponent(group),{tag:"component",attrs:{"data":group}},[(group.title)?_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"form-group__header"},[_c('div',{staticClass:"form-group__title"},[(group.accordion && group.accordion.toggle)?_c('div',{staticClass:"field field--checkbox"},[_c('label',{staticClass:"typo--m"},[_vm._v(_vm._s(group.title))]),_c('div',{staticClass:"field-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(group.visible),expression:"group.visible"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(group.visible)?_vm._i(group.visible,null)>-1:(group.visible)},on:{"change":function($event){var $$a=group.visible,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(group, "visible", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(group, "visible", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(group, "visible", $$c)}}}}),_c('div',{staticClass:"field-after-input"})])]):_c('Spacer',{staticClass:"typo--m",attrs:{"x":!group.accordion ? 's' : false,"bottom":!group.accordion ? 's' : false,"top":!group.accordion ? 'xxs' : false}},[_vm._v(" "+_vm._s(group.title)+" ")])],1)])]):_vm._e(),_c('div',{attrs:{"slot":"fields"},slot:"fields"},[_c('Wrapper',{attrs:{"size":"m","margin":false}},[_c('Spacer',{attrs:{"x":_vm.theme !== 'inline' ? 's' : false}},[(_vm.showEmptyState(group))?_c('div',{staticClass:"form-group__fields",domProps:{"innerHTML":_vm._s(group.emptyState.value)}}):_c('Grid',{staticClass:"form-group__fields",attrs:{"row-gap":"xs","column-gap":"l"}},_vm._l((group.fields),function(field,key){return _c('Column',{key:key,attrs:{"size":!_vm.responsive
                            ? { default: field.size }
                            : { default: 12, s: field.size || 12 }}},[_c(_vm.setFieldComponent(field.type),{key:key,ref:field.id,refInFor:true,tag:"component",class:[field.id ? `form-field--${field.id}` : ''],attrs:{"data":field,"model":field.model},on:{"update:model":function($event){return _vm.$set(field, "model", $event)}}},[_c('label',{attrs:{"slot":"label","for":field.id || key},slot:"label"},[_vm._v(_vm._s(_vm.$labels[field.label])+" "),(field.rules)?_c('sup',{domProps:{"innerHTML":_vm._s(_vm.isRequired(field.rules))}}):_vm._e()])])],1)}),1)],1)],1)],1)])],1)}),_c('Column',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkEmitGlobal),expression:"checkEmitGlobal"}],attrs:{"size":"12"}},[_c('Wrapper',{attrs:{"size":"m","margin":false}},[_c('Spacer',{attrs:{"x":_vm.theme !== 'inline' ? 's' : false,"bottom":"s"}},[_c('Grid',{attrs:{"column-gap":"l"}},[_c('Column',{staticClass:"form-footer",attrs:{"size":_vm.setBtnSize}},[_c('Cta',{attrs:{"theme":"button","data":{
                          title: _vm.$labels[_vm.settings.sendLabel] || _vm.$labels.send,
                          fn: () => { },
                        }}})],1)],1)],1)],1)],1)],2)],1)]}}])})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
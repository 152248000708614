<template>
  <Flex
    class="menu-user"
    tag="nav"
  >
    <Flex
      class="categories"
      align="center"
    >
      <Spacer
        x="s"
        top="1"
      >
        <Cta
          :data="{
            title: $labels.account_details,
            url: {name: 'EditAccount'},
          }"
        />
      </Spacer>
      <Spacer
        x="s"
        top="1"
      >
        <Cta
          :data="{
            title: $labels.orders,
            url: {name: 'Orders'},
          }"
        />
      </Spacer>
      <Spacer
        x="s"
        top="1"
      >
        <Cta
          v-if="areSubscriptionsActive"
          :data="{
            title: $labels.subscriptions,
            url: {name: 'Subscriptions'},
          }"
        />
      </Spacer>
      <Spacer
        x="s"
        top="1"
      >
        <Cta
          :data="{
            title: $labels.logout,
            url: {name: 'Account'},
            fn: logout
          }"
        />
      </Spacer>
    </Flex>
  </Flex>
</template>

<script>
import Cta from '@/components/typo/cta';
// eslint-disable-next-line no-undef
const { woocommerce_subscriptions } = __VUE_WORDPRESS__.state.wcSettings;

export default {
  name: 'MenuUser',
  components: {
    Cta,
  },
  computed: {
    areSubscriptionsActive() {
      return woocommerce_subscriptions;
    },
  },
  mounted() {

  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-user {
  position: fixed;
  top: 100%;
  width: 100%;
  left: 0;
  top: var(--bar-height);
  height: var(--bar-height);
  background: var(--grey-xl);
  z-index: 15;

  transition: all 0.4s $ease-custom;

  .header-scrolling & {
    top: 0;
  }

  ::v-deep .router-link-exact-active {
    color: var(--grey);
  }
}
</style>

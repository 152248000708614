<template>
  <div
    :class="['share', active ? 'share-active' : false, data.theme ? `share--${data.theme}` : false]"
  >
    <Cta
      v-show="!active"
      :data="{
        title: 'Share',
        fn: openShare
      }"
    />
    <Flex
      v-show="active"
      class="list"
      tag="ul"
      column-gap="m"
    >
      <li>
        <a
          class="typo--label"
          :href="`https://facebook.com/dialog/share?display=popup&href=${encodeURIComponent(permalink)}`"
          target="_blank"
        >Facebook</a>
      </li>
      <li>
        <a
          class="typo--label"
          :href="`http://pinterest.com/pin/create/link/?url=${encodeURIComponent(permalink)}&description=${data.title}&media=${data.media}`"
          target="_blank"
        >Pinterest</a>
      </li>
      <li>
        <a
          class="typo--label"
          :href="`https://twitter.com/intent/tweet?url=${encodeURIComponent(permalink)}&text=${encodeURIComponent(data.title)}&via=MousseMagazine`"
          target="_blank"
        >Twitter</a>
      </li>
      <li>
        <a
          class="typo--label"
          :href="`mailto:?subject=Mousse Magazine — ${data.title}&body=${permalink}`"
          target="_blank"
        >Mail</a>
      </li>
      <li>
        <button
          class="typo--label"
          @click="clipboard"
        >
          {{ copy }}
        </button>
      </li>
    </Flex>
  </div>
</template>

<script>
import Cta from '@/components/typo/cta';

export default {
  name: 'Share',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      copy: 'Copy link',
      timeout: null,
    };
  },
  computed: {
    permalink() {
      return window.location.href;
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    openShare() {
      if (navigator.share && this.$mq.isMobile) {
        navigator.share({
          title: this.title,
          url: this.permalink,
        });
        this.setActive(false);
      } else {
        this.setActive(true);
      }
    },
    setActive(val) {
      this.active = val;
    },
    close(e) {
      if (e.target !== this.$el && !this.$el.contains(e.target)) {
        this.active = false;
      }
    },
    clipboard() {
      navigator.clipboard.writeText(this.permalink).then(() => {
        this.copy = 'Link copied';
        if (this.timeout) clearInterval(this.timeout);
        this.timeout = setTimeout(() => {
          this.copy = 'Copy link';
        }, 1200);
      }, (err) => {
        console.error('Async: Could not copy text: ', err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.share {
  &--post {
    &.share-active {
      position: absolute;
      left: 0;
      width: calc(100% - var(--spacer-s));
      text-align: right;
      background: var(--white);
      z-index: 2;
      pointer-events: none;
    }
  }

  .list {
    display: inline-flex;
    pointer-events: auto;
  }
}
</style>

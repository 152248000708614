<template>
  <transition name="fade">
    <Flex
      v-show="show"
      :class="['modal', `modal-theme--${theme}`, 'overlay']"
      align="center"
      :tabindex="show ? 0 : -1"
      @click.native="close"
    >
      <Wrapper
        v-if="data"
        :class="['modal-wrapper']"
        :size="data.type === 'dialog' ? 'xs' : 'm'"
        @click.native.stop="() => {}"
      >
        <Icon
          v-if="!data.mandatory"
          name="close"
          :fn="close"
          theme="minimal"
          :size="data.type === 'dialog' ? 'xs' : 'm'"
          class="icon--close"
        />

        <template v-if="data.type === 'video'">
          <Video
            ref="video"
            :data="{
              embed: true,
              url: data.url,
            }"
          />
        </template>

        <template v-if="data.type === 'content'">
          <Spacer all="s">
            <Content
              :data="{
                title: data.content.title,
                rich_text: data.content.richtext,
                cta: data.content.cta,
              }"
            />
          </Spacer>
        </template>

        <template v-if="data.type === 'dialog'">
          <Spacer all="s">
            <Grid col="12">
              <Spacer
                bottom="m"
                class="t-center"
              >
                <Title
                  :data="{
                    value: data.content.title,
                  }"
                />
                <Richtext
                  :data="{
                    value: data.content.richtext,
                  }"
                />
              </Spacer>
              <Flex justify="center">
                <Cta
                  :data="{
                    fn: dialogFn,
                    title: data.content.cta.title,
                  }"
                  theme="button"
                />
              </Flex>
            </Grid>
          </Spacer>
        </template>

        <template v-if="data.type === 'banner'">
          <Flex
            align="center"
            class="banner-wrapper"
          >
            <Adv
              :template="data.id"
              @click.native="close"
            />
          </Flex>
        </template>
      </Wrapper>
    </Flex>
  </transition>
</template>

<script>
import Icon from '@/components/ui/icon';
import Video from '@/components/media/video';
import Content from '@/components/blocks/content';
import Cta from '@/components/typo/cta';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Adv from '@/components/blocks/custom/adv';

export default {
  name: 'Modal',
  components: {
    Video,
    Content,
    Icon,
    Cta,
    Title,
    Richtext,
    Adv,
  },
  data() {
    return {
      show: false,
      data: null,
      force: true,
    };
  },
  computed: {
    theme() {
      let theme = 'default';
      if (this.data && this.data.theme) theme = this.data.theme;

      return theme;
    },
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add('overflow-hidden');
        document.body.addEventListener('keydown', this.close);
      } else {
        document.body.classList.remove('overflow-hidden');
        document.body.removeEventListener('keydown', this.close);
      }
    },
  },
  mounted() {
    this.$bus.$on('modal', this.init);
    this.$bus.$on('openModal', this.open);
    this.$bus.$on('populateModal', this.content);
    this.$bus.$on('removeContentModal', this.remove);
  },
  beforeDestroy() {
    this.$bus.$off('modal', this.init);
    this.$bus.$off('openModal', this.open);
    this.$bus.$off('populateModal', this.content);
    this.$bus.$off('removeContentModal', this.remove);
  },
  methods: {
    init(data) {
      this.data = data;
      this.show = true;
    },
    content(data) {
      this.data = data;
      this.force = false;
    },
    open(id) {
      if (id === this.data.id) {
        this.show = true;
      }
    },
    close(e) {
      if (e.keyCode === 27 || e.type === 'click') {
        if (this.data.type === 'video' && this.$refs.video) {
          this.$refs.video.plyr.destroy();
        }

        if (this.data.id === 'popup') {
          // Cookies.set('mousse-banner', '1'); Lasciare sempre visibile il banner
        }

        this.show = false;

        if (this.force) this.data = null;
      }
    },
    remove(id) {
      if (id && this.data) {
        if (this.data.id === id) {
          this.show = false;
          this.data = null;
        }

        if (id === 'popup') {
          // Cookies.set('mousse-banner', '1'); Lasciare sempre visibile il banner
        }
      }
    },
    dialogFn() {
      this.data.fn();
      this.remove(this.data.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;

  &-theme--default {
    .modal-wrapper {
      max-height: calc(var(--vh, 1vh) * 80);
      background: var(--white);
      border-radius: var(--border-radius);
    }
  }

  &-theme--banner {
    .modal-wrapper {
      padding: 0;

      .icon--close {
        top: 0;
        right: 0;
        mix-blend-mode: difference;
        color: var(--white);
      }

      .banner-wrapper {
        height: 100%;
      }
    }
  }

  &-theme--dialog {
    .modal-wrapper {
      background: var(--grey-xxl);
    }
  }
}
</style>

import Vue from 'vue';

export default {
  ADD_ITEM(state, {
    type, item, lang, fromArchive = false, category,
  }) {
    if (category && item && type && state.categories[category][lang]) {
      Vue.set(state.categories[category][lang], item.slug, item);
    } else if (item && type && state[type][lang]) {
      Vue.set(state[type][lang], item.slug, {
        ...item,
        fromArchive,
      });
    }
  },
  ADD_REQUEST(state, { type, request }) {
    state[type].requests.push(request);
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      state.pages[lang] = {};

      Object.keys(state.postTypes).forEach((key) => {
        const restBase = state.postTypes[key].rest_base;
        state[restBase][lang] = {};
      });
    });
  },
  SET_AJAX_LOADING(state, value) {
    if (value) {
      document.querySelector('html').classList.add('wp-ajax-loading');
    } else {
      document.querySelector('html').classList.remove('wp-ajax-loading');
    }
    state.ajaxLoading = value;
  },
};

export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_PAGE(state, value) {
    state.currentPage = value;
  },
  SET_CURRENT_SINGLE(state, value) {
    state.currentSingle = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  SET_OVERLAY(state, value) {
    state.overlay = value;
  },
  TOGGLE_MENU(state) {
    state.menu = !state.menu;
  },
  SET_MENU(state, value) {
    state.menu = value;
  },
  TOGGLE_SEARCH(state) {
    state.search = !state.search;
  },
  SET_SEARCH(state, value) {
    state.search = value;
  },
  SET_GO_BACK(state, value) {
    state.goBack = value;
  },
};

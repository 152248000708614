var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('section',{staticClass:"filters typo--label-xs"},[(_vm.request.type === 'post')?[_c('Flex',{attrs:{"align":"center"}},[(_vm.$mq.isMobile)?_c('Icon',{attrs:{"name":!_vm.filterBar ? 'filter' : 'close',"fn":_vm.toggleFilterBar}}):_vm._e(),_c('Flex',{staticClass:"post-type",attrs:{"tag":"ul"}},[_c('Spacer',{attrs:{"tag":"li","x":"s","top":"1"}},[_c('Cta',{class:['subtype', !_vm.request.subtype ? 'subtype-active' : false],attrs:{"data":{
                title: 'All',
                fn: () => {
                  _vm.setSubType('all');
                },
              }}})],1),_c('Spacer',{attrs:{"tag":"li","x":"s","top":"1"}},[_c('Cta',{class:['subtype', _vm.request.subtype === 'post' ? 'subtype-active' : false],attrs:{"data":{
                title: 'Magazine',
                fn: () => {
                  _vm.setSubType('post');
                },
              }}})],1),_c('Spacer',{attrs:{"tag":"li","x":"s","top":"1"}},[_c('Cta',{class:['subtype', _vm.request.subtype === 'publishing' ? 'subtype-active' : false],attrs:{"data":{
                title: 'Publishing',
                fn: () => {
                  _vm.setSubType('publishing');
                },
              }}})],1),_c('Spacer',{attrs:{"tag":"li","x":"s","top":"1"}},[_c('Cta',{class:['subtype', _vm.request.subtype === 'product' ? 'subtype-active' : false],attrs:{"data":{
                title: 'Shop',
                fn: () => {
                  _vm.setSubType('product');
                },
              }}})],1)],1),_c('Flex',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$mq.isMobile || _vm.filterBar),expression:"!$mq.isMobile || filterBar"}],staticClass:"filter tags",attrs:{"tag":"ul","column-gap":"s"}},[_vm._l((_vm.tagsFiltered),function(tag){return _c('li',{key:tag.id,class:[
              'filter-btn',
              'tag',
              _vm.tagsSelected.includes(tag.id.toString()) ? 'filter-btn-active' : false,
            ],attrs:{"data-tag":tag.id},on:{"click":_vm.setTags}},[_c('div',{staticClass:"close"},[(_vm.tagsSelected.includes(tag.id.toString()))?_c('inline-svg',{attrs:{"src":require(`@/assets/svg/icons/close-filter.svg`)}}):_vm._e()],1),_vm._v(" "+_vm._s(tag.name)+" ")])}),_c('li',{staticClass:"filter-tags-offset"},[_c('div',{staticClass:"filter-btn filter-btn-mini filter-btn-active",on:{"click":_vm.setTagsOffset}},[_vm._v(" + ")])])],2)],1)]:_vm._e(),(_vm.request.type === 'media')?[_c('Flex',{attrs:{"align":"center"}},[_c('Flex',{staticClass:"filter color"},[_c('div',{staticClass:"label"},[_vm._v(" Color ")]),_c('Flex',{staticClass:"color-list",attrs:{"tag":"ul","column-gap":"s"}},_vm._l((_vm.colors),function(color){return _c('li',{key:color,class:[_vm.request.colour === color ? 'color-active' : false],attrs:{"data-color":color},on:{"click":_vm.setColor}},[(_vm.request.colour === color)?_c('inline-svg',{attrs:{"src":require(`@/assets/svg/icons/close-filter.svg`)}}):_vm._e()],1)}),0)],1),_c('div',{staticClass:"delim u-hidden--until-m"}),(!_vm.$mq.isMobile)?_c('Flex',{staticClass:"filter size",attrs:{"align":"center"}},[_c('div',{staticClass:"label"},[_vm._v(" Grid size ")]),_c('Flex',{attrs:{"tag":"ul","column-gap":"s"}},[_c('li',{class:[
                'filter-btn',
                'filter-btn-mini',
                _vm.colSize === 2 ? 'filter-btn-active' : false,
              ],on:{"click":() => _vm.changeColSize('s')}},[_vm._v(" S ")]),_c('li',{class:[
                'filter-btn',
                'filter-btn-mini',
                _vm.colSize === 3 ? 'filter-btn-active' : false,
              ],on:{"click":() => _vm.changeColSize('m')}},[_vm._v(" M ")]),_c('li',{class:[
                'filter-btn',
                'filter-btn-mini',
                _vm.colSize === 6 ? 'filter-btn-active' : false,
              ],on:{"click":() => _vm.changeColSize('l')}},[_vm._v(" L ")])])],1):_vm._e()],1)]:_vm._e()],2),_c('section',{ref:"results",staticClass:"results typo--s-alt"},[_c('Spacer',[(_vm.results.length > 0)?_c('Grid',{attrs:{"col":{
          default: _vm.request.type === 'post' ? 12 : 6,
          m: _vm.col,
        },"row-gap":"xs"}},[(_vm.request.type === 'post')?[(!_vm.$mq.isMobile)?_c('Spacer',{staticClass:"results-header typo--xxs",attrs:{"x":"s"}},[_c('Grid',[_c('Column',{attrs:{"size":"6"}},[_vm._v(" Title ")]),_c('Column',{attrs:{"size":"2","offset":"7"}},[_c('div'),_c('Cta',{class:['order', `order-${_vm.order}`],attrs:{"data":{
                    title: 'Type',
                    fn: () => _vm.setOrderBy('type'),
                  }}})],1),_c('Column',{attrs:{"size":"2","offset":"9"}},[_c('div'),_c('Cta',{class:['order', `order-${_vm.order}`],attrs:{"data":{
                    title: 'Category',
                    fn: () => _vm.setOrderBy('category'),
                  }}})],1),_c('Column',{attrs:{"size":"1","offset":"11"}},[_c('Cta',{class:['order', `order-${_vm.order}`],attrs:{"data":{
                    title: 'Year',
                    fn: () => _vm.setOrderBy('year'),
                  }}})],1)],1)],1):_vm._e(),_vm._l((_vm.orderedResults),function(item){return _c('router-link',{key:item.id,staticClass:"item item--post",attrs:{"to":_vm.$relativeUrl(item.url)}},[_c('Grid',[_c('Column',{attrs:{"size":{ default: 12, m: 6 }}},[_c('Grid',[_c('Column',{staticClass:"u-hidden--from-m typo--label-xs",attrs:{"size":"4"}},[_vm._v(" Title ")]),_c('Column',{staticClass:"title",attrs:{"size":{ default: 8, m: 12 }}},[_c('div',{domProps:{"innerHTML":_vm._s(item.title)}})])],1)],1),_c('Column',{attrs:{"size":{ default: 12, m: 2 },"offset":{ default: 0, m: 7 }}},[_c('Grid',[_c('Column',{staticClass:"u-hidden--from-m typo--label-xs",attrs:{"size":"4"}},[_vm._v(" Type ")]),_c('Column',{attrs:{"size":{ default: 8, m: 12 }}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.postTypesLabel(item.subtype))}})])],1)],1),_c('Column',{attrs:{"size":{ default: 12, m: 2 },"offset":{ default: 0, m: 9 }}},[_c('Grid',[_c('Column',{staticClass:"u-hidden--from-m typo--label-xs",attrs:{"size":"4"}},[_vm._v(" Category ")]),_c('Column',{attrs:{"size":{ default: 8, m: 12 }}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.categories(item.gds_taxonomies, item.subtype))}})])],1)],1),_c('Column',{attrs:{"size":{ default: 12, m: 1 },"offset":{ default: 0, m: 11 }}},[_c('Grid',[_c('Column',{staticClass:"u-hidden--from-m typo--label-xs",attrs:{"size":"4"}},[_vm._v(" Year ")]),_c('Column',{attrs:{"size":{ default: 8, m: 12 }}},[_vm._v(" "+_vm._s(_vm.year(item.date_format))+" ")])],1)],1)],1)],1)})]:_vm._e(),(_vm.request.type === 'media')?_vm._l((_vm.results),function(item){return _c(item._links.post && item._links.post[0] ? 'router-link' : 'div',{key:item.id,tag:"component",staticClass:"item item--media",attrs:{"to":item._links.post && item._links.post[0]
                ? _vm.$relativeUrl(item._links.post[0].href)
                : false}},[_c('Figure',{ref:"figure",refInFor:true,attrs:{"data":{
                default: item.media,
                object_fit: 'contain',
                ratio: true,
                lazyload: true,
                caption: item._links.post[0].title,
                showCaption: false,
              }}})],1)}):_vm._e()],2):(_vm.results.length === 0 && !_vm.found)?_c('Spacer',{attrs:{"all":"s"}},[_vm._v(" "+_vm._s(_vm.emptyState)+" ")]):_c('Spacer',{attrs:{"all":"s"}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])],1),_c('div',{staticClass:"infinite"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <component
      :is="setBlock(block.acf_fc_layout)"
      v-for="(block, index) in data"
      :key="index"
      :data="block"
    />
  </div>
</template>

<script>
import Newsletter from '@/components/blocks/newsletter';
import GoBack from '@/components/ui/go-back';
import GoTop from '@/components/ui/go-top';
import Block from '@/components/blocks/block';

export default {
  name: 'OverlayFooter',
  props: {
    data: {
      type: [Array, Boolean],
      required: true,
    },
  },
  methods: {
    setBlock(layout) {
      switch (layout) {
      case 'newsletter': {
        return Newsletter;
      }
      case 'subscribe': {
        // return Subscribe;
        return false;
      }
      case 'go_back': {
        return GoBack;
      }
      case 'back_to_top': {
        return GoTop;
      }
      case 'custom': {
        return Block;
      }

      default: {
        return Block;
      }
      }
    },
  },
};
</script>

<style></style>

<template>
  <component
    :is="data.cta.fn ? 'div' : 'router-link'"
    :to="data.cta.url ? $relativeUrl(data.cta.url) : false"
    :class="['block', data.theme ? `block--${data.theme}` : false]"
    :style="{
      '--bg': data.background,
      '--color': data.color,
    }"
    @click="data.cta.fn ? data.cta.fn() : false"
  >
    <Spacer
      x="s"
      y="xs"
    >
      <Grid :col="{ default: 'auto', m: 6 }">
        <Title
          v-if="data.title"
          :data="{
            value: data.title,
          }"
        />
        <Flex
          class="cta"
          align="center"
        >
          <Title
            v-if="!$mq.isMobile || data.theme === 'go-back' || data.theme === 'go-top'"
            :data="{
              value: data.cta.title,
            }"
          />
          <Icon
            :name="data.icon ? data.icon : 'arrow-right'"
            size="l"
          />
        </Flex>
      </Grid>
    </Spacer>
  </component>
</template>

<script>
import Title from '@/components/typo/title';
import Icon from '@/components/ui/icon';

export default {
  name: 'Block',
  components: {
    Title,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  height: var(--block-height);
  display: block;

  @include mq($and: $hover) {
    &:hover {
      color: var(--bg, var(--grey-xxd));
      background: var(--color, var(--white));
    }
  }

  & > * {
    display: block;
    height: 100%;
  }

  background: var(--bg, var(--grey-xxd));
  color: var(--color, var(--white));

  ::v-deep .grid {
    justify-content: space-between;
  }

  &--go-top {
    cursor: pointer;
  }

  &--go-back {
    & > * {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-end;
    }

    ::v-deep .icon {
      order: -1;
    }
  }
}
</style>

<template>
  <Spacer
    v-if="page || single || parent"
    x="s"
    tag="aside"
    top="1"
    :class="[
      'breadcrumbs',
      $route.name === 'Index' || $route.name === 'Search' || $route.name === 'Checkout'
        ? 'breadcrumbs--hide'
        : false,
    ]"
  >
    <Flex
      align="center"
      nowrap
      class="overflow-scroll"
    >
      <Cta
        v-if="parent"
        :data="{
          title: parent.title,
          url: parent.url,
          size: 'label-s',
        }"
      />
      <Spacer
        v-if="page && parent"
        x="xs"
        class="typo--label-s"
      >
        >
      </Spacer>
      <Cta
        v-if="page && overlay"
        :data="{
          title: page.title,
          url: page.url,
          size: 'label-s',
        }"
      />
      <Label
        v-if="page && !overlay"
        :data="{
          value: page.title,
          size: 'label-s',
        }"
      />
      <Spacer
        v-if="overlay && single"
        x="xs"
        class="typo--label-s"
      >
        >
      </Spacer>
      <Label
        v-if="overlay && single"
        :data="{
          value: single.title,
          size: 'label-s',
        }"
      />
    </Flex>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Label from '@/components/typo/label';

export default {
  name: 'Breadcrumbs',
  components: {
    Cta,
    Label,
  },
  data() {
    return {
      page: null,
      single: null,
      parent: null,
    };
  },
  computed: {
    ...mapGetters(['currentPage', 'currentSingle', 'overlay']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        let parent = null;
        if (val.path.startsWith('/magazine')) {
          parent = {
            title: 'Magazine',
            url: '/magazine',
          };
        } else if (val.path.startsWith('/publishing')) {
          parent = {
            title: 'Publishing',
            url: '/publishing',
          };
        } else if (val.path.startsWith('/shop')) {
          parent = {
            title: 'Shop',
            url: '/shop',
          };
        }
        this.parent = parent;

        if (val.name === 'Index') {
          this.page = null;
          this.single = null;
        }
      },
    },
    currentPage: {
      immediate: true,
      handler(val) {
        if (val) {
          if (
            val.slug !== 'index'
            && val.slug !== 'shop'
            && val.slug !== 'magazine'
            && val.slug !== 'publishing'
          ) {
            this.page = {
              title: val.title ? val.title.rendered : val.name,
              url: val.link,
            };
          } else {
            this.page = null;
          }
        }
      },
    },
    currentSingle(val) {
      if (val) {
        this.single = {
          title: val.title ? val.title.rendered : val.name,
        };

        if (!this.page) {
          this.setCategory();
        }
      } else {
        this.single = null;
        this.page = null;
      }
    },
  },
  methods: {
    async setCategory() {
      const type = this.currentSingle.type === 'post'
        ? 'magazine'
        : this.currentSingle.type === 'publishing'
          ? 'publishing'
          : 'shop';
      const cat = this.currentSingle.type === 'post'
        ? 'categories'
        : this.currentSingle.type === 'publishing'
          ? 'category-publishing'
          : 'categories';
      const slug = type !== 'shop'
        ? this.currentSingle.gds_taxonomies[cat][0].slug
        : this.currentSingle.categories[0].slug;
      let page = this.$store.state.wp.categories[type].default[slug];
      if (!page) {
        page = await this.$store.dispatch('getSingleBySlug', {
          type: cat,
          slug,
          lang: 'default',
          category: type,
        });
      }
      if (page) {
        this.page = {
          title: page.name,
          url: page.link,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  position: sticky;
  top: var(--bar-height);
  height: var(--bar-height);
  background: var(--grey-xl);
  z-index: 15;
  overflow: hidden;
  opacity: 1;

  .overflow-scroll {
    @include mq($until: m) {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  & > * {
    height: var(--bar-height);
    white-space: nowrap;
  }

  .router-link-active {
    color: var(--grey);
  }

  transition: all 0.4s $ease-custom;

  .header-scrolling & {
    top: 0;
  }

  &.breadcrumbs--hide {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    margin-top: calc(var(--bar-height) * -1);
  }
}
</style>

<template>
  <Spacer
    y="s"
  >
    <Wrapper
      size="xs"
      :margin="false"
    >
      <div>
        <Grid
          col="12"
          row-gap="m"
        >
          <Spacer
            class="typo--m"
            x="s"
          >
            {{ $labels.create_accunt }}
          </Spacer>
          <Form
            id="register"
            class="form--single"
          />
        </Grid>
      </div>
    </Wrapper>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '@/components/form';

export default {
  name: 'Register',
  components: {
    Form,
  },
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    if (this.user) {
      this.$router.push({ name: 'Account' });
    }
  },
};
</script>

<style lang="scss" scoped>
.form--single {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>

<template>
  <main>
    <section class="filters typo--label-xs">
      <template v-if="request.type === 'post'">
        <Flex align="center">
          <Icon
            v-if="$mq.isMobile"
            :name="!filterBar ? 'filter' : 'close'"
            :fn="toggleFilterBar"
          />
          <Flex
            tag="ul"
            class="post-type"
          >
            <Spacer
              tag="li"
              x="s"
              top="1"
            >
              <Cta
                :data="{
                  title: 'All',
                  fn: () => {
                    setSubType('all');
                  },
                }"
                :class="['subtype', !request.subtype ? 'subtype-active' : false]"
              />
            </Spacer>
            <Spacer
              tag="li"
              x="s"
              top="1"
            >
              <Cta
                :data="{
                  title: 'Magazine',
                  fn: () => {
                    setSubType('post');
                  },
                }"
                :class="['subtype', request.subtype === 'post' ? 'subtype-active' : false]"
              />
            </Spacer>
            <Spacer
              tag="li"
              x="s"
              top="1"
            >
              <Cta
                :data="{
                  title: 'Publishing',
                  fn: () => {
                    setSubType('publishing');
                  },
                }"
                :class="['subtype', request.subtype === 'publishing' ? 'subtype-active' : false]"
              />
            </Spacer>
            <Spacer
              tag="li"
              x="s"
              top="1"
            >
              <Cta
                :data="{
                  title: 'Shop',
                  fn: () => {
                    setSubType('product');
                  },
                }"
                :class="['subtype', request.subtype === 'product' ? 'subtype-active' : false]"
              />
            </Spacer>
          </Flex>
          <Flex
            v-show="!$mq.isMobile || filterBar"
            tag="ul"
            column-gap="s"
            class="filter tags"
          >
            <li
              v-for="tag in tagsFiltered"
              :key="tag.id"
              :data-tag="tag.id"
              :class="[
                'filter-btn',
                'tag',
                tagsSelected.includes(tag.id.toString()) ? 'filter-btn-active' : false,
              ]"
              @click="setTags"
            >
              <div class="close">
                <inline-svg
                  v-if="tagsSelected.includes(tag.id.toString())"
                  :src="require(`@/assets/svg/icons/close-filter.svg`)"
                />
              </div>
              {{ tag.name }}
            </li>
            <li class="filter-tags-offset">
              <div
                class="filter-btn filter-btn-mini filter-btn-active"
                @click="setTagsOffset"
              >
                +
              </div>
            </li>
          </Flex>
        </Flex>
      </template>
      <template v-if="request.type === 'media'">
        <Flex align="center">
          <Flex class="filter color">
            <div class="label">
              Color
            </div>
            <Flex
              tag="ul"
              column-gap="s"
              class="color-list"
            >
              <li
                v-for="color in colors"
                :key="color"
                :data-color="color"
                :class="[request.colour === color ? 'color-active' : false]"
                @click="setColor"
              >
                <inline-svg
                  v-if="request.colour === color"
                  :src="require(`@/assets/svg/icons/close-filter.svg`)"
                />
              </li>
            </Flex>
          </Flex>
          <div class="delim u-hidden--until-m" />
          <Flex
            v-if="!$mq.isMobile"
            class="filter size"
            align="center"
          >
            <div class="label">
              Grid size
            </div>
            <Flex
              tag="ul"
              column-gap="s"
            >
              <li
                :class="[
                  'filter-btn',
                  'filter-btn-mini',
                  colSize === 2 ? 'filter-btn-active' : false,
                ]"
                @click="() => changeColSize('s')"
              >
                S
              </li>
              <li
                :class="[
                  'filter-btn',
                  'filter-btn-mini',
                  colSize === 3 ? 'filter-btn-active' : false,
                ]"
                @click="() => changeColSize('m')"
              >
                M
              </li>
              <li
                :class="[
                  'filter-btn',
                  'filter-btn-mini',
                  colSize === 6 ? 'filter-btn-active' : false,
                ]"
                @click="() => changeColSize('l')"
              >
                L
              </li>
            </Flex>
          </Flex>
        </Flex>
      </template>
    </section>
    <section
      ref="results"
      class="results typo--s-alt"
    >
      <Spacer>
        <Grid
          v-if="results.length > 0"
          :col="{
            default: request.type === 'post' ? 12 : 6,
            m: col,
          }"
          row-gap="xs"
        >
          <template v-if="request.type === 'post'">
            <Spacer
              v-if="!$mq.isMobile"
              class="results-header typo--xxs"
              x="s"
            >
              <Grid>
                <Column size="6">
                  Title
                </Column>
                <Column
                  size="2"
                  offset="7"
                >
                  <div />
                  <Cta
                    :data="{
                      title: 'Type',
                      fn: () => setOrderBy('type'),
                    }"
                    :class="['order', `order-${order}`]"
                  />
                </Column>
                <Column
                  size="2"
                  offset="9"
                >
                  <div />
                  <Cta
                    :data="{
                      title: 'Category',
                      fn: () => setOrderBy('category'),
                    }"
                    :class="['order', `order-${order}`]"
                  />
                </Column>
                <Column
                  size="1"
                  offset="11"
                >
                  <Cta
                    :data="{
                      title: 'Year',
                      fn: () => setOrderBy('year'),
                    }"
                    :class="['order', `order-${order}`]"
                  />
                </Column>
                <!-- <Column
                  size="1"
                  offset="12"
                >
                  <Cta
                    :data="{
                      title: 'Misc',
                      fn: () => setOrderBy('misc'),
                    }"
                    :class="['order', `order-${order}`]"
                  />
                </Column> -->
              </Grid>
            </Spacer>
            <router-link
              v-for="item in orderedResults"
              :key="item.id"
              class="item item--post"
              :to="$relativeUrl(item.url)"
            >
              <Grid>
                <Column :size="{ default: 12, m: 6 }">
                  <Grid>
                    <Column
                      size="4"
                      class="u-hidden--from-m typo--label-xs"
                    >
                      Title
                    </Column>
                    <Column
                      class="title"
                      :size="{ default: 8, m: 12 }"
                    >
                      <div v-html="item.title" />
                    </Column>
                  </Grid>
                </Column>
                <Column
                  :size="{ default: 12, m: 2 }"
                  :offset="{ default: 0, m: 7 }"
                >
                  <Grid>
                    <Column
                      size="4"
                      class="u-hidden--from-m typo--label-xs"
                    >
                      Type
                    </Column>
                    <Column :size="{ default: 8, m: 12 }">
                      <div v-html="postTypesLabel(item.subtype)" />
                    </Column>
                  </Grid>
                </Column>
                <Column
                  :size="{ default: 12, m: 2 }"
                  :offset="{ default: 0, m: 9 }"
                >
                  <Grid>
                    <Column
                      size="4"
                      class="u-hidden--from-m typo--label-xs"
                    >
                      Category
                    </Column>
                    <Column :size="{ default: 8, m: 12 }">
                      <div v-html="categories(item.gds_taxonomies, item.subtype)" />
                    </Column>
                  </Grid>
                </Column>
                <Column
                  :size="{ default: 12, m: 1 }"
                  :offset="{ default: 0, m: 11 }"
                >
                  <Grid>
                    <Column
                      size="4"
                      class="u-hidden--from-m typo--label-xs"
                    >
                      Year
                    </Column>
                    <Column :size="{ default: 8, m: 12 }">
                      {{ year(item.date_format) }}
                    </Column>
                  </Grid>
                </Column>
                <!-- <Column
                  :size="{ default: 12, m: 1 }"
                  :offset="{ default: 0, m: 12 }"
                >
                  <Grid>
                    <Column
                      size="4"
                      class="u-hidden--from-m typo--label-xs"
                    >
                      Misc
                    </Column>
                    <Column :size="{ default: 8, m: 12 }">
                      /
                    </Column>
                  </Grid>
                </Column> -->
              </Grid>
            </router-link>
          </template>
          <template v-if="request.type === 'media'">
            <component
              :is="item._links.post && item._links.post[0] ? 'router-link' : 'div'"
              v-for="item in results"
              :key="item.id"
              class="item item--media"
              :to="
                item._links.post && item._links.post[0]
                  ? $relativeUrl(item._links.post[0].href)
                  : false
              "
            >
              <Figure
                ref="figure"
                :data="{
                  default: item.media,
                  object_fit: 'contain',
                  ratio: true,
                  lazyload: true,
                  caption: item._links.post[0].title,
                  showCaption: false,
                }"
              />
            </component>
          </template>
        </Grid>
        <Spacer
          v-else-if="results.length === 0 && !found"
          all="s"
        >
          {{ emptyState }}
        </Spacer>
        <Spacer
          v-else
          all="s"
        >
          {{ placeholder }}
        </Spacer>
      </Spacer>

      <div class="infinite" />
    </section>
  </main>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import orderBy from 'lodash.orderby';

import medusa from '@/assets/js/observer';

import Figure from '@/components/media/figure';
import Icon from '@/components/ui/icon';
import Cta from '@/components/typo/cta';

export default {
  name: 'Search',
  components: {
    Figure,
    Cta,
    Icon,
  },
  data() {
    return {
      results: [],
      found: true,
      placeholder: '',
      emptyState: 'Found nothing',
      colSize: 2,
      col: 12,
      colors: ['red', 'yellow', 'green', 'light-blue', 'blue', 'purple', 'white', 'black', 'grey'],
      request: {},
      tagsSelected: [],
      tagsFiltered: [],
      tagsOffset: 8,
      tagsPages: 1,
      page: 1,
      prevPage: 0,
      orderBy: null,
      order: 'asc',
      stopScroll: false,
      prevRequest: null,
      cancelToken: null,
      filterBar: false,
    };
  },
  computed: {
    ...mapGetters(['tags', 'search', 'ajaxLoading', 'postTypesLabel']),
    orderedResults() {
      return this.orderBy ? orderBy(this.results, this.fnOrderBy, this.order) : this.results;
    },
  },
  watch: {
    $route(to, old) {
      if (to.name === 'Search' && !old.meta.overlay) {
        this.results = [];
        this.prevPage = 0;
        this.page = 1;
        this.order = 'asc';
        this.orderBy = null;
        this.request = to.query;
        this.stopScroll = false;

        if (to.query.type && to.query.type !== old.query.type) {
          this.setColSize();
          this.setSubType('all');
          this.setTags(null);
          if (
            to.query.type === 'post'
            && old.query.type === 'media'
            && this.tagsFiltered.length === 0
          ) {
            this.setTagsFiltered();
          }
          this.setRequest();
        }
        if (to.query.subtype !== old.query.subtype) {
          this.setRequest();
        }
        if (to.query.colour !== old.query.colour) {
          this.setRequest();
        }
        if (to.query.tags !== old.query.tags) {
          this.setRequest();
        }
        if (to.query.s !== old.query.s) {
          this.setRequest();
        }
      } else if (old.name === 'Search' && to.meta.overlay) {
        this.$store.commit('SET_SEARCH', false);
        this.request = old.query;
      } else if (to.name === 'Search' && old.meta.overlay && !this.search) {
        this.$store.commit('SET_SEARCH', true);
      }
    },
  },
  created() {
    if (!this.$route.query.type) {
      this.$router.replace({ query: { ...this.$route.query, type: 'post' } });
    }

    const { tags } = this.$route.query;

    if (tags) {
      if (Array.isArray(tags)) {
        this.tagsSelected = tags;
      } else {
        this.tagsSelected.push(tags);
      }
    }
  },
  mounted() {
    this.$store.commit('SET_SEARCH', true);
    this.request = this.$route.query;
    this.setColSize();
    this.setRequest();
    this.setTagsFiltered();
    this.setInfiniteScroll();
  },
  beforeDestroy() {
    this.$store.commit('SET_SEARCH', false);

    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll-search')) {
      medusa.ref.removeTarget('infinite-scroll-search');
    }
  },
  methods: {
    async setRequest() {
      const params = {
        search: this.request.s,
        type: this.request.type,
        subtype: this.request.subtype,
        colour: this.request.colour,
        tags: this.request.tags,
        per_page: this.request.type === 'media' ? 24 : 50,
        page: this.page,
      };

      const parseParams = btoa(JSON.stringify(params));

      if (this.cancelToken) {
        this.cancelToken.cancel('Operation canceled due to new request.');
      }
      this.cancelToken = axios.CancelToken.source();

      if (parseParams !== this.prevRequest) {
        await this.$store
          .dispatch('getSearch', { params, cancelToken: this.cancelToken.token })
          .then((response) => {
            if (parseInt(response.headers['x-wp-totalpages'], 10) <= this.page) {
              this.stopScroll = true;
            }

            if (response.data && response.data.length > 0) {
              this.found = true;
              this.results.push(...response.data);
              // if (params.type === 'media') {
              //   this.$root.$children[0].lazyObserve(this.$el);
              // }

              this.$nextTick(() => {
                if (parseInt(response.headers['x-wp-totalpages'], 10) > 0) {
                  this.prevPage = this.page;
                  this.page += 1;
                }
                if (
                  parseInt(response.headers['x-wp-totalpages'], 10) > this.page
                  && this.$refs.results.offsetHeight <= window.innerHeight
                ) {
                  this.setRequest();
                }
              });
            } else if (this.page === 1) {
              this.found = false;
              this.results = [];
            }
          })
          .catch(() => {});
      }

      this.prevRequest = parseParams;
    },
    setSubType(subtype) {
      if (subtype !== 'all') {
        this.$router.replace({ query: { ...this.$route.query, subtype } });
      } else {
        this.$router.replace({ query: { ...this.$route.query, subtype: undefined } });
      }
    },
    setTags(event) {
      if (event) {
        const { tag } = event.target.dataset;
        if (this.tagsSelected.includes(tag)) {
          this.tagsSelected = this.tagsSelected.filter((item) => item !== tag);
        } else {
          this.tagsSelected.push(tag);
        }

        if (this.tagsSelected.length > 0) {
          this.$router.replace({ query: { ...this.$route.query, tags: this.tagsSelected } });
        } else {
          this.$router.replace({ query: { ...this.$route.query, tags: undefined } });
        }
      } else {
        this.tagsSelected = [];
        this.tagsFiltered = [];
        this.tagsPages = 1;
        this.$router.replace({ query: { ...this.$route.query, tags: undefined } });
      }
    },
    setTagsFiltered() {
      this.tagsFiltered = this.tags.filter(
        (tag, i) => i <= this.tagsOffset * this.tagsPages || this.tagsSelected.includes(tag.id.toString()),
      );
    },
    setTagsOffset() {
      this.tagsPages += 1;
      this.setTagsFiltered();
    },
    setColor(event) {
      const { color: colour } = event.target.dataset;
      if (colour !== this.$route.query.colour || !this.$route.query.colour) {
        this.$router.replace({ query: { ...this.$route.query, colour } });
      } else {
        this.$router.replace({ query: { ...this.$route.query, colour: undefined } });
      }
    },
    changeColSize(size) {
      if (size === 's') {
        this.colSize = 2;
      }
      if (size === 'm') {
        this.colSize = 3;
      }
      if (size === 'l') {
        this.colSize = 6;
      }

      this.setColSize();
    },
    setColSize() {
      if (this.$route.query.type === 'media') {
        this.col = this.colSize;

        if (this.$refs.figure) {
          this.$refs.figure.forEach((figure) => {
            figure.currentSrc();
          });
        }
      } else {
        this.col = 12;
      }
    },
    setInfiniteScroll() {
      if (medusa.ref && medusa.ref.idList.includes('infinite-scroll-search')) {
        medusa.ref.removeTarget('infinite-scroll-search');
      }

      medusa.ref.addTarget({
        id: 'infinite-scroll-search',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
        offsets: '0px 0px 0px 0px',
      });

      medusa.ref.pushToTarget('infinite-scroll-search', this.$el.querySelector('.infinite'));
    },
    setOffset(entry) {
      if (entry.isIntersecting && window.pageYOffset > 0 && !this.stopScroll) {
        this.setRequest();
      }
    },
    setOrderBy(val) {
      if (this.order === 'desc' && val === this.orderBy) {
        this.orderBy = null;
        this.order = 'asc';
      } else if (val === this.orderBy) {
        this.order = 'desc';
      } else {
        this.order = 'asc';
        this.orderBy = val;
      }
    },
    fnOrderBy(el) {
      let identity;
      if (this.orderBy === 'category') {
        identity = el.gds_taxonomies.categories ? el.gds_taxonomies.categories[0].cat_name : null;
      }
      if (this.orderBy === 'year') {
        identity = this.year(el.date_format);
      }
      return identity;
    },
    categories(array, type) {
      const cat = type === 'product'
        ? 'product_cat'
        : type === 'publishing'
          ? 'category-publishing'
          : 'categories';
      return array[cat]
        ? array[cat]
          .map((el) => (type === 'product' || type === 'publishing' ? el.name : el.cat_name))
          .join(', ')
        : null;
    },
    year(date) {
      const array = date.split('.');
      return array[array.length - 1];
    },
    toggleFilterBar() {
      this.filterBar = !this.filterBar;
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  position: sticky;
  top: calc((var(--bar-height) * 2) + 30px);
  background: var(--white);
  z-index: 5;

  @include mq(m) {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & > * {
    flex: 0 0 100%;

    @include mq(m) {
      height: var(--bar-height);
      flex: 0 0 auto;
      flex-wrap: nowrap;
    }
  }

  transition: all 0.4s $ease-custom;

  .header-scrolling & {
    top: calc((var(--bar-height)) + 30px);
  }

  .app--is-overlay & {
    position: relative;
  }

  .subtype {
    color: var(--grey);

    &-active {
      color: var(--grey-xxd);
    }
  }

  .tags,
  .post-type {
    height: 30px;
    align-items: center;

    @include mq(m) {
      height: auto;
      align-items: stretch;
      flex-wrap: nowrap;
    }

    & > * {
      flex: 0 0 auto;
    }
  }

  .tags {
    @include mq($until: m) {
      overflow-x: auto;
      flex-wrap: nowrap;
      align-items: flex-start;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .filter {
    padding: 0 var(--spacer-s);
    align-items: center;
    min-height: 30px;

    .label {
      padding-top: var(--spacer-1);
      margin-right: var(--spacer-s);
    }

    .filter-btn {
      position: relative;
      color: var(--white);
      background: var(--grey-l);
      display: inline-block;
      cursor: pointer;
      height: 22px;
      line-height: 22px;
      text-align: center;
      padding: var(--spacer-1) var(--spacer-s) 0;

      &-mini {
        flex: 0 0 22px;
        width: 22px;
        padding: 0;
      }

      &-active {
        cursor: default;
        background: var(--grey-xxd);
      }
    }

    .filter-tags-offset {
      padding-right: var(--spacer-s);

      .filter-btn {
        cursor: pointer;
        background: var(--grey-xxd);
      }
    }

    .tag {
      &.filter-btn-active {
        cursor: pointer;
        padding-left: 26px;

        .close {
          position: absolute;
          top: 50%;
          transform: translate3d(0, -50%, 0);
          left: 6px;
          width: 14px;
          height: 14px;
          background: var(--grey-d);
          pointer-events: none;
          border-radius: 2px;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
      }
    }

    .color-list {
      li {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        cursor: pointer;
        margin-right: var(--spacer-xxs);
        position: relative;
        color: var(--white);

        @include mq(m) {
          width: 12px;
          height: 12px;
        }

        &[data-color="red"] {
          background: red;
        }

        &[data-color="orange"] {
          background: orange;
        }

        &[data-color="yellow"] {
          background: yellow;
        }

        &[data-color="green"] {
          background: green;
        }

        &[data-color="light-blue"] {
          background: lightblue;
        }

        &[data-color="blue"] {
          background: blue;
        }

        &[data-color="purple"] {
          background: purple;
        }

        &[data-color="pink"] {
          background: pink;
        }

        &[data-color="white"] {
          background: white;
          border: 1px solid var(--grey-l);
        }

        &[data-color="black"] {
          background: black;
        }

        &[data-color="grey"] {
          background: grey;
        }

        &.color-active {
          box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.45);
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }

        &[data-color="orange"],
        &[data-color="yellow"],
        &[data-color="light-blue"],
        &[data-color="pink"],
        &[data-color="white"] {
          color: var(--black);
        }
      }
    }
  }
}

.results-header {
  position: sticky;
  background: var(--white);
  height: 22px;
  line-height: 22px;

  top: calc(var(--bar-height) * 4);

  transition: all 0.4s $ease-custom;

  .header-scrolling & {
    top: calc(var(--bar-height) * 3);
  }

  .app--is-overlay & {
    position: relative;
  }

  .order {
    svg {
      vertical-align: middle;
      margin-left: var(--spacer-xxxs);
      opacity: 0;
    }

    &:hover,
    &-active {
      svg {
        opacity: 1;
      }
    }

    &-active {
      &.order-desc {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.item {
  display: block;

  .title {
    @include mq($until: m) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    @include mq(m) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--post {
    padding: var(--spacer-xxs) var(--spacer-s) var(--spacer-xxxs);

    @include mq($and: $hover) {
      &:hover {
        background: var(--grey-xxl);
      }
    }

    border-bottom: 1px solid var(--grey-xxl);
    padding-bottom: var(--spacer-xxs);
    margin-bottom: var(--spacer-xxs);

    @include mq(m) {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  &--media {
    position: relative;
    line-height: 0;
    overflow: hidden;

    ::v-deep figcaption {
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      padding: var(--spacer-xs) var(--spacer-s);
      color: var(--white);
      mix-blend-mode: difference;
    }

    &:hover {
      ::v-deep figcaption {
        opacity: 1;
      }
    }
  }
}

.infinite {
  height: 1px;
}
</style>

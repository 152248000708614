var render = function render(){var _vm=this,_c=_vm._self._c;return _c('figure',{class:[
    'picture',
    !_vm.data.ratio ? 'no-ratio' : false,
    _vm.data.relative ? 'picture-relative' : false,
    _vm.data.object_fit ? `picture-${_vm.data.object_fit}` : 'picture-cover',
    _vm.data.align ? `align--${_vm.data.align}` : false,
  ]},[(_vm.imageType !== 'svg' && _vm.mounted)?_c('picture',{style:({ paddingBottom: _vm.data.ratio ? `${_vm.ratio}%` : false })},[_vm._l((_vm.transform),function(v,i){return _c('source',{key:i,attrs:{"media":v.media,"srcset":v.src,"type":`image/jpeg`}})}),_c('img',{ref:"image",staticClass:"image",attrs:{"lazy":_vm.data.lazyload ? 'lazy' : undefined,"alt":_vm.defaultImage.alt,"data-id":_vm.defaultImage.id},on:{"load":_vm.loading}}),(_vm.pending)?_c('div',{staticClass:"placeholder"}):_vm._e()],2):(_vm.imageType === 'inline-svg')?_c('div',{staticClass:"svg",domProps:{"innerHTML":_vm._s(_vm.data.svg)}}):(_vm.imageType === 'svg')?_c('div',[_c('img',{ref:"image",staticClass:"svg",attrs:{"src":_vm.defaultImage.url,"alt":_vm.defaultImage.alt}})]):_vm._e(),(_vm.caption !== '' && _vm.data.showCaption)?_c('Spacer',{attrs:{"tag":"figcaption","x":"s","y":"xs"},domProps:{"innerHTML":_vm._s(_vm.caption)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
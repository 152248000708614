<template>
  <div
    :style="{ '--cell-width': `${cellWidth}px` }"
    :class="[
      'carousel',
      extraSettings && extraSettings.template ? `carousel-${extraSettings.template}` : null,
    ]"
  >
    <slot />

    <div
      v-if="!$mq.isMobile && (extraSettings ? extraSettings.template !== 'posts' : true)"
      class="navigation"
    >
      <div
        class="prev"
        data-cursor="Prev"
        @click="prev"
      />
      <div
        class="next"
        data-cursor="Next"
        @click="next"
      />
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

require('flickity-imagesloaded');

export default {
  name: 'Carousel',
  props: {
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      carousel: null,
      settings: {
        cellAlign: 'center',
        cellSelector: '.item',
        imagesLoaded: true,
        wrapAround: true,
        pageDots: false,
        draggable: this.$mq.isMobile,
        prevNextButtons: true,
        arrowShape:
          'M35.5923 50.0002L71.2266 11.1264L68.7694 8.87402L31.0704 50.0002L68.7694 91.1264L71.2266 88.874L35.5923 50.0002Z',
        accessibility: false,
      },
      cellWidth: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.carousel = new Flickity(this.$el, Object.assign(this.settings, this.extraSettings));

      if (this.extraSettings && this.extraSettings.currentId) {
        this.carousel.selectCell(`[data-id="${this.extraSettings.currentId}"]`, false, true);
      }

      this.setCellWidth();
      window.addEventListener('resize', this.setCellWidth);
    });
  },
  beforeUnmount() {
    this.carousel.destroy();
    window.removeEventListener('resize', this.setCellWidth);
  },
  methods: {
    setCellWidth() {
      this.cellWidth = this.carousel.selectedElement.clientWidth;
    },
    prev() {
      this.carousel.previous();
    },
    next() {
      this.carousel.next();
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
}
</style>

<style lang="scss">
.carousel {
  .flickity-button {
    color: var(--white);
    mix-blend-mode: difference;
  }

  .navigation {
    position: absolute;
    mix-blend-mode: difference;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    pointer-events: none;

    & > * {
      flex: 0 0 25%;
      pointer-events: auto;
    }
  }

  &-single-gallery {
    .navigation {
      top: var(--bar-height);
      height: calc(100% - (var(--bar-height) * 2));
    }

    .item {
      margin-right: 2px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        top: 0;
        height: 100%;
        background: var(--grey-xl);
      }
      &::before {
        left: -1px;
      }
      &::after {
        right: -1px;
      }
    }
  }
}

.archive--post .carousel.archive-grid--horizontal {
  .item {
    width: 100%;

    @include mq(m) {
      width: 50%;
      margin-right: var(--column-gap-xs);
    }
  }
  .flickity-button {
    color: var(--white);
    top: calc(var(--cell-width) / 4 * 3 / 2 - var(--size) / 2);
    mix-blend-mode: unset;
    transform: none;
  }
}
</style>

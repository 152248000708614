<template>
  <main :data-template="post.acf.page_template">
    <template v-if="post.children.length">
      <Grid
        col="3"
        class="children"
      >
        <Column
          :size="{ default: 4, s: 1 }"
          class="children-menu"
        >
          <Flex dir="column">
            <Spacer
              v-for="c in post.children"
              :key="c.id"
              :class="[
                'child',
                'typo--label-s',
                { active: $route.hash === `#${c.slug}` },
              ]"
              x="s"
            >
              <router-link
                :to="{ hash: c.slug }"
                v-html="c.title.rendered"
              />
            </Spacer>
          </Flex>
        </Column>
        <Column :size="{ default: 4, s: 3 }">
          <Spacer all="s">
            <Section
              v-for="(section, parentIndex) in currentChild.acf.sections"
              :key="parentIndex"
              :layout="layout(section)"
              :class="`section--${parentIndex}`"
            >
              <Spacer
                v-for="(block, index) in section.blocks"
                :key="index"
                :x="
                  currentChild.acf.page_template === 'static'
                    ? 's'
                    : block.block_spacer
                "
              >
                <component
                  :is="components.get(block.acf_fc_layout)"
                  :class="[
                    'block',
                    `block--${block.acf_fc_layout}`,
                    block.block_classes && block.block_classes !== ''
                      ? block.block_classes.split(' ')
                      : null,
                  ]"
                  :data="block"
                />
              </Spacer>
            </Section>
          </Spacer>
        </Column>
      </Grid>
    </template>
    <template v-else>
      <Adv
        v-if="
          (slug === 'index' || $route.meta.slug === 'index') && advs.preheader
        "
        template="preheader"
      />
      <Section
        v-for="(section, parentIndex) in post.acf.sections"
        :key="parentIndex"
        :layout="layout(section)"
        :class="`section--${parentIndex}`"
      >
        <Spacer
          v-for="(block, index) in section.blocks"
          :key="index"
          :x="post.acf.page_template === 'static' ? 's' : block.block_spacer"
        >
          <component
            :is="components.get(block.acf_fc_layout)"
            :class="[
              'block',
              `block--${block.acf_fc_layout}`,
              block.block_classes && block.block_classes !== ''
                ? block.block_classes.split(' ')
                : null,
            ]"
            :data="block"
          />
        </Spacer>
      </Section>
    </template>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import components from '@/assets/js/components';
import data from '@/mixins/data';

import Section from '@/components/section';
import Adv from '@/components/blocks/custom/adv';

export default {
  name: 'Page',
  components: {
    Section,
    Adv,
  },
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  computed: {
    ...mapGetters(['advs']),
    currentChild() {
      const { children } = this.post;
      if (children.length) {
        return children.find((c) => `#${c.slug}` === this.$route.hash);
      }
      return null;
    },
  },
  mounted() {
    if (this.post.children.length && !this.$route.hash) {
      this.$router.replace({ hash: this.post.children[0].slug });
    }
  },
  methods: {
    layout(section) {
      const {
        section_background,
        section_classes,
        section_layout,
        section_text,
        section_id,
        section_wrapper,
        section_grid,
      } = section;

      let { section_spacer } = section;

      if (this.post.acf.page_template === 'static') {
        section_spacer = 's';
      }

      return {
        section_background,
        section_classes,
        section_layout,
        section_text,
        section_id,
        section_wrapper,
        section_spacer,
        section_grid,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
[data-template="static"] {
  @include mq(m) {
    & > section {
      width: 50%;
    }
  }
}
.children {
  background: var(--grey-xl);
  min-height: 100%;
  grid-template-rows: min-content;

  @include mq(m) {
    grid-template-rows: initial;
  }

  & > * {
    background: var(--white);
  }
}

.children-menu,
.children-menu > * {
  position: sticky;
  top: calc(var(--bar-height) * 2);
  transition: top 0.4s $ease-custom;
  z-index: 1;
}

.header-scrolling {
  .children-menu,
  .children-menu > * {
    top: var(--bar-height);
  }
}

.child {
  height: var(--bar-height);
  display: grid;
  align-items: center;
}

.child.active {
  background: var(--grey-xxl);
}
</style>

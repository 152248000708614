<template>
  <div class="field field--number">
    <slot
      name="label"
      class="field-label"
    />
    <Flex class="field-input">
      <div
        class="btn"
        @click="() => data.fn && ! data.readonly ? data.fn([data.itemKey, computedValue-1]) : false"
      >
        -
      </div>
      <input
        :id="formId ? `${formId}-${data.id || $vnode.key}` : data.id || $vnode.key"
        v-model.trim="computedValue"
        :data-key="data.itemKey"
        :name="data.id || $vnode.key"
        type="number"
        :placeholder="data.placeholder"
        :readonly="data.readonly"
        @change="data.fn ? data.fn($event) : false"
      >
      <div
        class="btn"
        @click="() => data.fn && ! data.readonly ? data.fn([data.itemKey, computedValue+1]) : false"
      >
        +
      </div>
    </Flex>
  </div>
</template>

<script>

export default {
  name: 'Input',
  props: {
    data: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    formId: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    computedValue: {
      get() {
        return Number(this.value);
      },
      set(val) {
        this.$emit('update:value', Number(val));
      },
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 20px;
  height: 22px;
  background: var(--grey-xxl);
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Grid',{class:['cart-list', `cart-list--${_vm.theme}`],attrs:{"col":"12","row-gap":"0"}},_vm._l((_vm.cart.items),function(cartItem,index){return _c('div',{key:index,staticClass:"cart__item",attrs:{"data-key":cartItem.key,"data-quantity":cartItem.quantity}},[_c('Wrapper',{attrs:{"size":"m","margin":false}},[_c('Flex',{attrs:{"justify":"space-between"}},[_c('Flex',{attrs:{"nowrap":""}},[_c('div',{staticClass:"wc-product-image wc-product-image--l",style:({
              backgroundImage: `url(${cartItem.product_image})`
            })}),_c('Flex',{attrs:{"dir":"column","justify":"space-between"}},[_c('Spacer',{attrs:{"y":"xs"}},[_c('router-link',{staticClass:"title",attrs:{"to":{name: 'SingleProduct', params: {
                  slug: cartItem.slug
                }}}},[_vm._v(" "+_vm._s(cartItem.product_name)+" ")]),_c('Spacer',{attrs:{"top":"s"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getLineTotal(cartItem))}})])],1),_c('Spacer',{staticClass:"cart__item__footer",attrs:{"y":"xs"}},[(_vm.theme !== 'checkout')?_c('Flex',{attrs:{"align":"center"}},[_c('Number',{key:_vm.cartLoading,attrs:{"data":{
                    id: `number`,
                    fn: _vm.numberChanged,
                    itemKey: cartItem.key,
                    readonly: _vm.cartLoading
                  },"value":cartItem.quantity}}),_c('Spacer',{attrs:{"left":"s"}},[_c('a',{staticClass:"button cart__remove-item typo--s",on:{"click":function($event){return _vm.removeCartItem(cartItem)}}},[_vm._v(" "+_vm._s(_vm.$labels.remove)+" ")])])],1):_vm._e()],1)],1)],1)],1)],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
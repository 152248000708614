<template>
  <div
    id="app"
    :class="[
      overlay ? 'app--is-overlay' : false,
      $route.name === 'Index' ? 'app--home' : false,
      $route.name === 'Search' ? 'app--search' : false,
    ]"
  >
    <AppHeader />
    <Breadcrumbs />
    <Search v-if="search" />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <div
        :key="filteredPath"
        :class="['main--default', `main--${filteredPath.substring(1)}`]"
        :style="overlay ? { transform: `translate3d(0, ${offset * -1}px, 0)` } : false"
      >
        <router-view />

        <AppFooter v-show="!hideFooter" />
      </div>
    </transition>

    <transition
      :css="false"
      :mode="
        $route.meta.overlay && $store.state.route.from.meta.overlay && !$route.meta.avoidTransition
          ? 'in-out'
          : 'out-in'
      "
      appear
      @enter="enterOverlay"
      @leave="leaveOverlay"
    >
      <div
        v-if="overlay"
        :key="$route.path"
        ref="overlay"
        class="main--overlay"
        :style="{ '--overlay-opacity': Number(opacity).toFixed(2) }"
        @click="clickGoBack"
      >
        <router-view name="overlay" />
      </div>
    </transition>
    <Modal />
    <Snackbar />
    <Cart />
    <Loader />
    <Pointer v-if="!$mq.isMobile" />

    <div
      ref="disable"
      class="disable"
    />
  </div>
</template>

<script>
import gsap from 'gsap';
// import Cookies from 'js-cookie';
import VirtualScroll from 'virtual-scroll';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';

import AppHeader from '@/components/ui/header';
import Breadcrumbs from '@/components/ui/breadcrumbs';
import AppFooter from '@/components/ui/footer';

import Modal from '@/components/ui/modal';
import Snackbar from '@/components/ui/snackbar';
import Loader from '@/components/ui/loader';
import Search from '@/components/ui/search';
import Pointer from '@/components/ui/pointer';

import Cart from '@/components/wc/cart';

export default {
  name: 'App',
  components: {
    AppHeader,
    Breadcrumbs,
    AppFooter,
    Modal,
    Loader,
    Snackbar,
    Cart,
    Search,
    Pointer,
  },
  data() {
    return {
      offset: 0,
      isIntersecting: false,
      lastScroll: -1,
      dir: false,
      scroller: null,
      isIntersectingTimeout: null,
      isBack: false,
      isAnim: false,
      count: 0,
      opacity: 0.75,
    };
  },
  computed: {
    ...mapGetters([
      'currentPage',
      'currentSingle',
      'options',
      'overlay',
      'transition',
      'search',
      'advs',
    ]),
    filteredPath() {
      let prev = this.$store.state.route.from
        && this.$store.state.route.from.name
        && this.$store.state.route.from.name !== 'post-Single'
        && this.$store.state.route.from.name !== 'SingleProduct'
        && this.$store.state.route.from.name !== 'publishing-Single'
        ? this.$store.state.route.from.path
        : null;
      if (prev && prev.length > 1 && prev.endsWith('/')) prev = prev.slice(0, -1);

      const prevPath = prev
        || (this.$route.name === 'post-Single'
          ? '/magazine'
          : this.$route.name === 'SingleProduct'
            ? '/shop'
            : this.$route.name === 'publishing-Single'
              ? '/publishing'
              : null);
      let { path } = this.$route;
      if (path.length > 1 && path.endsWith('/')) path = path.slice(0, -1);
      const route = this.$route.name !== 'post-Single'
        && this.$route.name !== 'SingleProduct'
        && this.$route.name !== 'publishing-Single'
        ? path
        : prevPath;
      return route;
    },
    hideFooter() {
      return this.currentPage?.acf?.hide_footer
        ? this.currentPage.acf.hide_footer
        : this.$route.meta.hideFooter;
    },
  },
  watch: {
    overlay: {
      immediate: true,
      handler(val) {
        const fn = val ? 'add' : 'remove';
        document.body.classList[fn]('overscroll-behavior');

        if (val) {
          this.offset = window.pageYOffset;
          if (this.$store.state.route.from.name === 'Index') {
            const barHeight = parseInt(
              window.getComputedStyle(document.documentElement).getPropertyValue('--bar-height'),
              10,
            );
            this.offset += barHeight;
          }
        } else {
          this.isIntersecting = false;
          const extraOffset = this.$route.name === 'Index'
            ? parseInt(
              window
                .getComputedStyle(document.documentElement)
                .getPropertyValue('--bar-height'),
              10,
            )
            : 0;
          const prevOffset = this.offset - extraOffset;
          this.$nextTick(() => {
            window.scroll(0, prevOffset);
          });
          this.offset = 0;
        }
      },
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );

    // this.$aion.add(this.goBack, 'goBack', true);
    this.$aion.add(this.checkHeader, 'checkHeader');

    if (
      // !Cookies.get('mousse-banner') &&
      this.advs.popup
    ) {
      this.$bus.$emit('modal', {
        type: 'banner',
        theme: 'banner',
        id: 'popup',
      });

      this.$aion.add(this.hideModalOnScroll, 'hideModalOnScroll');
    }

    this.scroller = new VirtualScroll();
    this.scroller.on(this.goBack);

    document.body.addEventListener('keydown', this.clickGoBack);

    this.$bus.$on('iubendaReady', () => {
      this.$el.classList.add('app--cookied');
    });

    this.$bus.$on('iubendaClose', () => {
      this.$el.classList.remove('app--cookied');
    });
  },
  beforeDestroy() {
    document.body.removeEventListener('keydown', this.clickGoBack);
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
    enterOverlay(el, done) {
      if (this.$route.meta.overlay && this.$store.state.route.from.meta.overlay) {
        done();
      }

      if (this.$route.meta.avoidTransition) {
        this.$store.commit('SET_TRANSITION', false);
        return;
      }

      gsap.set(el, {
        y: window.innerHeight,
      });

      gsap.to(el, {
        y: 0,
        duration: 0.8,
        ease: 'expo.inOut',
        clearProps: 'all',
        onComplete: () => {
          done();
          this.$store.commit('SET_TRANSITION', false);
        },
      });
    },
    leaveOverlay(el, done) {
      this.$store.commit('SET_TRANSITION', true);

      if (this.$route.meta.avoidTransition) {
        window.scroll(0, 0);
        done();
        return;
      }

      const extraOffset = parseInt(
        window.getComputedStyle(document.documentElement).getPropertyValue('--bar-height'),
        10,
      ) * 2;

      if (this.$route.meta.overlay) {
        gsap.set(el, {
          position: 'fixed',
          top: extraOffset,
          y: window.pageYOffset * -1,
          left: 0,
          width: '100%',
        });

        window.scroll(0, 0);

        setTimeout(() => {
          done();
        }, 1000);
      } else {
        window.scroll(0, 0);
        done();
      }
    },
    clickGoBack(e) {
      if (this.overlay && this.$refs.overlay) {
        if (
          (e.target !== this.$refs.overlay.children[0]
            && !this.$refs.overlay.children[0].contains(e.target))
          || e.keyCode === 27
        ) {
          const barHeight = parseInt(
            window.getComputedStyle(document.documentElement).getPropertyValue('--bar-height'),
            10,
          );
          if (
            !this.transition
            && window.pageYOffset + window.innerHeight
              >= document.body.clientHeight - barHeight - window.innerHeight * 0.5
            && document.body.clientHeight - barHeight * 2 > window.innerHeight
          ) {
            this.animBack();
          }
        }
      }
    },
    goBack(e) {
      if (this.isAnim) {
        return;
      }

      const barHeight = parseInt(
        window.getComputedStyle(document.documentElement).getPropertyValue('--bar-height'),
        10,
      );
      if (
        !this.transition
        && this.overlay
        && window.pageYOffset + window.innerHeight >= document.body.clientHeight - barHeight
        && !this.isIntersecting
        && document.body.clientHeight - barHeight * 2 > window.innerHeight
      ) {
        if (this.$mq.isTouchDevice) {
          this.animBack();
          return;
        }

        this.isIntersecting = true;
        this.isBack = true;
        if (this.isIntersectingTimeout) {
          window.clearTimeout(this.isIntersectingTimeout);
          this.isIntersectingTimeout = null;
        }
        this.isIntersectingTimeout = setTimeout(() => {
          this.isBack = true;
        }, 500);
      } else if (this.isBack && e.deltaY < -30) {
        this.animBack();
      } else if (
        this.isIntersecting
        && window.pageYOffset + window.innerHeight
          <= document.body.clientHeight - window.innerHeight * 0.5
      ) {
        this.isBack = false;
        this.isIntersecting = false;

        if (this.isIntersectingTimeout) {
          window.clearTimeout(this.isIntersectingTimeout);
          this.isIntersectingTimeout = null;
        }
      }
    },
    animBack() {
      this.isBack = false;
      this.isAnim = true;
      if (this.isIntersectingTimeout) {
        window.clearTimeout(this.isIntersectingTimeout);
        this.isIntersectingTimeout = null;
      }
      disableBodyScroll(this.$refs.disable);
      this.$store.commit('SET_GO_BACK', true);

      this.$refs.overlay.classList.add('go-back');

      if (this.$mq.isTouchDevice) {
        gsap.to(this, {
          opacity: 0,
          duration: 0.6,
          ease: 'expo.out',
          onComplete: () => {
            clearAllBodyScrollLocks();
            this.$refs.overlay.classList.remove('go-back');
            this.opacity = 0.75;

            this.isIntersecting = false;
            this.isBack = false;
            this.isAnim = false;
            if (this.filteredPath === '/search') {
              this.$router.push(this.$store.state.route.from);
            } else {
              this.$router.push(this.filteredPath);
            }
          },
        });
      } else {
        gsap.to(this, {
          opacity: 0,
          duration: 0.6,
          ease: 'expo.out',
        });

        gsap.to(window, {
          scrollTo: {
            y: 'max',
          },
          duration: 0.8,
          ease: 'expo.out',
          onComplete: () => {
            clearAllBodyScrollLocks();
            this.$refs.overlay.classList.remove('go-back');
            this.opacity = 0.75;

            this.isIntersecting = false;
            this.isBack = false;
            this.isAnim = false;
            if (this.filteredPath === '/search') {
              this.$router.push(this.$store.state.route.from);
            } else {
              this.$router.push(this.filteredPath);
            }
          },
        });
      }
    },
    checkHeader() {
      if (
        window.pageYOffset <= (this.$route.name === 'Search' ? (this.$mq.isMobile ? 170 : 220) : 30)
      ) {
        this.lastScroll = -1;
        if (this.$el.classList.contains('header-scrolling')) this.$el.classList.remove('header-scrolling');
        return;
      }

      this.dir = window.pageYOffset >= this.lastScroll;

      if (this.dir) {
        this.lastScroll = window.pageYOffset;
        if (!this.$el.classList.contains('header-scrolling')) this.$el.classList.add('header-scrolling');
      } else {
        this.lastScroll = window.pageYOffset + 1;
        if (this.$el.classList.contains('header-scrolling')) this.$el.classList.remove('header-scrolling');
      }
    },
    hideModalOnScroll() {
      if (window.pageYOffset > window.innerHeight) {
        this.$bus.$emit('removeContentModal', 'popup');

        this.$aion.remove('hideModalOnScroll');
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

body {
  &.overlay-gallery {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;

    .main--overlay {
      z-index: 50;
    }
  }

  &.overlay-menu {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
}

.app-loaded {
  #loader {
    display: none;
  }
}

.main--overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  & > * {
    position: relative;
    background: var(--white);
  }

  &::after {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    height: calc(var(--vh, 1vh) * 50);
    // pointer-events: none;
    opacity: var(--overlay-opacity, 0.75);
    background: var(--white);
    cursor: pointer;
  }

  &.go-back {
    &::after {
      height: calc((var(--vh, 1vh) * 100) - (var(--bar-height) * 1));
    }
  }
}

.main--default {
  min-height: calc((var(--vh, 1vh) * 100) - (var(--bar-height) * 2));
  display: flex;
  flex-direction: column;

  & > *:first-child {
    flex: 1 0 0;
  }
}

.app--is-overlay {
  .main--default {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc((var(--vh, 1vh) * 100) - (var(--bar-height) * 2));
    pointer-events: none;
    z-index: 0;

    .thumb--horizontal-xl .abstract {
      position: relative !important;
      top: 0 !important;
    }
  }
}
</style>

<template>
  <main v-if="!loading">
    <Grid
      class="product-section"
      tag="section"
      column-gap="0"
    >
      <Column :size="{ default: 12, m: 8 }">
        <Media
          v-if="productGallery && productGallery.length > 0"
          ref="product-gallery"
          class="product-gallery"
          :data="{
            type: 'gallery',
            gallery: productGallery,
            object_fit: 'cover',
            ratio: $mq.isMobile,
            lazyload: false,
          }"
        />

        <Media
          v-else
          class="product-gallery"
          :data="{
            type: 'image',
            image: post.gds_featured_image,
            object_fit: 'cover',
            ratio: $mq.isMobile,
            lazyload: false,
          }"
        />
      </Column>
      <Column :size="{ default: 12, m: 4 }">
        <Flex
          :dir="{ m: 'column' }"
          class="product-content"
        >
          <div class="product-body">
            <div class="text">
              <Spacer all="s">
                <Title
                  :data="{
                    value: isSubscription(post.slug) ? magazineSubscription.title : post.name,
                    size: $mq.isMobile ? 'l' : 'm',
                  }"
                />
                <Spacer :top="{ default: 'xl', m: 'm' }">
                  <Richtext
                    :data="{
                      value: isSubscription(post.slug)
                        ? magazineSubscription.text
                        : product.description
                          ? product.description
                          : post.description,
                      size: $mq.isMobile ? 'm' : 's',
                    }"
                  />
                </Spacer>
              </Spacer>
            </div>
            <div
              v-if="author || info"
              class="meta"
            >
              <Spacer all="s">
                <Richtext
                  v-if="author && author !== ''"
                  :data="{
                    value: author,
                  }"
                />
                <Richtext
                  v-if="info && info !== ''"
                  :data="{
                    value: info,
                  }"
                />
              </Spacer>
            </div>
            <div
              v-if="isSubscription(post.slug)"
              class="subscriptions"
            >
              <Spacer all="s">
                <Grid
                  grid="1"
                  row-gap="s"
                >
                  <Cta
                    v-for="s in magazineSubscription.products"
                    :key="s.ID"
                    :data="{
                      title: s.post_title,
                      url: {
                        name: 'SingleProduct',
                        params: {
                          slug: s.post_name,
                        },
                        query: {
                          switch: true,
                        },
                      },
                    }"
                    :theme="s.ID === post.id ? 'button' : 'button-outline'"
                    :disabled="s.ID === post.id"
                  />
                </Grid>
              </Spacer>
            </div>
            <div
              v-if="isSubscription(post.slug)"
              class="text"
            >
              <Spacer all="s">
                <Richtext
                  :data="{
                    value: product.description ? product.description : post.description,
                    size: $mq.isMobile ? 'm' : 's',
                  }"
                />
              </Spacer>
            </div>
            <div class="actions typo--label">
              <Spacer all="s">
                <Share
                  :data="{
                    permalink: post.link,
                    title: post.name,
                    media: post.gds_featured_image ? post.gds_featured_image.url : null,
                  }"
                />
              </Spacer>
            </div>
            <div v-if="hasVariations">
              <Spacer all="s">
                <div
                  v-for="(attribute, index) in attributes"
                  :key="index"
                >
                  <Flex>
                    <Select
                      v-model="attribute.model"
                      class="product__variation-selector"
                      :data="getAttribute(attribute)"
                      :model.sync="attribute.model"
                    >
                      <label
                        slot="label"
                        for="terms"
                      >{{ attribute.name }}</label>
                    </Select>
                    <Icon
                      v-if="attribute.model"
                      name="close"
                      theme="small"
                      :fn="() => resetAttribute(attribute)"
                    />
                  </Flex>
                </div>
              </Spacer>
            </div>
          </div>
          <Flex class="product-footer flex-auto">
            <div
              v-if="isOos && post.acf.notification && post.acf.notification.active"
              class="notification-box"
            >
              <Transition name="slide">
                <Notification
                  v-show="showNotification"
                  :text="post.acf.notification.text"
                  :cta="$labels.notification_submit_cta"
                  :product-id="product.id"
                  @close="closeNotification"
                />
              </Transition>
              <Cta
                class="wc-cta"
                :data="{
                  title: showNotification ? $labels.close : $labels.notification_cta,
                  url: false,
                  fn: () => {},
                }"
                theme="button"
                @click.native="showNotification = !showNotification"
              />
            </div>
            <div class="price t-center">
              <Price
                v-if="product"
                :product="product"
              />
            </div>
            <!-- <Availability :product="product" /> -->
            <!-- <Number
              :data="{
                id: `number`,
                fn: numberChanged,
                itemKey: product.id,
                readonly: cartLoading || isOos,
              }"
              :value.sync="numberToBeAdded"
            /> -->
            <template v-if="product.type !== 'external'">
              <Cta
                class="wc-cta"
                :data="{
                  title: labelCta,
                  url: false,
                  fn: addToCart,
                }"
                theme="button"
                :disabled="!addableToCart"
              />
            </template>
            <template v-else>
              <Cta
                class="wc-cta"
                :data="{
                  title: product.button_text || $labels.external_url,
                  url: product.external_url,
                  target: '_blank',
                }"
                theme="button"
              />
            </template>
          </Flex>
        </Flex>
      </Column>
    </Grid>
    <Archive
      :data="{
        title: post.upsell_ids && post.upsell_ids.length > 0 ? 'Related items' : 'From the shop',
        archive_type: 'archive',
        archive: 'product',
        product: post.upsell_ids && post.upsell_ids.length > 0 ? post.upsell_ids : undefined,
        max_posts: 4,
        exclude: isSubscription(post.slug)
          ? [post.id, ...magazineSubscription.products.map((p) => p.ID)]
          : [post.id],
        layout: [
          {
            media_query: 'default',
            layout_size: '6',
            layout_type: 'grid',
          },
          {
            media_query: 'm',
            layout_size: '3',
            layout_type: 'grid',
          },
        ],
      }"
    />
    <OverlayFooter :data="footer.blocks" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { decodeHtml } from '@/assets/js/utils';
import { addableToCart, isOos } from '@/assets/js/utils-wc';

import data from '@/mixins/data';
import isEqual from 'lodash.isempty';

import Media from '@/components/blocks/media';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Richtext from '@/components/typo/richtext.vue';
import Icon from '@/components/ui/icon.vue';
import Price from '@/components/wc/price.vue';
import Notification from '@/components/wc/notification.vue';
// import Availability from '@/components/wc/availability.vue';

import Archive from '@/components/blocks/archive';
import Share from '@/components/ui/share';
import OverlayFooter from '@/components/ui/overlay-footer';

// import Number from '@/components/form/fields/number';
import Select from '@/components/form/fields/select';

import { fetchVariations } from '@/api';

// N.B. testato fino a 2 attributi

export default {
  name: 'SingleProduct',
  components: {
    Media,
    Title,
    Cta,
    Richtext,
    Icon,
    Price,
    // Availability,
    // Number,
    Select,
    Archive,
    Share,
    OverlayFooter,
    Notification,
  },
  mixins: [data],
  data() {
    return {
      loading: true,
      product: null,
      variations: [],
      attributes: [],
      selectedVariation: null,
      selectedImage: null,
      numberToBeAdded: 1,
      labelCta: this.$labels.add_to_cart,
      showNotification: false,
    };
  },
  computed: {
    ...mapGetters(['cartLoading', 'options', 'magazineSubscription', 'isSubscription']),
    hasVariations() {
      return this.post.type.startsWith('variable');
    },
    isOos() {
      return isOos(this.product);
    },
    addableToCart() {
      if (this.hasVariations && !this.selectedVariation) {
        return false;
      }
      if (!addableToCart(this.product) || isOos(this.product)) {
        return false;
      }
      return true;
    },
    productGallery() {
      this.variations.forEach((variation) => {
        this.post.gallery.push(variation.gds_featured_image);
      });
      return this.post.gallery ? this.post.gallery : [];
    },
    author() {
      const meta = this.post.meta_data?.find((val) => val.key === '_autori_field');
      return meta ? decodeHtml(meta.value) : false;
    },
    info() {
      const meta = this.post.meta_data?.find((val) => val.key === '_info_field');
      return meta ? decodeHtml(meta.value) : false;
    },
    footer() {
      const settings = this.options.overlay_footer.find((el) => el.type === 'products');
      return settings;
    },
  },
  watch: {
    $route() {
      this.setSelectedVariation();
    },
    selectedImage() {
      if (this.$refs['product-gallery'] && this.$refs['product-gallery'].$children[0]) {
        setTimeout(() => {
          this.$refs['product-gallery'].$children[0].gallery.selectCell(
            `[data-item-id="${this.selectedImage}"]`,
          );
        }, 100); // Await flickity loading
      }
    },
    loading(val) {
      if (!val) {
        this.$nextTick(() => {
          if (this.$refs['product-gallery'] && this.$refs['product-gallery'].$children[0]) {
            this.$refs['product-gallery'].$children[0].gallery.selectCell(
              `[data-item-id="${this.selectedImage}"]`,
            );
          }
        });
      }
    },
  },
  async created() {
    this.product = this.post;
    if (isOos(this.product)) {
      this.labelCta = this.$labels.out_of_stock;
    }

    if (this.product.button_text) {
      this.labelCta = this.product.button_text;
    }
    if (this.hasVariations) {
      this.$store.commit('SET_CART_LOADING', true);
      const { data: variations } = await fetchVariations({
        params: { slug: this.post.slug },
      });
      this.variations = variations;
      const attributesKeys = Object.keys(this.post.attributes_slugs);
      attributesKeys.forEach((attribute_slug, index) => {
        const newAttribute = {
          type: 'select',
          model: null,
          id: attribute_slug,
          name: this.post.attributes[index].name,
          hidden: false,
          placeholder: null,
          options: {},
          originalOptions: {},
          fn: this.updateSelectedVariation,
        };
        this.post.attributes[index].options.forEach((option, i) => {
          // Check if exists any variation with this attribute
          if (
            this.variations.findIndex(
              (variation) => variation.attributes_slugs[attribute_slug]
                === this.post.attributes_slugs[attribute_slug][i],
            ) > -1
          ) {
            newAttribute.options[this.post.attributes_slugs[attribute_slug][i]] = option;
            newAttribute.originalOptions[this.post.attributes_slugs[attribute_slug][i]] = option;
          }
        });
        this.attributes.push(newAttribute);
      });
      this.setSelectedVariation();
      this.$store.commit('SET_CART_LOADING', false);
      this.loading = false;
    } else {
      this.loading = false;
    }

    if (this.$route.query.bis_unsub_handle) {
      const { data: notices } = await this.$store.dispatch('sendRequest', {
        fn: 'wc-notice',
      });

      if (notices && notices.success && notices.success.length) {
        this.$store.commit('SET_SNACKBAR', {
          active: true,
          content: notices.success[notices.success.length - 1].notice,
          theme: 'success',
          autoClose: false,
        });
      }
    }
  },
  async mounted() {
    // Marketing
    this.$bus.$emit('viewItem', this.product);
  },
  methods: {
    addToCart() {
      const prevCta = this.labelCta;
      this.labelCta = this.$labels.added;
      this.$store
        .dispatch(
          'addToCart',
          Object.assign(this.post, {
            id: String(this.post.id),
            variation_id: this.selectedVariation,
            variation: this.variations.find((variation) => this.selectedVariation === variation.id),
            quantity: this.numberToBeAdded,
            quantityDelta: this.numberToBeAdded,
          }),
        )
        .then(() => {
          this.labelCta = prevCta;
        });
    },
    numberChanged(val) {
      if (val[0]) {
        /* eslint-disable */
        this.numberToBeAdded = val[1];
      } else {
        this.numberToBeAdded = val.target.value;
      }
      if (this.numberToBeAdded < 1) {
        this.numberToBeAdded = 1;
      }
      if (this.product.stock_quantity && this.numberToBeAdded > this.product.stock_quantity) {
        this.numberToBeAdded = this.product.stock_quantity;
      }
    },
    getAttribute(attribute) {
      const select = document.querySelector(`select#${attribute.id}`);
      const currentFixedOption = document.querySelector(".field.focus select");
      if (
        currentFixedOption &&
        select &&
        select.closest(".field") &&
        !select.closest(".field").classList.contains("focus")
      ) {
        // Re set original value
        const originalOptionsKeys = Object.keys(attribute.originalOptions);
        originalOptionsKeys.forEach((originalOptionsKey) => {
          if (!attribute.options[originalOptionsKey]) {
            attribute.options[originalOptionsKey] = attribute.originalOptions[originalOptionsKey];
          }
        });

        const currentFixedKey = currentFixedOption.getAttribute("id");
        const currentFixedValue = currentFixedOption.value;
        const optionsKeys = Object.keys(attribute.options);
        optionsKeys.forEach((optionsKey) => {
          const existingVariation = this.variations.filter((variation) => {
            return (
              variation.attributes_slugs[currentFixedKey] === currentFixedValue &&
              variation.attributes_slugs[attribute.id] === optionsKey
            );
          });
          if (!existingVariation[0]) {
            delete attribute.options[optionsKey];
          }
        });
      }

      return attribute;
    },
    resetAttribute(attribute) {
      attribute.model = null;
      this.resetSelectedVariation();
    },
    resetSelectedVariation() {
      this.selectedVariation = null;
      this.selectedImage = null;
    },
    setSelectedVariation() {
      const queryVariations = Object.keys(this.$route.query);
      if (queryVariations.length === 0) {
        this.attributes.forEach((attribute) => {
          attribute.model = null;
        });
        this.resetSelectedVariation();
      } else {
        // Re-attribute model if reloading page
        queryVariations.forEach((queryVariation) => {
          this.attributes.forEach((attribute) => {
            if (attribute.id === queryVariation.replace("attribute_", "")) {
              attribute.model = this.$route.query[queryVariation];
            }
          });
        });

        const selectedVariation = this.variations.find((variation) => {
          let isRightVariation = false;
          let correctAttributes = 0;
          this.attributes.forEach((attribute) => {
            if (attribute.model === variation.attributes_slugs[attribute.id]) {
              correctAttributes += 1;
            }
          });
          if (correctAttributes === variation.attributes.length) {
            isRightVariation = true;
          }
          return isRightVariation;
        });
        if (selectedVariation) {
          this.selectedVariation = selectedVariation.id;
          this.selectedImage = selectedVariation.gds_featured_image
            ? selectedVariation.gds_featured_image.id
            : null;
          this.product = selectedVariation;
        } else {
          this.resetSelectedVariation();
        }
      }
      // Marketing
      this.$bus.$emit("selectItem", this.product);
    },
    updateSelectedVariation() {
      let attributesSelected = 0;
      const query = {};
      this.attributes.forEach((attribute) => {
        if (attribute.model) {
          query[`attribute_${attribute.id}`] = attribute.model;
          attributesSelected += 1;
        }
      });
      if (attributesSelected === this.attributes.length && !isEqual(query, this.$route.query)) {
        this.$router.push({ query });
      }
    },
    closeNotification() {
      this.showNotification = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-gallery {
  max-height: 300px;

  ::v-deep .item {
    &::before,
    &::after {
      background: var(--white);
    }
  }
}

@include mq(m) {
  .product-section,
  .product-content,
  .product-gallery {
    height: calc((var(--vh, 1vh) * 100) - (2 * var(--bar-height)));
  }

  .product-gallery {
    max-height: none;

    ::v-deep .item {
      height: calc((var(--vh, 1vh) * 100) - (2 * var(--bar-height)));
    }
  }
}

.product-body {
  @include mq(m) {
    flex: 1 1 0;
  }

  width: 100%;
  overflow-y: scroll;

  & > *:not(:last-child) {
    border-bottom: 1px solid var(--grey-xl);
  }
}

@include mq(m) {
  .text {
    max-height: calc(var(--vh, 1vh) * 64);
    overflow-y: scroll;
  }
}

.product__variation-selector {
  min-width: 200px;
}

.product-footer {
  background: var(--white);
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 2;

  .notification-box {
    flex-basis: 100%;
    overflow: hidden;
    z-index: 3;
  }

  .notification {
    position: relative;
    z-index: -1;
  }

  .app--cookied & {
    bottom: var(--bar-height);
  }
}

.price {
  position: relative;
  line-height: var(--bar-height);

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--grey-xl);
  }
}

.wc-cta {
  ::v-deep {
    a,
    button {
      height: var(--bar-height);

      div {
        line-height: var(--bar-height);
      }
    }
  }
}

.subscriptions {
  ::v-deep {
    .cta-disabled {
      opacity: 1;
    }
  }
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>

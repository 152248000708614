<template>
  <AccordionWrapper :active-counter="data.accordion.open ? 1 : 0">
    <AccordionItem
      :opened-fn="data.accordion.fn"
      :show="data.accordion.open"
    >
      <template slot="accordion-trigger">
        <slot name="title" />
      </template>
      <template slot="accordion-content">
        <slot name="fields" />
      </template>
    </AccordionItem>
  </AccordionWrapper>
</template>

<script>
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';

export default {
  name: 'AccordionGroup',
  components: {
    AccordionWrapper,
    AccordionItem,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>

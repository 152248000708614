<template>
  <div>
    <Flex
      :class="['search-bar']"
      align="center"
    >
      <Spacer
        x="s"
        :class="['search-input']"
      >
        <Flex
          justify="space-between"
          align="center"
          :class="['search-input-wrapper']"
        >
          <div class="bar">
            <input
              id="s"
              ref="input"
              v-model="s"
              name="s"
              placeholder="Search"
              spellcheck="false"
              @keyup.enter="submit"
            >
          </div>
        </Flex>
      </Spacer>

      <div>
        <Flex
          class="type"
          column-gap="0"
        >
          <Cta
            :data="{
              title: 'Text',
              fn: () => setRequestType('post'),
              size: 'label-xs',
            }"
            :theme="type === 'post' ? 'button' : 'button-white'"
          />
          <Cta
            :data="{
              title: 'Images',
              fn: () => setRequestType('media'),
              size: 'label-xs',
            }"
            :theme="type === 'media' ? 'button' : 'button-white'"
          />
        </Flex>
      </div>
    </Flex>

    <div class="search-placeholder" />
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import Cta from '@/components/typo/cta';

export default {
  name: 'Search',
  components: {
    Cta,
  },
  data() {
    return {
      s: this.$route.query.s,
      type: this.$route.query.type || 'post',
      debounceHandler: null,
    };
  },
  computed: {},
  mounted() {
    this.$refs.input.focus();
    this.debounceHandler = debounce(this.submit, 1000);
    this.$refs.input.addEventListener('input', this.debounceHandler);
  },
  beforeDestroy() {
    this.$refs.input.removeEventListener('input', this.debounceHandler);
  },
  methods: {
    setRequestType(type) {
      this.type = type;
      this.submit();
    },
    submit() {
      this.$router.replace({ query: { ...this.$route.query, s: this.s, type: this.type } });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-placeholder {
  position: relative;
  height: calc(var(--block-height) + var(--bar-height));
}
.search-bar {
  position: fixed;
  top: var(--bar-height);
  left: 0;
  width: 100%;
  z-index: 12;

  transition: all 0.4s $ease-custom;

  .header-scrolling & {
    top: 0;
  }

  .app--search & {
    // position: sticky;
  }

  & > * {
    flex: 0 0 100%;
  }

  .bar {
    flex: 1 0 0;
  }

  input {
    appearance: none;
    background: transparent;
    @extend %typo--l;
    border: 0;
    font-family: $serif;
    color: var(--grey-xxd);
    width: 100%;
    transition: font 0.3s $ease-custom;

    .header-scrolling & {
      @extend %typo--input;
    }
  }

  .search-input {
    height: var(--block-height);
    background: var(--green);
    transition: height 0.3s $ease-custom, padding 0.3s $ease-custom;
    padding-top: var(--spacer-xs);
    .header-scrolling & {
      padding-top: var(--spacer-1);
      height: var(--bar-height);
    }
  }

  .search-input-wrapper {
    height: var(--bar-height);
  }

  .type {
    & > * {
      flex: 1 0 0;
    }
  }
}
</style>

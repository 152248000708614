<template>
  <AccordionWrapper
    :id="`order-${order.id}`"
    class="block-theme--default"
  >
    <AccordionItem :data-order="order.id">
      <template slot="accordion-trigger">
        <Grid class="order-grid">
          <Column
            :size="{
              default: 6,
              xs: 3,
            }"
            :class="{ 'typo--m': $mq.isMobileXS }"
          >
            #{{ order.id }}
          </Column>
          <Column
            v-if="$mq.isMobileXS"
            :size="{
              default: 9,
              s: 3,
            }"
            class="typo--m"
          >
            <div class="t-ellipsis">
              {{ orderStatus }}
            </div>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <div class="t-ellipsis">
              {{ parseDate(order.created_date) }}
            </div>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <Flex
              align="center"
              class="flex-no-wrap"
            >
              <div class="t-ellipsis">
                {{ orderStatus }}
              </div>
              <Spacer
                v-if="orderActions[0]"
                left="xs"
              >
                <Icon
                  name="alert"
                  size="xs"
                />
              </Spacer>
            </Flex>
          </Column>
          <Column
            v-if="!$mq.isMobileXS"
            size="3"
          >
            <div
              class="t-ellipsis"
              v-html="getOrderTotal(order)"
            />
          </Column>
        </Grid>
      </template>
      <template slot="accordion-content">
        <Spacer x="s">
          <Grid
            col="12"
            row-gap="l"
          >
            <div
              v-if="!$mq.isMobileXS"
              class="typo--m"
            >
              <span v-if="theme === 'default'">
                {{ $labels.order_details }}
              </span>
              <span v-else>{{ $labels.details }}</span>
            </div>
            <div v-else>
              <OrderHeader
                :order="order"
                :theme="theme"
              />
              <div class="separator--row" />
            </div>
            <OrderDetails
              :order="order"
              :theme="theme"
            />
          </Grid>
        </Spacer>
      </template>
    </AccordionItem>
  </AccordionWrapper>
</template>

<script>
import {
  parseDate,
  parseCurrency,
  availableOrderActions,
  getOrderTotal,
} from '@/assets/js/utils-wc';

import { mapGetters } from 'vuex';
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import OrderHeader from '@/components/wc/order-header';
import OrderDetails from '@/components/wc/order-details';
import Icon from '@/components/ui/icon';

export default {
  name: 'Order',
  components: {
    AccordionWrapper,
    AccordionItem,
    OrderHeader,
    OrderDetails,
    Icon,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      parseCurrency,
    };
  },
  computed: {
    ...mapGetters(['wcSettings']),
    orderStatus() {
      return this.$store.state.wp.wcSettings.woocommerce_order_statuses[
        `wc-${this.order.status}`
      ];
    },
    orderActions() {
      return availableOrderActions[`wc-${this.order.status}`];
    },
  },
  mounted() {},
  methods: {
    parseDate(date) {
      if (date) {
        return parseDate(date, this.wcSettings);
      }
      return null;
    },
    getOrderTotal(order) {
      return getOrderTotal(order, this.wcSettings);
    },
    // closeOtherOrders() {
    //   document.querySelectorAll('.accordion__trigger--active').forEach((accordion) => {
    //     console.log(Number(accordion.parentElement.dataset.order), this.order.id);
    //     if (Number(accordion.parentElement.dataset.order) !== Number(this.order.id)) {
    //       accordion.click();
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.block-theme--default {
  border-bottom: 1px solid var(--grey-xl);
  padding-bottom: var(--spacer-xs);
  margin-bottom: var(--spacer-xs);
}
.order-grid {
  flex: 1 0 0;
}
</style>

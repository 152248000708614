<template>
  <Spacer
    top="s"
    x="s"
  >
    <Grid
      col="12"
      row-gap="m"
    >
      <div class="typo--m">
        {{ $labels.change_payment_method }}
      </div>
      <div v-if="subscription">
        <Grid
          align="baseline"
        >
          <Column :size="{ default: 12, s: 6, l: 8 }">
            <Grid
              col="12"
              class="block-theme--default"
            >
              <SubscriptionDetails
                :subscription="subscription"
                theme="simple"
              />
            </Grid>
          </Column>
          <Column :size="{ default: 12, s: 6, l: 4 }">
            <Grid
              col="12"
              class="block-theme--default"
              row-gap="m"
            >
              <Spacer
                x="s"
                class="typo--m"
              >
                {{ $labels.payment_methods }}
              </Spacer>
              <Form
                id="changePaymentMethod"
                theme="plain"
                :data="{
                  subscription
                }"
              />
            </Grid>
          </Column>
        </Grid>
      </div>
    </Grid>
  </Spacer>
</template>

<script>
import Form from '@/components/form';
import isEmpty from 'lodash.isempty';
import SubscriptionDetails from '@/components/wc/subscription-details.vue';

import { mapGetters } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import {
  stripeMountCard,
} from '@/assets/js/utils-wc';

export default {
  name: 'PayOrder',
  components: {
    SubscriptionDetails,
    Form,
  },
  data() {
    return {
      subscription: null,
    };
  },
  computed: {
    ...mapGetters(['paymentGateways', 'paymentMetas', 'user']),
  },
  created() {
    if (isEmpty(this.user)) {
      this.$router.push({ name: 'Account' });
    }
  },
  async mounted() {
    const subscription = await this.$store.dispatch('getSubscription', {
      key: this.$route.query.key,
      subscription_id: this.$route.params.orderId,
    });
    this.subscription = subscription;

    if (this.paymentGateways.stripe && this.paymentGateways.stripe.publishable_key) {
      const stripe = await loadStripe(this.paymentGateways.stripe.publishable_key, {
        locale: 'en', // TODO: get from WPML
      });
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      stripeMountCard(this.$store, this.paymentMetas);
    }
  },
};
</script>

<template>
  <div
    ref="gallery"
    class="gallery"
  >
    <div
      v-for="(item, index) in data.items"
      :key="index"
      :class="['item', item.acf && item.acf.half_size ? 'item-half' : false]"
      :data-item-id="item.id"
    >
      <Figure
        :ref="index"
        :data="{...item, lazyload: false, ratio: data.ratio, object_fit: data.object_fit, showCaption: data.showCaption }"
      />
    </div>

    <div
      v-if="!$mq.isMobile && data.items.length > 1"
      class="navigation"
    >
      <div
        class="prev"
        data-cursor="Prev"
        @click="prev"
      />
      <div
        class="next"
        data-cursor="Next"
        @click="next"
      />
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        draggable: this.$mq.isMobile,
        prevNextButtons: this.$mq.isMobile && this.data.length > 1,
        wrapAround: true,
        arrowShape: 'M35.5923 50.0002L71.2266 11.1264L68.7694 8.87402L31.0704 50.0002L68.7694 91.1264L71.2266 88.874L35.5923 50.0002Z',
        pageDots: false,
        accessibility: false,
      },
    };
  },
  mounted() {
    this.gallery = new Flickity(this.$refs.gallery, Object.assign(this.settings, this.extraSettings));
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
  methods: {
    prev() {
      this.gallery.previous();
    },
    next() {
      this.gallery.next();
    },
  },
};
</script>

<style lang="scss">
.gallery {
  overflow: hidden;
  background: inherit;
  .navigation {
    position: absolute;
    mix-blend-mode: difference;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - var(--bar-height));
    z-index: 2;

    display: flex;
    justify-content: space-between;
    pointer-events: none;

    & > * {
      flex: 0 0 33.33%;
      pointer-events: auto;
    }
  }

  .flickity-viewport {
    overflow: visible;
  }
  .item {
    width: 100%;
    background: inherit;
    &.item-half {
      @include mq(m) {
        width: 50%;
      }
    }

    margin-right: 2px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      top: 0;
      height: 100%;
      background: inherit;
    }
    &::before {
      left: -1px;
    }
    &::after {
      right: -1px;
    }
  }
}
</style>

<template>
  <figure
    :class="[
      'picture',
      !data.ratio ? 'no-ratio' : false,
      data.relative ? 'picture-relative' : false,
      data.object_fit ? `picture-${data.object_fit}` : 'picture-cover',
      data.align ? `align--${data.align}` : false,
    ]"
  >
    <picture
      v-if="imageType !== 'svg' && mounted"
      :style="{ paddingBottom: data.ratio ? `${ratio}%` : false }"
    >
      <source
        v-for="(v, i) in transform"
        :key="i"
        :media="v.media"
        :srcset="v.src"
        :type="`image/jpeg`"
      >
      <img
        ref="image"
        class="image"
        :lazy="data.lazyload ? 'lazy' : undefined"
        :alt="defaultImage.alt"
        :data-id="defaultImage.id"
        @load="loading"
      >
      <div
        v-if="pending"
        class="placeholder"
      />
    </picture>
    <div
      v-else-if="imageType === 'inline-svg'"
      class="svg"
      v-html="data.svg"
    />
    <div v-else-if="imageType === 'svg'">
      <img
        ref="image"
        class="svg"
        :src="defaultImage.url"
        :alt="defaultImage.alt"
      >
    </div>
    <Spacer
      v-if="caption !== '' && data.showCaption"
      tag="figcaption"
      x="s"
      y="xs"
      v-html="caption"
    />
  </figure>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    data: {
      type: Object,
      required: true,
    },
    skipLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
      ratio: 0,
      pending: true,
      mounted: false,
    };
  },
  computed: {
    defaultImage() {
      if (this.data.default) {
        return this.data.default;
      }
      return this.data;
    },
    imageType() {
      if (this.data.type === 'svg') {
        return 'inline-svg';
      }
      if (this.defaultImage?.subtype === 'svg+xml') {
        return 'svg';
      }
      return 'image';
    },
    caption() {
      let text = '';
      if (this.data.title && this.data.title !== '') {
        text += `${this.data.title}`;
      }

      if (this.data.caption && this.data.caption !== '') {
        if (text !== '') text += '<br> ';

        text += `${this.data.caption}`;
      }

      return text;
    },
    transform() {
      return {
        xxl: {
          media: `(min-width: ${this.calcSize(3000)}px)`,
          src: this.defaultImage.sizes.xxl,
        },
        xl: {
          media: `(min-width: ${this.calcSize(2000)}px)`,
          src: this.defaultImage.sizes.xl,
        },
        l: {
          media: `(min-width: ${this.calcSize(1400)}px)`,
          src: this.defaultImage.sizes.l,
        },
        m: {
          media: '(min-width: 0px)',
          src: this.defaultImage.sizes.m,
        },
      };
    },
  },
  mounted() {
    this.mounted = true;
    if (this.imageType === 'image') {
      this.currentSrc();
      this.setRatio();
      this.$bus.$on('windowResized', this.currentSrc);
    }

    // if (!this.data.lazyload && !this.skipLoad) {
    //   this.load();
    // }
  },
  beforeDestroy() {
    if (this.imageType === 'image') {
      this.$bus.$off('windowResized', this.currentSrc);
    }
  },
  methods: {
    loading() {
      this.pending = !this.$refs.image.complete;
    },
    currentSrc() {
      if (this.$mq.isMobile && this.data.mobile) {
        this.currentImg = 'mobile';
        this.setRatio();
      } else {
        this.currentImg = 'default';
        this.setRatio();
      }
    },
    setRatio() {
      if (!this.data && !this.defaultImage) this.ratio = 0;
      this.ratio = this.currentImg === 'default'
        ? (this.defaultImage.height / this.defaultImage.width) * 100
        : (this.data.mobile.height / this.data.mobile.width) * 100;
    },
    calcSize(v) {
      const doubleParameter = this.data.double_size === true ? 2 : 1;
      const { offsetWidth: width, offsetHeight: height } = this.$parent.$el;
      const refSize = width >= height ? width : height;
      return Math.round(((v / 1.5) * window.innerWidth) / doubleParameter / refSize / 2);
    },
    // async load() {
    //   const img = this.$refs.image;

    //   if (this.skipLoad && this.src) {
    //     img.src = this.src;
    //   }

    //   if (img && img.decode && img.src) {
    //     await img.decode();
    //     this.loaded = true;
    //   } else {
    //     this.loaded = true;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.picture {
  position: relative;
  overflow: hidden;
  width: 100%;

  picture {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &.no-ratio {
    height: 100%;

    picture {
      height: 100%;
    }

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: none;
    }

    &.picture-relative {
      .image {
        position: relative;
        max-width: 100%;
      }
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.align--top {
    .image {
      object-position: 50% 0;
    }
  }

  &.align--center {
    .image {
      object-position: 50% 50%;
    }
  }

  &.align--bottom {
    .image {
      object-position: 50% 100%;
    }
  }

  &.picture-contain {
    .image {
      object-fit: contain;
    }
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.2s $ease-custom;
    will-change: opacity;

    background: var(--grey-xxl);
  }

  figcaption {
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    @extend %typo--xs;
    color: var(--white);
    mix-blend-mode: difference;

    /* @include mq(m) {
      max-width: 50%;
    } */

    &:hover {
      mix-blend-mode: unset;
      background: var(--white);
      color: var(--grey-xxd);
    }
  }
}
</style>

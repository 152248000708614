var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{attrs:{"data-template":_vm.post.acf.template}},[_c('article',{ref:"article",class:_vm.background},[_c('section',{staticClass:"intro"},[_c('div',{staticClass:"abstract"},[_c('Spacer',{staticClass:"abstract-wrapper",attrs:{"top":"xs","x":"s"}},[_c('Flex',{staticClass:"wrapper",attrs:{"dir":"column"}},[_c('Spacer',{attrs:{"bottom":"xxl"}},[_c('Title',{staticClass:"title",attrs:{"data":{ value: _vm.post.title.rendered }}}),(
                  _vm.post.gds_taxonomies.tax_author ||
                    (_vm.post.acf.subtitle && _vm.post.acf.subtitle !== '')
                )?_c('Title',{staticClass:"title",attrs:{"data":{
                  value:
                    _vm.post.acf.subtitle && _vm.post.acf.subtitle !== ''
                      ? _vm.post.acf.subtitle
                      : `by ${_vm.post.gds_taxonomies.tax_author[0].name}`,
                }}}):_vm._e()],1)],1)],1)],1)]),_c('section',{staticClass:"meta typo--label"},[_c('Spacer',{attrs:{"x":"s"}},[_c('Spacer',{staticClass:"meta-wrapper",attrs:{"top":"1"}},[_c('Flex',{staticClass:"flex-auto",attrs:{"gap":""}},[_c('time',[_vm._v(_vm._s(_vm.date))]),(_vm.post.acf.template !== 'past-issue')?_c('div',{staticClass:"t-center reading"},[_vm._v(" Reading Time "+_vm._s(_vm.remaining)+"′ ")]):_vm._e(),_c('Share',{staticClass:"t-right single--share",attrs:{"data":{
                permalink: _vm.post.link,
                title: _vm.post.title.rendered,
                media: _vm.post.gds_featured_image
                  ? _vm.post.gds_featured_image.url
                  : null,
                theme: 'post',
              }}})],1)],1)],1)],1),_c('Spacer',{attrs:{"bottom":"xxxs"}}),_c('section',{staticClass:"gallery"},[(_vm.post.acf.page_gallery && _vm.post.acf.page_gallery.length > 0)?_c('Media',{staticClass:"media",style:({
          '--ratio': `${_vm.setRatio(_vm.post.acf.page_gallery, 'width', true)}%`,
        }),attrs:{"data":{
          type: 'gallery',
          gallery: _vm.post.acf.page_gallery,
          showCaption: true,
          object_fit: 'contain',
        }}}):(_vm.post.gds_featured_image)?_c('Media',{attrs:{"data":{
          type: 'image',
          image: _vm.post.gds_featured_image,
          ratio: true,
          showCaption: true,
          object_fit: 'contain',
        }}}):_vm._e()],1),_c('section',{ref:"main",staticClass:"main-content"},[(_vm.excerpt)?_c('div',{staticClass:"excerpt"},[_c('Spacer',{attrs:{"all":"s"}},[_c('RichText',{attrs:{"data":{ value: _vm.excerpt, size: 'l' }}})],1)],1):_vm._e(),_c('div',{ref:"content",staticClass:"text"},[_c('Spacer',{attrs:{"bottom":"s"}},_vm._l((_vm.content),function(text,index){return _c('div',{key:index},[(
                _vm.post.acf.template !== 'past-issue' &&
                  index + 1 < _vm.content.length
              )?_c('Adv',{key:index,attrs:{"template":_vm.randomAdv[index % 3]}}):_vm._e(),_c('RichText',{class:[
                'article-wysiwyg',
                _vm.excerpt && index === 0 ? 'with-excerpt' : false,
              ],attrs:{"data":{ value: text }}})],1)}),0)],1)])],1),(_vm.post.acf.template !== 'past-issue' && _vm.category)?_c('section',{staticClass:"related"},[_c('Archive',{attrs:{"data":{
        title: 'Related articles',
        archive_type: 'archive',
        archive: _vm.post.type,
        max_posts: 4,
        thumb: 'horizontal',
        exclude: [_vm.post.id],
        archive_category: {
          taxonomy: _vm.category.taxonomy,
          term_id: _vm.category.term_id,
        },
        layout: [
          {
            media_query: 'default',
            layout_size: 'auto',
            layout_type: 'carousel',
          },
          {
            media_query: 'm',
            layout_size: '3',
            layout_type: 'grid',
          },
        ],
      }}}),_c('Archive',{attrs:{"data":{
        title: 'From the shop',
        archive_type: 'archive',
        archive: 'product',
        max_posts: 4,
        product: _vm.post.acf.related_products,
        layout: [
          {
            media_query: 'default',
            layout_size: 'auto',
            layout_type: 'carousel',
          },
          {
            media_query: 'm',
            layout_size: '3',
            layout_type: 'grid',
          },
        ],
      }}})],1):_vm._e(),_c('section',{staticClass:"footer"},[(_vm.post.acf.template !== 'past-issue')?_c('OverlayFooter',{attrs:{"data":_vm.footer.blocks}}):_vm._e(),(_vm.post.acf.template === 'past-issue' && _vm.post.acf.product)?_c('Block',{staticClass:"issue",attrs:{"data":{
        cta: {
          url: _vm.post.acf.product.url,
          title: 'Buy issue',
        },
        title: _vm.post.acf.product.title,
      }}}):_vm._e()],1),(_vm.extraGallery && _vm.extraImages)?_c('section',{staticClass:"extra-gallery"},[_c('Flex',{staticClass:"ui",attrs:{"justify":"space-between","align":"center"}},[_c('Cta',{attrs:{"data":{
          title:
            _vm.templateExtraGallery === 'carousel'
              ? 'Grid View'
              : 'Gallery View',
          fn: _vm.setTemplateExtraGallery,
          size: 'label',
        }}}),_c('Cta',{attrs:{"data":{
          title: 'Close',
          fn: _vm.closeExtraGallery,
          size: 'label',
        }}})],1),_c('Grid',{attrs:{"template":_vm.templateExtraGallery,"col":{ default: 6, m: 3 },"extra-settings":{
        initialIndex: _vm.currentIndex,
        template: 'single-gallery',
        currentId: _vm.currentId,
      },"row-gap":"xs"}},_vm._l((_vm.extraImages),function(img,index){return _c('div',{key:`gallery-${index}`,staticClass:"item",attrs:{"data-id":img.id},on:{"click":() => {
            _vm.templateExtraGallery === 'grid' ? _vm.setExtraGallery(img.id) : null;
          }}},[_c('Figure',{ref:"figure",refInFor:true,attrs:{"data":{
            ...img,
            ratio: _vm.templateExtraGallery === 'grid',
            object_fit: 'contain',
            showCaption: _vm.templateExtraGallery === 'carousel',
          }}}),(_vm.templateExtraGallery === 'carousel')?_c('div',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.pad(index + 1, 2))+" / "+_vm._s(_vm.pad(_vm.extraImages.length, 2))+" ")]):_vm._e()],1)}),0)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <footer
    v-if="footer"
    class="page-footer typo--s-alt"
  >
    <Grid :col="{ default: 12, m: 3 }">
      <Flex
        v-for="(info, index) in footer.information"
        :key="index"
      >
        <template v-if="$mq.isMobile">
          <AccordionWrapper>
            <AccordionItem>
              <template slot="accordion-trigger">
                <Flex
                  class="col-header"
                  align="center"
                >
                  <div class="typo--label-s">
                    {{ info.title }}
                  </div>
                </Flex>
              </template>
              <template slot="accordion-content">
                <div class="col-body">
                  <template v-if="info.type === 'text'">
                    <div v-html="info.text" />
                  </template>
                  <template v-else-if="info.type === 'menu'">
                    <ul>
                      <li
                        v-for="item in menu(info.menu).items"
                        :key="item.id"
                        :class="[...item.css]"
                      >
                        <Cta
                          :data="{
                            url: item.url,
                            title: item.content,
                            target: item.target,
                          }"
                        />
                      </li>
                    </ul>
                  </template>
                </div>
              </template>
            </AccordionItem>
          </AccordionWrapper>
        </template>
        <template v-else>
          <Flex
            class="col-header"
            align="center"
          >
            <div class="typo--label-s">
              {{ info.title }}
            </div>
          </Flex>
          <div class="col-body">
            <template v-if="info.type === 'text'">
              <div v-html="info.text" />
            </template>
            <template v-else-if="info.type === 'menu'">
              <ul>
                <li
                  v-for="item in menu(info.menu).items"
                  :key="item.id"
                  :class="[...item.css]"
                >
                  <Cta
                    :data="{
                      url: item.url,
                      title: item.content,
                      target: item.target,
                    }"
                  />
                </li>
              </ul>
            </template>
          </div>
        </template>
      </Flex>
    </Grid>
    <Grid
      align="center"
      class="impressum"
    >
      <Column
        :size="{
          default: 12,
          m: footer.credits && footer.credits !== '' ? 9 : 12,
        }"
      >
        <div v-html="footer.description" />
      </Column>
      <Column
        v-if="footer.credits && footer.credits !== ''"
        :size="{ default: 12, m: 3 }"
        class="credits"
      >
        <div v-html="footer.credits" />
      </Column>
    </Grid>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import AccordionItem from '@/components/ui/accordion-item';
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import Cta from '@/components/typo/cta';

export default {
  name: 'Footer',
  components: {
    AccordionItem,
    AccordionWrapper,
    Cta,
  },
  computed: {
    ...mapGetters(['options', 'currentPost', 'menu']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
  mounted() {
    const cookie = document.querySelector('.check-cookie-consent a');
    if (cookie) {
      cookie.addEventListener('click', (e) => {
        e.preventDefault();
        // eslint-disable-next-line
        window._iub.cs.api.openPreferences();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  color: var(--grey-xxd);

  @include mq(m) {
    background: var(--grey-xl);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: var(--bar-height);
      background: var(--white);
    }
  }

  .col-header {
    position: relative;
    background: var(--grey-xl);
    height: var(--bar-height);
    z-index: 1;
    flex: 1 0 auto;

    @include mq(m) {
      padding: var(--spacer-1) var(--spacer-s) 0;
      flex: 0 0 100%;
    }
  }

  .col-social {
    flex: 0 0 100%;
    padding: 0 var(--spacer-s);

    a {
      display: inline-block;
    }
  }

  .col-body {
    flex: 0 0 100%;
    padding: var(--spacer-1) var(--spacer-s) 0;
    background: var(--white);

    @include mq(m) {
      min-height: var(--block-height);
      height: 100%;
    }

    & > * {
      padding-bottom: var(--spacer-s);

      @include mq(m) {
        padding-top: var(--spacer-s);
      }
    }

    li:not(:last-child) {
      margin-bottom: var(--spacer-xxs);
    }

    ::v-deep li:not(:last-child) {
      margin-bottom: var(--spacer-xxs);
    }
  }

  ::v-deep {
    .accordion__trigger {
      background: var(--grey-xl);
    }
  }

  .impressum {
    position: relative;
    z-index: 1;
    min-height: var(--bar-height);
    background: var(--grey-d);
    color: var(--white);
    padding: var(--spacer-xs) var(--spacer-s) m#{a}x(
        var(--spacer-xs),
        env(safe-area-inset-bottom)
      );

    @include mq(m) {
      padding: var(--spacer-1) var(--spacer-s) 0;
    }

    .credits {
      margin-top: var(--spacer-xxs);

      @include mq(m) {
        margin-top: 0;
        padding: 0 var(--spacer-s);
      }
    }
  }
}
</style>

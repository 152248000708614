<template>
  <div class="cursor">
    <Label :data="{ value: value, tag: 'div', size: 'label-xs' }" />
  </div>
</template>

<script>
import gsap from 'gsap';
import Label from '@/components/typo/label';

export default {
  name: 'AppCursor',
  components: {
    Label,
  },
  data() {
    return {
      value: '',
      el: null,
      mouse: {
        x: 0,
        y: 0,
        active: false,
        timeout: null,
        force: false,
        target: null,
      },
    };
  },
  mounted() {
    window.addEventListener('mousemove', (e) => {
      if (this.mouse.force) return;
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
      this.mouse.target = e.target;
      this.mouse.active = true;

      this.el = e.target.dataset.cursor ? e.target : e.target.closest('[data-cursor]');

      if (this.el && this.el.dataset.cursor !== this.value) {
        this.value = this.el.dataset.cursor;
      }

      if (this.el) {
        this.mouse.active = true;
      } else {
        this.mouse.active = false;

        if (!this.mouse.active) {
          this.mouse.active = true;
        }
      }
    });

    this.$aion.add(this.setCursor, 'setCursor');

    gsap.set(this.$el, {
      xPercent: -50,
      yPercent: -50,
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    });
  },
  beforeDestroy() {
    this.$aion.remove('setCursor');
  },
  methods: {
    setCursor() {
      if (!this.mouse.active || this.mouse.force) {
        gsap.killTweensOf(this.$el);
        gsap.set(this.$el, {
          autoAlpha: 0,
        });

        document.body.classList.remove('no-cursor');

        return;
      }

      if (!this.el) {
        gsap.killTweensOf(this.$el);

        gsap.set(this.$el, {
          autoAlpha: 0,
        });

        document.body.classList.remove('no-cursor');
      } else {
        document.body.classList.add('no-cursor');
        gsap.killTweensOf(this.$el);

        gsap.set(this.$el, {
          autoAlpha: this.mouse.active ? 1 : 0,
          x: this.mouse.x,
          y: this.mouse.y,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  position: fixed;
  top: 0;
  left: 0;
  white-space: nowrap;
  z-index: 50;
  pointer-events: none;
  mix-blend-mode: difference;
  color: var(--white);
  width: auto;
}
</style>

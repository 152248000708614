var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('Flex',{attrs:{"justify":"space-between"}},[_c('Spacer',{attrs:{"x":"s"}},[_c('Cta',{attrs:{"data":{
          title: 'Search',
          url: { name: 'Search', query: { type: 'post' } },
        }}})],1),_c('Spacer',{attrs:{"x":"s"}},[_c('Cta',{attrs:{"data":{
          title: !_vm.user ? _vm.$labels.login : _vm.$labels.account,
          url: { name: 'EditAccount' },
        }}})],1),_c('Spacer',{attrs:{"x":"s"}},[_c('Cta',{attrs:{"data":{
          title: _vm.cart && _vm.cart.items ? `${_vm.$labels.cart} (${_vm.cart.item_count})` : _vm.$labels.cart,
          url: _vm.$route.name === 'Checkout' ? '/cart' : false,
          fn: _vm.$route.name !== 'Checkout' ? _vm.toggleCart : false
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
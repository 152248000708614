export default {
  lang: (state) => state.lang,
  defaultLang: (state) => state.wp.i18n.default,
  langs: (state) => state.wp.i18n.langs,
  currentPage: (state) => state.currentPage,
  currentSingle: (state) => state.currentSingle,
  transition: (state) => state.transition,
  snackbar: (state) => state.snackbar,
  overlay: (state) => state.overlay,
  search: (state) => state.search,
  menuStatus: (state) => state.menu,
  goBack: (state) => state.goBack,
};

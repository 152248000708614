<template>
  <nav>
    <Flex justify="space-between">
      <Spacer
        x="s"
      >
        <Cta
          :data="{
            title: 'Search',
            url: { name: 'Search', query: { type: 'post' } },
          }"
        />
      </Spacer>
      <Spacer
        x="s"
      >
        <Cta
          :data="{
            title: !user ? $labels.login : $labels.account,
            url: { name: 'EditAccount' },
          }"
        />
      </Spacer>
      <Spacer
        x="s"
      >
        <Cta
          :data="{
            title: cart && cart.items ? `${$labels.cart} (${cart.item_count})` : $labels.cart,
            url: $route.name === 'Checkout' ? '/cart' : false,
            fn: $route.name !== 'Checkout' ? toggleCart : false
          }"
        />
      </Spacer>
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'WCMenu',
  components: {
    Cta,
  },
  computed: {
    ...mapGetters(['user', 'cart']),
  },
  methods: {
    toggleCart() {
      this.$store.commit('TOGGLE_CART');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

import Vue from 'vue';
// import Cookies from 'js-cookie';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import VTooltip from 'v-tooltip';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';
import sessionHandler from '@/assets/js/sessionHandler';

import { loadScript } from '@/assets/js/utils';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/js/eventBus';
import '@/assets/js/marketing';
import '@/assets/js/mq';
import '@/assets/js/raf';
import '@/assets/js/layout';

// eslint-disable-next-line no-undef
const { url: baseUrl } = __VUE_WORDPRESS__.routing;
Vue.prototype.$relativeUrl = (url) => (url ? url.replace(baseUrl, '').replace(process.env.VUE_APP_WP_URL, '/') : false);

sync(store, router);
Vue.config.productionTip = false;

Vue.use(InlineSvgPlugin);

Vue.use(VTooltip);
Vue.use(Vue2TouchEvents);

// eslint-disable-next-line no-undef
const { options } = __VUE_WORDPRESS__.state;

if (
  options
  && options.extra
  && options.cookies
  && options.extra.ga_id
  && options.cookies.id
  && process.env.NODE_ENV !== 'development'
) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: options.extra.ga_id,
        params: {
          anonymize_ip: true,
        },
      },
      disableScriptLoad: true,
      // bootstrap: !!Cookies.get(options.cookies.id),
      bootstrap: true,
    },
    router,
  );
}

sessionHandler.init({
  onInitCompleted: () => {
    store.dispatch('initActions');
  },
});

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

router.onReady(() => app.$mount('#app'));

// eslint-disable-next-line
window._iub = [];
// eslint-disable-next-line
window._iub.csConfiguration = {
  lang: 'en-GB',
  siteId: 2652927,
  cookiePolicyId: 56875151,
  consentOnScroll: false,
  consentOnContinuedBrowsing: false,
  // invalidateConsentWithoutLog: true,
  banner: {
    applyStyles: false,
    position: 'bottom',
    slideDown: 'true',
    content: 'We use cookies. To find out more, read our %{cookie_policy_link}.',
    cookiePolicyLinkCaption: 'Cookie Policy',
    acceptButtonDisplay: true,
    rejectButtonDisplay: true,
    explicitWithdrawal: true,
    closeButtonDisplay: false,
  },
  callback: {
    onConsentRejected: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
        });
      }
    },
    onBannerClosed: () => {
      Vue.prototype.$bus.$emit('iubendaClose');
    },
    onBannerShown: () => {
      Vue.prototype.$bus.$emit('iubendaReady');
    },
  },
  preferenceCookie: {
    expireAfter: 180,
  },
};

loadScript('https://cdn.iubenda.com/cs/iubenda_cs.js');

<template>
  <div>
    <div v-if="$mq.isMobileS">
      <Spacer
        bottom="xl"
        :use-margin="true"
        class="t-center checkout-order__mobile-static"
      >
        <Grid
          col="12"
          row-gap="m"
        >
          <Cta
            :data="{
              title: $labels.place_order,
              fn: placeOrder,
            }"
            theme="button"
          />
          <Checkbox
            v-model="terms.model"
            :data="terms"
            :model.sync="terms.model"
          >
            <label
              slot="label"
              for="terms"
            >{{ $labels[terms.label] }} <sup
              v-html="'*'"
            /></label>
          </Checkbox>
        </Grid>
      </Spacer>
      <transition name="fade">
        <div
          v-if="showMobileModal"
          class="overlay"
          @click="showMobileModal = !showMobileModal"
        />
      </transition>
      <Grid
        v-touch:swipe="swipeHandlerMobileModal"
        col="12"
        class="checkout-order__mobile-modal block-theme--default spacer spacer--padding--a--s"
        :class="{ 'checkout-order__mobile-modal--visible': showMobileModal }"
      >
        <div @click="showMobileModal = !showMobileModal">
          <Flex
            justify="space-between"
            align="center"
          >
            <Spacer
              class="typo--label"
              bottom="xs"
            >
              {{ $labels.show_order_summary }}
            </Spacer>
            <transition name="fade">
              <Spacer
                v-if="!showMobileModal"
                bottom="xs"
                class="typo--label"
              >
                <span
                  v-html="parseCurrency(unparseCurrency(cart.totals.total))"
                />
              </Spacer>
            </transition>
          </Flex>
        </div>
        <CartList theme="checkout" />
        <div class="separator--row" />
        <CartTotals />
      </Grid>
    </div>
    <Grid
      v-else
      col="12"
      class="block-theme--default"
      row-gap="m"
    >
      <div class="typo--s">
        {{ $labels.order_details }}
      </div>
      <CartList theme="checkout" />
      <CartTotals />
      <div>
        <Cta
          :data="{
            title: $labels.place_order,
            fn: placeOrder,
          }"
          theme="button"
        />
      </div>
      <Checkbox
        v-model="terms.model"
        :data="terms"
        :model.sync="terms.model"
      >
        <label
          slot="label"
          for="terms"
        >{{ $labels[terms.label] }} <sup
          v-html="'*'"
        /></label>
      </Checkbox>
    </Grid>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  parseCurrency,
  unparseCurrency,
  exclVatLabel,
} from '@/assets/js/utils-wc';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Checkbox from '@/components/form/fields/checkbox';
import CartTotals from '@/components/wc/cart-totals';
import CartList from '@/components/wc/cart-list';
import Cta from '@/components/typo/cta';

export default {
  name: 'CheckoutOrder',
  components: {
    CartTotals,
    CartList,
    Checkbox,
    Cta,
  },
  data() {
    return {
      acceptTerms: false,
      invalid: true,
      invalidFromTerms: true,
      invalidFromForm: true,
      // products: [],
      showMobileModal: false,
      terms: {
        id: 'terms',
        type: 'checkbox',
        checked: false,
        model: false,
        label: 'terms',
        fn: this.setAcceptTerms,
      },
    };
  },
  computed: {
    ...mapGetters(['cart', 'wcSettings']),
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
  },
  watch: {
    invalidFromTerms(val) {
      if (!val && !this.invalidFromForm) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    invalidFromForm(val) {
      if (!val && !this.invalidFromTerms) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    showMobileModal(value) {
      if (value) {
        disableBodyScroll(document.querySelector('html'));
      } else {
        clearAllBodyScrollLocks();
      }
    },
  },
  async mounted() {
    this.$bus.$on('validForm', this.formInvalid);
    // const request = {
    //   type: 'product',
    //   params: {
    //     // include: [this.cart.items.map((x) => x.product_id)],
    //     per_page: 100,
    //     offset: 0,
    //     lang: this.$store.state.lang,
    //   },
    // };
    // this.products = await this.$store.dispatch('getItems', request);
  },
  beforeDestroy() {
    this.$bus.$off('validForm', this.formInvalid);
  },
  methods: {
    getLineTotal(cartItem) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(
          this.unparseCurrency(cartItem.line_subtotal)
            + this.unparseCurrency(cartItem.line_subtotal_tax),
        );
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(
          this.unparseCurrency(cartItem.line_subtotal),
        )}  ${exclVatLabel(this.$labels.excluded_vat)}`;
      }
      return this.parseCurrency(
        this.unparseCurrency(cartItem.line_subtotal)
          + this.unparseCurrency(cartItem.line_subtotal_tax),
      );
    },
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
    unparseCurrency(price) {
      return unparseCurrency(price, this.wcSettings);
    },
    placeOrder() {
      this.$bus.$emit('submitForm', 'checkout');
    },
    setAcceptTerms() {
      this.acceptTerms = !this.acceptTerms;
      this.invalidFromTerms = !this.acceptTerms;
    },
    formInvalid(data) {
      if (data && data.id === 'checkout') {
        this.invalidFromForm = data.invalid;
      }
    },
    swipeHandlerMobileModal(dir) {
      if (dir === 'top') {
        this.showMobileModal = true;
      }
      if (dir === 'bottom') {
        this.showMobileModal = false;
      }
    },
  },
};
</script>

<style lang="scss">
.checkout-order__mobile-modal {
  position: fixed;
  bottom: 36px;
  background: #F6F6F6;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transition: all 0.6s $ease-custom;
  z-index: 20;
  max-height: calc(var(--vh) * 100 - #{$unit * 8});
  overflow: auto;

  &--visible {
    transform: translateY(0);
    bottom: 0;
  }
}
</style>

<template>
  <Grid
    v-if="loginApps && loginApps[0]"
    grid="1"
  >
    <Cta
      v-for="(loginApp, index) in loginApps"
      :key="index"
      :data="{
        title: `Sign in with <span class='t-capitalize'>${loginApp}</span>`,
        toggle: 'icon',
        fn: () => loginWithApp(loginApp),
      }"
      theme="login"
    >
      <template #icon>
        <Icon
          :name="loginApp"
          size="s"
        />
      </template>
    </Cta>
  </Grid>
</template>

<script>
import Cta from '@/components/typo/cta';
import Icon from '@/components/ui/icon';

import Cookies from 'js-cookie';

export default {
  name: 'LoginSocial',
  components: {
    Cta,
    Icon,
  },
  computed: {
    loginApps() {
      if (this.$store.state.wp.session.social_apps) {
        return this.$store.state.wp.session.social_apps;
      }
      return [];
    },
  },
  methods: {
    loginWithApp(appName) {
      /* eslint-disable no-undef */
      Cookies.set('gds-after-social-login-url', this.$route.fullPath, {
        expires: 1,
      });
      moOpenIdLogin(appName, 'true');
    },
  },
};
</script>

<style></style>

<template>
  <main>
    <img
      ref="clippy"
      class="clippy"
      :src="require('@/assets/img/clippy.png')"
    >
  </main>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'NotFound',
  mounted() {
    gsap.set(this.$refs.clippy, {
      scale: 0,
    });

    gsap.to(this.$refs.clippy, {
      scale: 1,
      ease: 'elastic.out(.3,.1)',
      duration: 1,
      delay: 0.5,
    });
  },
};
</script>

<style lang="scss" scoped>
main {
  position: relative;
}
.clippy {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  max-height: 600px;

  transform-origin: 100% 100%;
}
</style>

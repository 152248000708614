<template>
  <Grid
    col="12"
    row-gap="s"
  >
    <Form
      id="edit-subscription-address"
      :name="$labels.shipping_address"
      class="form--single form--no-accordion"
    />
  </Grid>
</template>

<script>
import { mapGetters } from 'vuex';

import Form from '@/components/form';

export default {
  name: 'EditAccount',
  components: {
    Form,
  },
  computed: {
    ...mapGetters(['user', 'paymentGateways']),
  },
  created() {
    if (!this.$route.query.subscription) {
      this.$router.push({ name: 'EditAccount', hash: `#edit-${this.$route.params.addressType}-address` });
    }
  },
  mounted() {
    if (this.user) {
      // Ricarica dati se utente cambia al checkout
      this.$store.dispatch('loadCustomerData');
    }
  },
  methods: {

  },
};
</script>

<template>
  <ValidationProvider
    v-if="!data.hidden"
    v-slot="{ errors, classes }"
    mode="lazy"
    :rules="data.rules"
    :vid="data.vid"
    tag="div"
  >
    <div :class="['field field--input', classes, { 'empty': labelEmpty }, { 'focus': labelFocus }]">
      <slot
        name="label"
        class="field-label"
      />
      <div class="field-input">
        <input
          :id="formId ? `${formId}-${data.id || $vnode.key}` : data.id || $vnode.key"
          :ref="data.vid ? data.vid : null"
          v-model.trim="computedModel"
          :name="data.id || $vnode.key"
          :type="data.type"
          :readonly="data.readonly"
          spellcheck="false"
          :placeholder="computedPlaceholder"
          @change="data.fn ? data.fn() : false"
          @blur="labelPosition"
          @focus="labelPosition"
        >
        <Icon
          name="alert"
          theme="warning"
          size="xs"
        />
        <Icon
          v-if="data.tooltip"
          v-tooltip.top-center="data.tooltip"
          name="help"
          size="xs"
        />
      </div>
    </div>

    <div
      slot="errors"
      class="input-errors"
    >
      {{ errors[0] }}
    </div>
  </ValidationProvider>
</template>

<script>
import Icon from '@/components/ui/icon';
import isEmpty from 'lodash.isempty';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Input',
  components: {
    ValidationProvider,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      default: '',
    },
    formId: {
      type: String,
      default: () => { },
    },
  },
  data() {
    return {
      labelFocus: false,
    };
  },
  computed: {
    labelEmpty() {
      return isEmpty(this.computedModel);
    },
    computedPlaceholder() {
      if (this.data.placeholder && this.labelFocus) {
        return this.$labels[this.data.placeholder];
      }
      return null;
    },
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('update:model', val);
      },
    },
  },
  methods: {
    labelPosition(e) {
      if (e.type === 'focus') {
        this.labelFocus = true;
      } if (e.type === 'blur') {
        this.labelFocus = false;
      }
    },
  },
};
</script>

<style>

</style>

<template>
  <div class="saved-cards block-theme--default">
    <AccordionWrapper>
      <AccordionItem>
        <template slot="accordion-trigger">
          <div class="form-wrapper__header">
            <div class="form-wrapper__title typo--m">
              {{ $labels.saved_cards }}
            </div>
          </div>
        </template>
        <template slot="accordion-content">
          <Wrapper
            size="m"
            :margin="false"
          >
            <Spacer
              v-if="!customerSavedMethodsSorted[0]"
              bottom="s"
              x="s"
            >
              {{ $labels.no_saved_card }}.
            </Spacer>
            <Spacer
              v-else
              bottom="s"
            >
              <Grid
                v-for="(savedMethod, index) in customerSavedMethodsSorted"
                :key="index"
                column-gap="m"
              >
                <Column
                  :size="{
                    default: 8,
                    s: 6,
                  }"
                  class="saved-cards__grid-element-left saved-cards__grid-element-left-top"
                >
                  <Spacer
                    x="s"
                  >
                    {{ savedMethod.method.brand }}
                    {{ $labels.ending_in }}
                    {{ savedMethod.method.last4 }}
                    ({{ $labels.expires }} {{ savedMethod.expires }})
                  </Spacer>
                </Column>
                <Column
                  :size="{
                    default: 4,
                    s: 6,
                  }"
                >
                  <Spacer
                    x="s"
                  >
                    <Flex
                      align="center"
                      justify="end"
                    >
                      <Spacer
                        v-for="(action, i) in getAvailableActions(savedMethod)"
                        :key="i"
                        :right="
                          i !== getAvailableActions(savedMethod).length - 1 &&
                            !$mq.isMobileS
                            ? 'xs'
                            : false
                        "
                        :bottom="
                          i !== getAvailableActions(savedMethod).length - 1 &&
                            $mq.isMobileS
                            ? 'xs'
                            : false
                        "
                      >
                        <Cta
                          v-if="action === 'delete'"
                          :data="{
                            title: $labels.delete,
                            fn: () => openModal(savedMethod),
                          }"
                          theme="button"
                        />
                        <Cta
                          v-if="action === 'make-default'"
                          :data="{
                            title: $labels.make_default,
                            fn: () => makeDefaultCard(savedMethod),
                          }"
                          theme="button"
                        />
                      </Spacer>
                    </Flex>
                  </Spacer>
                </Column>
              </Grid>
            </Spacer>
            <!-- <hr> -->
            <Spacer
              bottom="s"
              x="s"
            >
              <Spacer
                bottom="s"
                class="typo--m"
              >
                {{ $labels.add_new_card }}
              </Spacer>
              <Grid>
                <Column
                  :size="{
                    default: 12,
                    s: 8,
                  }"
                  class="saved-cards__grid-element-left"
                >
                  <div id="card-element">
                    <!-- Elements will create input elements here -->
                  </div>
                  <p
                    id="card-errors"
                    role="alert"
                  >
                    <!-- Error messages will be shown in this element -->
                  </p>
                </Column>
                <Column
                  :size="{
                    default: 12,
                    s: 4,
                  }"
                  class="saved-cards__grid-element-right"
                >
                  <Spacer
                    bottom="xs"
                    :top="$mq.isMobileS ? 'm' : false"
                  >
                    <Cta
                      :data="{ title: $labels.add, fn: addCard }"
                      theme="button"
                    />
                  </Spacer>
                </Column>
              </Grid>
            </Spacer>
          </Wrapper>
        </template>
      </AccordionItem>
    </AccordionWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import orderBy from 'lodash.orderby';

import { loadStripe } from '@stripe/stripe-js';
import {
  stripeMountCard,
  addCardToCustomerSavedMethods,
} from '@/assets/js/utils-wc';

import { setDefaultPaymentSource, deletePaymentSource } from '@/api';

import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';
import Cta from '@/components/typo/cta';

export default {
  name: 'CartList',
  components: {
    AccordionWrapper,
    AccordionItem,
    Cta,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['paymentGateways', 'paymentMetas', 'customerSavedMethods']),
    customerSavedMethodsSorted() {
      return orderBy(this.customerSavedMethods, ['is_default'], ['desc']);
    },
  },
  watch: {},
  async mounted() {
    if (
      this.paymentGateways.stripe
      && this.paymentGateways.stripe.publishable_key
    ) {
      const stripe = await loadStripe(
        this.paymentGateways.stripe.publishable_key,
        {
          locale: 'en', // TODO: get from WPML
        },
      );
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      stripeMountCard(this.$store, this.paymentMetas, {
        order_id: this.$route.params.orderId,
        key: this.$route.query.key,
      });
    }
  },
  methods: {
    async addCard() {
      this.$store.commit('SET_CART_LOADING', true);
      await addCardToCustomerSavedMethods(this.$store, {
        stripeInstance: this.paymentMetas.stripe,
      });
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    getAvailableActions(method) {
      const actions = ['delete'];
      if (!method.is_default) {
        actions.unshift('make-default');
      }
      return actions;
    },
    async deleteCard(card) {
      this.$store.commit('SET_CART_LOADING', true);
      await deletePaymentSource({ payment_source_id: card.token_key });
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    async makeDefaultCard(card) {
      this.$store.commit('SET_CART_LOADING', true);
      await setDefaultPaymentSource({ payment_source_id: card.token_key });
      this.$store.dispatch('loadCustomerData');
      this.$store.commit('SET_CART_LOADING', false);
    },
    openModal(card) {
      this.$bus.$emit('modal', {
        type: 'dialog',
        id: 'cancelCars',
        content: {
          richtext: this.$labels.cancel_card_dialog,
          cta: {
            title: this.$labels.cancel_card,
          },
        },
        fn: () => this.deleteCard(card),
      });
    },
  },
};
</script>

<style lang="scss">
.saved-cards .accordion__trigger .form-wrapper__header {
  padding-bottom: 4px;
}

.saved-cards__grid-element-left-top {
  align-self: center;

  @include mq(s) {
    display: flex;
    align-self: flex-start;
    align-items: baseline;
    padding-top: 6px;
  }
}

.saved-cards__grid-element-right {
  @include mq(s) {
    text-align: right;
    .cta {
      display: inline-block !important;
    }
  }
}

#card-element {
  padding-top: 4px;
}
</style>

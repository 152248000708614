<template>
  <Block
    :data="{
      cta: {
        title: 'Back to top',
        fn: fn,
      },
      icon: 'arrow-top',
      theme: 'go-top',
    }"
  />
</template>

<script>
import Block from '@/components/blocks/block';

export default {
  name: 'GoTop',
  components: {
    Block,
  },
  methods: {
    fn() {
      window.scroll(0, 0);
    },
  },
};
</script>

<style>

</style>

<template>
  <Spacer
    top="s"
    x="s"
  >
    <Grid
      col="12"
      row-gap="m"
    >
      <div v-if="order">
        <Wrapper
          v-if="!isOrderPayable"
          size="s"
        >
          <Grid
            col="12"
            class="block-theme--default"
          >
            <div class="t-center">
              {{ $labels.cannot_pay_for_order }}.
            </div>
            <Flex justify="center">
              <Cta
                :data="{
                  title: $labels.go_to_orders,
                  url: '/my-account/orders',
                }"
                theme="button"
              />
            </Flex>
          </Grid>
        </Wrapper>
        <Grid
          v-else
          align="baseline"
        >
          <Column :size="{ default: 12, s: 6, l: 8 }">
            <Grid
              col="12"
              class="block-theme--default"
              row-gap="m"
            >
              <div class="typo--m">
                {{ $labels.pay_for_order }}
              </div>
              {{ $labels.order_details }}
              <OrderHeader
                :order="order"
                theme="hide-actions"
              />
              <div class="separator--row" />
              <OrderDetails
                :order="order"
                theme="hide-actions"
              />
            </Grid>
          </Column>
          <Column :size="{ default: 12, s: 6, l: 4 }">
            <Grid
              col="12"
              class="block-theme--default"
              row-gap="m"
            >
              <Spacer
                x="s"
                class="typo--m"
              >
                {{ $labels.payment_methods }}
              </Spacer>
              <Form
                id="pay"
                theme="plain"
              />
            </Grid>
            <Column />
          </column>
        </Grid>
      </div>
    </Grid>
  </Spacer>
</template>

<script>
import OrderHeader from '@/components/wc/order-header';
import Cta from '@/components/typo/cta';
import OrderDetails from '@/components/wc/order-details';
import Form from '@/components/form';

import { mapGetters } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import {
  stripeMountCard,
  availableOrderActions,
} from '@/assets/js/utils-wc';

export default {
  name: 'PayOrder',
  components: {
    OrderHeader,
    OrderDetails,
    Form,
    Cta,
  },
  data() {
    return {
      order: null,
    };
  },
  computed: {
    ...mapGetters(['paymentGateways', 'paymentMetas']),
    isOrderPayable() {
      if (this.order) {
        const orderActions = availableOrderActions[`wc-${this.order.status}`];
        return orderActions.includes('pay');
      }
      return false;
    },
  },
  async mounted() {
    const { data } = await this.$store.dispatch('getOrder', {
      key: this.$route.query.key,
      order_id: this.$route.params.orderId,
    });

    if (!this.$route.query.key) {
      this.$router.replace({
        query: { key: data.key },
      });
    }

    this.order = data;

    if (this.paymentGateways.stripe && this.paymentGateways.stripe.publishable_key) {
      const stripe = await loadStripe(this.paymentGateways.stripe.publishable_key, {
        locale: 'en', // TODO: get from WPML
      });
      this.$store.commit('SET_PAYMENT_METAS', {
        id: 'stripe',
        key: 'stripe',
        meta: stripe,
      });
      stripeMountCard(this.$store, this.paymentMetas);
    }
  },
};
</script>

export default {
  data() {
    return {
      post: null,
    };
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    preview: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.setData(this.$route);
  },
  methods: {
    setData(to) {
      if (this.preview) {
        this.post = this.preview;
        return;
      }
      const request = {
        type: null,
        slug: null,
      };
      request.type = this.type || to.meta.type || 'pages';

      if (this.slug) {
        request.slug = this.slug;
      } else if (to.params.slug) {
        request.slug = to.params.slug;
      } else if (to.meta.slug) {
        request.slug = to.meta.slug;
      }

      const stateData = this.$store.state.wp[request.type].default;
      this.post = stateData[encodeURI(request.slug).toLowerCase()];
      this.post = Object.assign(this.post, {
        children: Object.values(stateData).filter(
          (d) => d.parent === this.post.id,
        ),
      });

      // TODO current default & current overlay
      if (request.type !== 'pages') {
        this.$store.commit('SET_CURRENT_SINGLE', this.post);
      } else {
        this.$store.commit('SET_CURRENT_PAGE', this.post);
      }
    },
  },
};

import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      settings: {
        title: this.$labels.shipping_subscription_address,
        sendLabel: 'save',
      },
      groups: {
        shipping: {
          visible: true,
          fields: {
            first_name: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'first_name',
              rules: 'required',
              size: 6,
            },
            last_name: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'last_name',
              rules: 'required',
              size: 6,
            },
            company: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'company',
            },
            address_1: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'address_1',
              size: 6,
              rules: 'required',
            },
            address_2: {
              type: 'text',
              model: '',
              placeholder: 'address_2_placeholder',
              label: 'address_2',
              size: 6,
            },
            city: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'city',
              rules: 'required',
              size: 6,
            },
            country: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'country',
              rules: 'required',
              options: {},
              size: 6,
              fn: () => {
                this.setStates();
              },
            },
            state: {
              type: 'select',
              model: '',
              placeholder: null,
              label: 'state',
              rules: 'required',
              options: {},
              size: 6,
            },
            postcode: {
              type: 'text',
              model: '',
              placeholder: null,
              label: 'postcode',
              rules: 'required',
              size: 6,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      geoData: 'geoData',
    }),
  },
  methods: {
    async init() {
      this.settings.title = `${this.$labels.shipping_subscription_address} #${this.$route.query.subscription}`;
      this.countries = this.geoData.countries;
      this.states = this.geoData.states;

      const subscription = await this.$store.dispatch('getSubscription', {
        key: this.$route.query.key,
        subscription_id: this.$route.query.subscription,
      });

      this.groups.shipping.fields.first_name.model = subscription.shipping.first_name ? subscription.shipping.first_name : '';
      this.groups.shipping.fields.last_name.model = subscription.shipping.last_name ? subscription.shipping.last_name : '';
      this.groups.shipping.fields.company.model = subscription.shipping.company ? subscription.shipping.company : '';
      this.groups.shipping.fields.address_1.model = subscription.shipping.address_1 ? subscription.shipping.address_1 : '';
      this.groups.shipping.fields.address_2.model = subscription.shipping.address_2 ? subscription.shipping.address_2 : '';
      this.groups.shipping.fields.country.model = subscription.shipping.country ? subscription.shipping.country : '';
      this.groups.shipping.fields.city.model = subscription.shipping.city ? subscription.shipping.city : '';
      this.groups.shipping.fields.state.model = subscription.shipping.state ? subscription.shipping.state : '';
      this.groups.shipping.fields.postcode.model = subscription.shipping.postcode ? subscription.shipping.postcode : '';

      Object.assign(this.groups.shipping.fields.country, { ...this.groups.shipping.fields.country, options: this.countries, model: this.customerShipping && this.customerShipping.shipping ? this.customerShipping.shipping.country && this.customerShipping.shipping.country !== '' ? this.customerShipping.shipping.country : 'IT' : 'IT' });

      if (this.groups.shipping.fields.country.model) {
        Object.assign(this.groups.shipping.fields.state, { ...this.groups.shipping.fields.state, options: this.states[this.groups.shipping.fields.country.model], model: this.customerShipping && this.customerShipping.shipping ? this.customerShipping.shipping.state : 'RM' });
      }
    },
    setStates() {
      Object.assign(this.groups.shipping.fields.state, { ...this.groups.shipping.fields.state, options: this.states[this.groups.shipping.fields.country.model], model: this.customerShipping.shipping.state || 'RM' });
    },
    async callback() {
      const body = {
        first_name: this.groups.shipping.fields.first_name.model,
        last_name: this.groups.shipping.fields.last_name.model,
        company: this.groups.shipping.fields.company.model,
        address_1: this.groups.shipping.fields.address_1.model,
        address_2: this.groups.shipping.fields.address_2.model,
        country: this.groups.shipping.fields.country.model,
        city: this.groups.shipping.fields.city.model,
        state: this.groups.shipping.fields.state.model,
        postcode: this.groups.shipping.fields.postcode.model,
      };

      await this.$store.dispatch('updateSubscriptionShipping', {
        params: {
          subscription_id: this.$route.query.subscription,
        },
        body,
      });
    },
  },
};

<template>
  <Spacer
    :x="theme !== 'simple' ? 's' : undefined"
    bottom="s"
  >
    <Grid
      col="12"
      row-gap="l"
    >
      <div class="typo--m">
        {{ $labels.subscription_details }}
      </div>

      <Grid
        col="12"
        row-gap="m"
      >
        <Flex
          justify="space-between"
        >
          <div>{{ $labels.status }}</div>
          <div
            class="t-right"
            v-html="subscriptionStatus"
          />
        </Flex>
        <Flex
          v-if="subscription.subscription_data.schedule_next_payment"
          justify="space-between"
        >
          <div>{{ $labels.next_payment_date }}</div>
          <div
            class="t-right"
            v-html="nextPayment"
          />
        </Flex>
        <Flex
          justify="space-between"
        >
          <div>{{ $labels.payment_method }}</div>
          <div
            class="t-right"
            v-html="subscription.payment_method_title"
          />
        </Flex>

        <Spacer
          v-if="theme !== 'simple'"
        >
          <Wrapper
            size="s"
            :margin="false"
          >
            <Flex
              v-if="theme !== 'simple'"
              column-gap="m"
              row-gap="m"
            >
              <Column
                v-for="(subscriptionAction, i) in subscriptionActions"
                :key="i"
                :size="{ default: 12, m: subscriptionAction === 'change-payment' ? 6 : 'auto' }"
              >
                <Cta
                  v-if="subscriptionAction === 'change-payment'"
                  theme="button"
                  :data="{
                    title: $labels.change_payment,
                    url: {
                      name: 'PayOrder',
                      params: {
                        orderId: subscription.id
                      },
                      query: {
                        pay_for_order: true,
                        key: subscription.key,
                        change_payment_method: subscription.id
                      }
                    },
                  }"
                />
                <Cta
                  v-if="subscriptionAction === 'renew-now'"
                  theme="button-outline"
                  :data="{
                    title: $labels.renew_now,
                    fn: () => {},
                  }"
                  disabled
                />
                <Cta
                  v-if="subscriptionAction === 'cancel'"
                  theme="button-outline"
                  :data="{
                    title: $labels.cancel,
                    fn: openModal
                  }"
                />
              </Column>
            </Flex>
          </Wrapper>
        </Spacer>
      </Grid>
      <div class="separator--row" />
      <Grid
        col="12"
        row-gap="m"
      >
        <div class="typo--m">
          {{ $labels.subscription_totals }}
        </div>
        <Flex
          v-for="(subscriptionItem, index) in subscription.items"
          :key="index"
          justify="space-between"
        >
          <Flex
            nowrap
          >
            <div
              class="wc-product-image wc-product-image--l"
              :style="{
                backgroundImage: `url(${subscriptionItem.product.gds_featured_image ? subscriptionItem.product.gds_featured_image.sizes.medium : ''})`
              }"
            />
            <Flex
              dir="column"
              justify="space-between"
            >
              <Spacer y="xs">
                <del v-if="subscriptionItem.qty_refunded_for_item < 0">{{ subscriptionItem.quantity - subscriptionItem.qty_refunded_for_item }}</del>
                {{ subscriptionItem.quantity }} x <router-link
                  :to="$relativeUrl(subscriptionItem.product.permalink)"
                >
                  {{ subscriptionItem.item_name }}
                  <Spacer
                    v-if="lastOrderIssues"
                    top="xs"
                    class="typo--xs"
                  >
                    {{ lastOrderIssues }}
                  </Spacer>
                </router-link>

                <Spacer
                  top="s"
                >
                  <div
                    v-html="getTaxedValueLine(subscriptionItem)"
                  />
                </Spacer>
              </Spacer>
            </Flex>
          </Flex>
        </Flex>
        <div class="separator--row" />
        <Flex
          justify="space-between"
        >
          <div>{{ $labels.subtotal }}</div>
          <div
            class="t-right"
            v-html="subtotal"
          />
        </Flex>
        <Flex
          v-if="subscription.shipping_total > 0"
          justify="space-between"
        >
          <div>{{ $labels.shipping }}</div>
          <div
            class="t-right"
            v-html="shipping"
          />
        </Flex>
        <Flex
          v-if="subscription.discount_total > 0"
          justify="space-between"
        >
          <div>{{ $labels.discount }}</div>
          <div>-<span v-html="discount" /></div>
        </Flex>
        <Flex
          v-if="taxDisplayCart === 'excl'"
          justify="space-between"
        >
          <div>{{ $labels.vat }}</div>
          <div v-html="vat" />
        </Flex>
        <div class="separator--row" />
        <Flex
          justify="space-between"
        >
          <div>{{ $labels.total }}</div>
          <div v-html="total" />
        </Flex>
        <div class="separator--row" />
      </Grid>
      <Grid
        v-if="theme !== 'simple'"
        col="12"
        row-gap="m"
      >
        <div class="typo--m">
          {{ $labels.related_orders }}
        </div>
        <Grid
          v-for="(order, i) in subscription.subscription_orders"
          :key="i"
          :col="{
            default: 6,
            s: 3
          }"
          align="center"
        >
          <div>{{ $labels.order }} #{{ order.id }}</div>
          <div v-if="!$mq.isMobileXS">
            {{ parseDate(order.date) }}
          </div>
          <div v-if="!$mq.isMobileXS">
            {{ getOrderStatus(order.status) }}
          </div>
          <Flex justify="end">
            <Cta
              theme="button"
              :data="{
                title: $labels.view,
                url: { name: 'Orders', hash: '#order-'+order.id},
              }"
            />
          </Flex>
        </Grid>
      </Grid>
      <div
        v-if="theme !== 'simple'"
        class="separator--row"
      />
      <Address
        v-if="theme !== 'simple'"
        :data="subscription"
      />
    </Grid>
  </Spacer>
</template>

<script>
import {
  parseDate,
  parseCurrency,
  availableSubscriptionActions,
  getOrderTotal,
} from '@/assets/js/utils-wc';

import orderBy from 'lodash.orderby';

import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Address from '@/components/wc/address.vue';

export default {
  name: 'SubscriptionDetails',
  components: {
    Cta,
    Address,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      lastOrder: null,
    };
  },
  computed: {
    ...mapGetters(['wcSettings']),
    subscriptionStatus() {
      return this.$store.state.wp.wcSettings.woocommerce_subscription_statuses[
        `wc-${this.subscription.status}`
      ];
    },
    nextPayment() {
      return this.subscription.status === 'active' && this.subscription.subscription_data.schedule_next_payment ? this.parseDate(this.subscription.subscription_data.schedule_next_payment.date) : '-';
    },
    subscriptionActions() {
      return availableSubscriptionActions[`wc-${this.subscription.status}`];
    },
    taxDisplayCart() {
      return this.$store.state.wp.wcSettings.woocommerce_tax_display_cart;
    },
    subtotal() {
      if (this.subscription) {
        let linesTotals = 0;
        let linesTaxs = 0;
        this.subscription.items.forEach((element) => {
          linesTotals += Number(element.total);
          linesTaxs += Number(element.total_tax);
        });
        if (this.taxDisplayCart === 'excl') {
          return `${this.parseCurrency(Number(linesTotals))}`;
        }
        return this.parseCurrency(Number(linesTotals) + Number(linesTaxs));
      }
      return null;
    },
    shipping() {
      if (this.subscription) {
        if (this.taxDisplayCart === 'excl') {
          return this.parseCurrency(Number(this.subscription.shipping_total));
        }
        return this.parseCurrency(Number(this.subscription.shipping_total) + Number(this.subscription.shipping_tax));
      }
      return null;
    },
    discount() {
      if (this.subscription) {
        if (this.taxDisplayCart === 'excl') {
          return this.parseCurrency(Number(this.subscription.discount_total));
        }
        return this.parseCurrency(Number(this.subscription.discount_total) + Number(this.subscription.discount_tax));
      }
      return null;
    },
    vat() {
      return this.parseCurrency(Number(this.subscription.total_tax));
    },
    total() {
      return getOrderTotal(this.subscription, this.wcSettings);
    },
    lastOrderIssues() {
      let issues = null;
      if (this.lastOrder) {
        this.lastOrder.items.forEach((lineItem) => {
          lineItem.item_metas.forEach((itemMeta) => {
            if (itemMeta.key === 'Issues') {
              issues = itemMeta.value;
            }
          });
        });
      }
      return issues;
    },
  },
  async mounted() {
    // Get last order
    if (this.subscription.subscription_orders[0]) {
      const last_order = orderBy(this.subscription.subscription_orders, ['id'], ['desc'])[0];
      const { data } = await this.$store.dispatch('getOrder', {
        order_id: last_order.id,
      });
      this.lastOrder = data;
    }
  },
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings, this.subscription.currency);
    },
    // unparseCurrency(price) {
    //   return unparseCurrency(price, this.wcSettings);
    // },
    parseDate(date) {
      if (date) {
        return parseDate(date, this.wcSettings);
      }
      return null;
    },
    getOrderStatus(status) {
      return this.$store.state.wp.wcSettings.woocommerce_order_statuses[
        `wc-${status}`
      ];
    },
    async cancelSubscription() {
      let status;
      if (this.subscription.status === 'active') {
        status = 'pending-cancel';
      }
      if (this.subscription.status === 'pending-cancel') {
        status = 'cancelled';
      }
      if (this.subscription.status === 'on-hold') {
        status = 'cancelled';
      }
      await this.$store.dispatch('changeSubscriptionStatus', {
        subscription_id: this.subscription.id,
        status,
      });
      await this.$store.dispatch('getSubscriptions');
    },
    openModal() {
      this.$bus.$emit('modal', {
        type: 'dialog',
        id: 'cancelSubscription',
        content: {
          richtext: this.$labels.cancel_subscription_dialog,
          cta: {
            title: this.$labels.cancel_subscription,
          },
        },
        fn: this.cancelSubscription,
      });
    },
    getTaxedValueLine(row) {
      if (this.taxDisplayCart === 'incl') {
        return this.parseCurrency(Number(row.total) + Number(row.total_tax));
      }
      if (this.taxDisplayCart === 'excl') {
        return `${this.parseCurrency(Number(row.total))}`;
      }
      return `${this.parseCurrency(Number(row.total))} + ${this.parseCurrency(Number(row.total_tax))} VAT`;
    },
  },
};
</script>

<style lang="scss"></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{class:[
    'archive',
    _vm.data.archive_type === 'archive'
      ? `archive--${_vm.data.archive}`
      : `archive--${_vm.data.posts[0].post_type}`,
    _vm.data.template ? `archive--${_vm.data.template}` : false,
    _vm.items.length > 1 && _vm.data.template !== 'shop' ? `archive-overflow` : false,
  ]},[(_vm.data.title)?_c('Title',{staticClass:"title",attrs:{"data":{ value: _vm.data.title, size: 'label-s' }}}):_vm._e(),(_vm.items.length > 0)?_c(_vm.data.carousel ? 'Carousel' : 'Grid',{tag:"component",class:['archive-grid', `archive-grid--${_vm.data.thumb}`],attrs:{"extra-settings":{
      cellAlign: 'left',
      prevNextButtons: _vm.items.length > 2 || _vm.$mq.isMobile,
      template: 'posts',
      autoPlay: _vm.data.autoplay ? 2000 : false,
      pauseAutoPlayOnHover: false,
    },"col":_vm.data.template === 'category' || _vm.data.template === 'shop' ? undefined : _vm.gridCol,"template":_vm.gridTemplate}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('transition',{attrs:{"name":"fade","appear":""}},[_c(_vm.thumb(item.type),{tag:"component",attrs:{"data":item,"thumb":(index + 1 - 3) % 5 === 0 && _vm.data.template === 'category'
              ? 'horizontal-xl'
              : _vm.data.thumb}})],1),((index + 1 - 3) % 10 === 0 && _vm.data.template === 'category' && _vm.advs['page-section'])?_c('Adv'):_vm._e()],1)}),0):_vm._e(),(_vm.items.length === 0)?_c('div',[_c('Skeleton',{class:['archive-grid', `archive-grid--${_vm.data.thumb}`],attrs:{"items":_vm.skeletonItems,"cols":_vm.gridCol,"thumb-component":_vm.data.archive_type === 'archive' ? _vm.data.archive : _vm.data.posts[0].post_type,"thumb-theme":_vm.data.thumb}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.items && _vm.data.infinite_scroll),expression:"items && data.infinite_scroll"}],ref:"infinite",staticClass:"infinite"}),_c('Cta',{directives:[{name:"show",rawName:"v-show",value:(_vm.items && _vm.data.infinite_scroll && _vm.counter >= 3 && _vm.showLoadMore),expression:"items && data.infinite_scroll && counter >= 3 && showLoadMore"}],staticClass:"load-more",attrs:{"data":{
      title: 'Load more',
      fn: _vm.requestOffset,
    },"theme":"button"}}),(_vm.data.cta)?_c('Cta',{attrs:{"data":_vm.data.cta}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }